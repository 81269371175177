import {
  CLEAR_ATTENDANCE_SUMMARY_VISITOR,
  REQUEST_FETCH_ATTENDANCE_SUMMARY_VISITOR,
  REQUEST_FETCH_ATTENDANCE_SUMMARY_VISITOR_FAILED,
  REQUEST_FETCH_ATTENDANCE_SUMMARY_VISITOR_SUCCESS
} from "./attendanceSummaryVisitor.types";

let initialState = {
  reload: false,
  response: {},
  error: null,
  loading: false
};

const attendanceSummaryVisitorReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FETCH_ATTENDANCE_SUMMARY_VISITOR:
      return {
        ...state,
        loading: true,
        error: null
      };

    case REQUEST_FETCH_ATTENDANCE_SUMMARY_VISITOR_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        reload: false
      };

    case REQUEST_FETCH_ATTENDANCE_SUMMARY_VISITOR_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reload: false
      };

    case CLEAR_ATTENDANCE_SUMMARY_VISITOR:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
        reload: false
      };

    default:
      return state;
  }
};

export default attendanceSummaryVisitorReducer;
