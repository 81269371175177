import React from 'react';
import {Route, Redirect} from "react-router-dom";
import { isAuthenticated } from "../../utils/utils";

const PublicRoute = ({ component: Component, redirectPathname: targetPath, ...rest }) => {

    return (
        <Route 
            {...rest}
            render = {(props) => {
                if(isAuthenticated()) {
                    return <Redirect 
                        to={{ 
                            pathname: targetPath, 
                            state: {from: props.location }
                        }} 
                    />
                } else {
                    return <Component {...props}/>
                }
            }} 
        />
    )
}

export default PublicRoute