import { useState } from "react";
import ButtonAccent from "../../../common/button-accent/ButtonAccent";
import AccessGroups from "../../access-groups/AccessGroups";
import AddOrEditGroups from "../../add-groups/AddOrEditGroups";

const SectionGroups = () => {
  const [addFolderPopup, setAddFolderPopup] = useState(false);

  function toggleAddFolderPopup() {
    setAddFolderPopup((ps) => !ps);
  }

  return (
    <div>
      <main
        style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
      >
        <>
          <div className="d-flex justify-content-center">
            <ButtonAccent
              label="Add Group"
              variant="span-button"
              startSpan="+"
              startSpanClassName="mr-1"
              onClick={toggleAddFolderPopup}
            />
          </div>
          <hr />
        </>
        {addFolderPopup && (
          <AddOrEditGroups handleClose={toggleAddFolderPopup} />
        )}
      </main>
      <AccessGroups />
    </div>
  );
};
export default SectionGroups;
