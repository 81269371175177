import { Autocomplete, Box, Grid, Modal, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  createAccessLvhGroupPromise,
  getAllAccessLvhGroups,
  getAllTeams,
} from "../../../services/group-for-lvh.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import { isEmpty } from "lodash";

const AddOrEditLvhGroups = (props) => {
  const params = useParams();

  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const initialValues = {
    group_name: "",
    team_id: "",
  };

  const validationSchema = Yup.object({
    group_name: Yup.string().required("Please enter the name of the Group"),
    team_id: Yup.string().required("Please select a team"),
  });

  const teamState = useSelector((state) => state.teamEm);
  const teamData = teamState?.response?.data;

  useEffect(() => {
    if (teamState.loading === false && isEmpty(teamData)) {
      dispatch(getAllTeams(params.collectionId));
    }
  }, []);

  const onSubmit = (values) => {
    if (!submitting) {
      setSubmitting(true);
      return createAccessLvhGroupPromise(params.collectionId, values)
        .then((res) => {
          setSubmitting(false);
          dispatch(getAllAccessLvhGroups(params.collectionId));
          props.handleClose();
          toast.success("Group Created");
        })
        .catch((err) => {
          setSubmitting(false);
          toast.error(err?.data || STD_ERROR_MESSAGE);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <Box
        sx={{
          width: "40%",
          backgroundColor: "whitesmoke",
          top: "20%",
          position: "relative",
          margin: "0 auto",
          p: 2,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="group_name"
                name="group_name"
                label="Group Name"
                variant="outlined"
                fullWidth
                value={formik.values.group_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.group_name && Boolean(formik.errors.group_name)
                }
                helperText={
                  formik.touched.group_name && formik.errors.group_name
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="team"
                options={teamData || []}
                name="team_id"
                onChange={(e, value) =>
                  formik.setFieldValue("team_id", value?.id)
                }
                getOptionSelected={(option, value) =>
                  option?.team_id === value?.id
                }
                getOptionLabel={(option) => option?.display_name}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Team"
                    variant="outlined"
                    fullWidth
                    error={
                      formik.touched.team_id && Boolean(formik.errors.team_id)
                    }
                    helperText={formik.touched.team_id && formik.errors.team_id}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonAccent label="Add to List" type="submit" />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
export default AddOrEditLvhGroups;
