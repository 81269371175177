import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import CloseIcon from "../../../assets/icon/cancel.svg";
import CancelLightIcon from "../../../assets/icon/cancel_light.svg";
import ErrorInfoIcon from "../../../assets/icon/info-red.svg";
import {
  createEntityLiveFeedPromise,
  getAllEntityFeeds,
  getAllFeeds,
} from "../../../services/liveFeed.service";
import {
  getPresignedUrlPromise,
  uploadImageUsingPresignedUrlPromise,
} from "../../../services/uploadImage.service";
import { AD_BUCKET_PATH, STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";
import ButtonTransparent from "../../common/button-transparent/ButtonTransparent";
import Image from "../../common/v2/image/Image";
import AccentLoadingButton from "../../common/v2/loading-button/AccentLoadingButton";

const EntityLiveFeedCreateOrEdit = (props) => {
  const { action, liveFeed, entityId } = props;

  const [previewImg, setPreviewImg] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const imageRef = useRef(null);
  const params = useParams();

  const entityState = useSelector((state) => state.entityAd);
  const dispatch = useDispatch();

  const entityData = entityState.response?.data;

  const initialValues = {
    text: action === "edit" ? (liveFeed?.text ? liveFeed?.text : "") : "",
    image_id:
      action === "edit" ? (liveFeed?.image_id ? liveFeed?.image_id : "") : "",
  };

  const validationSchema = Yup.object().shape({
    text: Yup.string().when("is_required", {
      is: () => Boolean(!imageFile),
      then: Yup.string().required(
        "Please enter the caption/body or pick an image"
      ),
      otherwise: Yup.string(),
    }),
  });

  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImg(reader.result);
      };
      reader.readAsDataURL(imageFile);
    }
  }, [imageFile]);

  const onCreateSubmit = (values, { resetForm }) => {
    if (!submitting) {
      setSubmitting(true);

      if (imageFile) {
        let imageId = "";
        const presignedUrlPayload = [
          {
            bucket_path: `${AD_BUCKET_PATH.entity}/${entityId}/${AD_BUCKET_PATH.liveFeed}/${imageFile.name}`,
            image_name: imageFile.name,
          },
        ];

        getPresignedUrlPromise(presignedUrlPayload)
          .then((response) => {
            imageId = response?.res?.data?.data?.[0]?.image_id;
            return uploadImageUsingPresignedUrlPromise(
              response?.res?.data?.data?.[0]?.upload_url,
              imageFile
            );
          })
          .then((response) => {
            const valuesCopy = { ...values };
            valuesCopy.image_id = imageId;
            return createEntityLiveFeedPromise(entityId, valuesCopy);
          })
          .then((res) => {
            setSubmitting(false);
            resetForm();
            setImageFile(null);
            setPreviewImg(null);
            dispatch(getAllEntityFeeds(entityId));
          })
          .catch((err) => {
            setSubmitting(false);
            toast.error(
              !isEmpty(err?.data?.error_data)
                ? err?.data?.error_data
                : STD_ERROR_MESSAGE
            );
          });
      } else {
        const valuesCopy = { ...values };
        createEntityLiveFeedPromise(entityId, valuesCopy)
          .then((res) => {
            setSubmitting(false);
            resetForm();
            dispatch(getAllEntityFeeds(entityId));
          })
          .catch((err) => {
            setSubmitting(false);
            toast.error(
              !isEmpty(err?.data?.error_data)
                ? err?.data?.error_data
                : STD_ERROR_MESSAGE
            );
          });
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onCreateSubmit,
  });

  return (
    <div
      className="mt-4"
      style={{
        border: "2px solid var(--low-emphasis)",
        borderRadius: "4px",
        padding: "1.5rem 2rem",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="d-flex justify-content-between align-items-start">
          <div className="d-flex align-items-center">
            <LazyLoadComponent>
              <Image
                src={getImageUrl(entityData?.image?.bucket_path)}
                alt=""
                containerCustomStyle={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
                imageCustomStyle={{
                  objectFit: "cover",
                  objectPosition: "center",
                  width: "100%",
                  height: "100%",
                }}
              />
            </LazyLoadComponent>
            <h5 className="pb16-lh24 mb-0 ml-2">{entityData?.name}</h5>
          </div>
          {action === "edit" && (
            <img
              className="py-2 pt-cursor"
              src={CloseIcon}
              alt=""
              onClick={props.onCloseClick}
            />
          )}
        </div>
        <textarea
          name="text"
          className="pr16-lh24 mt-3 px-3 py-2"
          style={{
            backgroundColor: "var(--secondary-color)",
            borderRadius: "4px",
            border: "none",
            minHeight: "150px",
            width: "100%",
          }}
          placeholder="Type caption / body text here..."
          value={formik.values.text}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {Boolean(formik.touched.text && formik.errors.text) && (
          <div className="d-flex align-items-center justify-content-start mt-2">
            <img src={ErrorInfoIcon} alt="" />
            <span className="margin-left-5 pr10-lh15 error-color">
              {formik.errors.text}
            </span>
          </div>
        )}
        {previewImg || formik.values?.image_id ? (
          <div
            className="mt-2"
            style={{
              width: "fit-content",
              height: "200px",
              position: "relative",
            }}
          >
            <img
              src={previewImg || getImageUrl(liveFeed?.image?.bucket_path)}
              alt=""
              style={{
                maxHeight: "200px",
                width: "auto",
                maxWidth: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                borderRadius: "50%",
                position: "absolute",
                top: "10px",
                right: "10px",
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="pt-cursor"
              onClick={() => {
                if (action === "edit") {
                  if (!previewImg && formik.values.image_id) {
                    formik.setFieldValue("image_id", null);
                  } else {
                    setPreviewImg(null);
                    setImageFile(null);
                  }
                } else {
                  setPreviewImg(null);
                  setImageFile(null);
                }
              }}
            >
              <img src={CancelLightIcon} alt="" />
            </div>
          </div>
        ) : null}
        <Grid container sx={{ marginTop: "0.5rem" }} spacing={2}>
          <Grid item xs={6}>
            <ButtonTransparent
              ref={imageRef}
              variant="span-button"
              startSpan="+"
              customStyling={{
                width: "100%",
                border: "1px solid var(--high-emphasis)",
              }}
              startSpanClassName="icon-medium margin-right-8 vertical-align-bottom"
              onClick={(event) => {
                event.preventDefault();
                imageRef.current.click();
              }}
              label={`${
                Boolean(imageFile || formik.values?.image_id)
                  ? "Change Image"
                  : "Add Image"
              }`}
            />
            <input
              type="file"
              style={{ display: "none" }}
              ref={imageRef}
              accept="image/png, image/jpeg, image/jpg"
              name="banner"
              onChange={(event) => {
                const file = event.target.files[0];
                if (file && file.type.substr(0, 5) === "image") {
                  setImageFile(file);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <AccentLoadingButton
              type="submit"
              label={`${action === "edit" ? "Update" : "Post"}`}
              loadingState={submitting}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EntityLiveFeedCreateOrEdit;
