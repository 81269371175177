import React, { useState, useEffect, Component } from "react";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import "./UserTestimonial.css";
import CustomSpinner from "../../common/spinner/CustomSpinner";

import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";

import { fetchTestimonial } from "../../../services/testimonial.service";

const UserTestimonialSection = (props) => {
  const { isMobile, name, description, imageUrl, layout, containerClassName } =
    props;

  return (
    <Grid container spacing={2} className={containerClassName || ""}>
      <Grid
        item
        xs={6}
        className="d-flex justify-content-start align-items-center"
      >
        <img
          style={{
            width: "100%",
            maxWidth: "40rem",
            aspectRatio: "8/5",
            borderRadius: "10rem",
            objectFit: "cover",
            objectPosition: "center",
          }}
          src={imageUrl}
          alt=""
        />
      </Grid>
      <Grid className="d-flex align-items-center" item xs={6}>
        <div>
          <h4 className={isMobile ? "pb24-lh40" : "pb36-lh56"}>{name}</h4>
          <p className={`${isMobile ? "pr14-lh24" : "pr24-lh40"} mb-0`}>
            {description}
          </p>
          {/* <p className="testimonialText">{description}</p> */}
        </div>
      </Grid>
    </Grid>
  );
};

const UserTestimonial = (props) => {
  const testimonialState = useSelector((state) => state.testimonialAd);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { entityId } = props;

  const testimonialData = testimonialState?.response?.data;

  const isMobile = useMediaQuery("(max-width: 480px)");

  const isMobileOrTablet = useMediaQuery("(max-width: 599px)");
  const minHeight = isMobileOrTablet ? 56 + 56 + 40 : 64 + 56 + 40;

  useEffect(() => {
    if (isEmpty(testimonialState.response)) {
      dispatch(fetchTestimonial(entityId));
    }
  }, []);

  useEffect(() => {
    if (!testimonialState.loading && !isEmpty(testimonialState.response)) {
      setLoading(false);
    }
    if (!testimonialState.loading && !isEmpty(testimonialState.error)) {
      setLoading(false);
      toast.error(
        testimonialState.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [testimonialState]);

  return (
    <div style={{ minHeight: `calc(100vh - ${minHeight}px)` }}>
      {!loading ? (
        !isEmpty(testimonialData) ? (
          <div
            style={{
              backgroundRepeat: "repeat",
            }}
            className="py-5 px-4"
          >
            <h2
              className={`${
                isMobile
                  ? "parisienne-normal-fs40-lh80"
                  : "parisienne-normal-fs72-lh80"
              } text-center`}
            >
              Testimonials
            </h2>
            <div
              className="mt-4"
              style={{
                maxWidth: "1200px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {testimonialData.map((TestimonialSection, index) => (
                <UserTestimonialSection
                  layout={index}
                  key={TestimonialSection.id}
                  isMobile={isMobile}
                  name={TestimonialSection.title}
                  description={TestimonialSection.text}
                  imageUrl={getImageUrl(TestimonialSection.image?.bucket_path)}
                  containerClassName="mb-3"
                />
              ))}
            </div>
          </div>
        ) : (
          <h4 className="text-center pr20-lh32 high-emphasis-color pt-5">
            You have no testimonials
          </h4>
        )
      ) : (
        <CustomSpinner />
      )}
    </div>
  );
};

export default UserTestimonial;
