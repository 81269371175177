import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import {
  getUserRole,
  isAuthenticated,
  setOriginalUrl,
} from "../../utils/utils";

const ProtectedRoute = ({
  component: Component,
  redirectPathname: targetPath,
  accessLevel,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated()) {
          if (accessLevel === getUserRole()) {
            return <Component {...props} />;
          } else {
            return <Redirect to="/" />;
          }
        } else {
          setOriginalUrl(props.location?.pathname);
          return (
            <Redirect
              to={{
                pathname: targetPath,
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
