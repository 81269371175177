import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import "./UserNews.css";
import CustomSpinner from "../../common/spinner/CustomSpinner";

import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";

import { fetchNews } from "../../../services/news.service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import moment from "moment";

const UserNews = (props) => {
  const newsState = useSelector((state) => state.newsAd);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const TEXT_CHAR_SIZE = 150;

  const newsData = newsState?.response?.data;

  const isMobile = useMediaQuery("(max-width: 480px)");

  const isMobileOrTablet = useMediaQuery("(max-width: 599px)");
  const minHeight = isMobileOrTablet ? 56 + 56 + 40 : 64 + 56 + 40;

  const { collectionInfoLoading } = props;

  const collectionState = useSelector((state) => state.collectionEm);

  const collectionData = collectionState.response?.data;

  const [isReadMore, setIsReadMore] = useState([]);
  const toggleReadMore = (i) => {
    const oldValue =
      isReadMore.filter((p) => p.index == i)[0]?.isReadMore || false;
    setIsReadMore((ps) => [
      ...ps.filter((p) => p.index != i),
      { index: i, isReadMore: !oldValue },
    ]);
  };

  useEffect(() => {
    if (isEmpty(newsState.response)) {
      dispatch(fetchNews(props.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!newsState.loading && !isEmpty(newsState.response)) {
      setLoading(false);
      newsData.map((news, index) =>
        setIsReadMore((ps) => [
          ...ps,
          { index: index, isReadMore: news.text.length > TEXT_CHAR_SIZE },
        ])
      );
    }
    if (!newsState.loading && !isEmpty(newsState.error)) {
      setLoading(false);
      toast.error(newsState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [newsState]);

  return (
    <div style={{ minHeight: "calc(100vh - 64px - 56px - 40px)" }}>
      {!loading && !collectionInfoLoading ? (
        <>
          {!isEmpty(newsData) && !isEmpty(collectionData) ? (
            <div className="back-ground">
              <div
                style={{
                  backgroundImage: `url(${getImageUrl(
                    collectionData?.image?.bucket_path
                  )})`,
                  height: isMobile ? "50vh" : "70vh",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: isMobile ? "100vw auto" : "auto 70vh",
                  position: "relative",
                }}
              ></div>
              <h1 className="title">{collectionData?.name}</h1>
              <div
                style={{
                  marginTop: "12rem",
                }}
              >
                <div
                  style={{
                    maxWidth: "480px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  {collectionData.invitation_title && (
                    <p className="pr20-lh32 text-center grey-2-color invitationTitle">
                      {collectionData.invitation_title}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <h1 className="news-heading">Important News And Updates</h1>
                    <div className="mt-4">
                      {newsData.map((newsSection, index) => (
                        <div key={index}>
                          <Grid item xs={6} className="news">
                            <img
                              className="news-image"
                              src={getImageUrl(newsSection.image?.bucket_path)}
                              alt=""
                            />
                            <div
                              style={{
                                marginLeft: "30px",
                              }}
                            >
                              <h4
                                className={isMobile ? "pb24-lh40" : "pb36-lh56"}
                              >
                                {newsSection.title}
                              </h4>
                              <div className="body">
                                {console.log("isReadMore line 110", isReadMore)}
                                {isReadMore.find((r) => r.index == index)
                                  .isReadMore
                                  ? newsSection.text.slice(0, TEXT_CHAR_SIZE)
                                  : newsSection.text}
                                <span
                                  onClick={() => toggleReadMore(index)}
                                  className="read-or-hide"
                                  style={{ color: "blue" }}
                                >
                                  {isReadMore.find((r) => r.index == index)
                                    .isReadMore
                                    ? "Read more"
                                    : "Show less"}
                                </span>
                                ''
                                <div className="news-timings">
                                  {moment(newsSection.updated_at).format(
                                    "DD MMMM YYYY , HH : MM"
                                  )}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h4 className="text-center pr20-lh32 high-emphasis-color pt-5">
              You have no news updates to view.
            </h4>
          )}
        </>
      ) : (
        <CustomSpinner />
      )}
    </div>
  );
};

export default UserNews;
