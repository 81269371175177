import {
  CLEAR_TEAM_EM,
  FETCH_TEAM_EM_FAILURE,
  FETCH_TEAM_EM_SUCCESS,
  REQUEST_FETCH_TEAM_EM
} from "./team.types";

export const requestTeamReducer = () => {
  return {
    type: REQUEST_FETCH_TEAM_EM
  };
};

export const fetchTeamEmSuccess = (response) => {
  return {
    type: FETCH_TEAM_EM_SUCCESS,
    payload: response
  };
};

export const fetchTeamEmFailure = (error) => {
  return {
    type: FETCH_TEAM_EM_FAILURE,
    payload: error
  };
};

export const clearTeamEm = (payload) => {
  return {
    type: CLEAR_TEAM_EM,
    payload
  };
};
