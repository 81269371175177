import React from "react";
import { useHistory } from "react-router";
import ButtonAccent from "../../components/common/button-accent/ButtonAccent";
import Footer from "../../components/layout/footer/Footer";
import CustomNavbar from "../../components/layout/navbar/Navbar";
import "./landing_page.css";

const LandingPage = () => {
  const history = useHistory();

  function handleLoginPage() {
    history.push("/sign-in");
  }

  return (
    <div>
      <CustomNavbar />
      <div className="landing-page-bg">
        <div className="landing-page-container">
          <h1>
            Your Company
            <br /> Online
          </h1>
          <h2>Social microsites for independent businesses</h2>
          <ButtonAccent
            className="pb14-lh21 landing-page-button"
            label="LOGIN HERE"
            onClick={handleLoginPage}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
