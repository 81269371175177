import {
  CLEAR_FETCH_ACCESS_LVH_GROUP_EM_STATES,
  FETCH_ACCESS_LVH_GROUP_EM_FAILURE,
  FETCH_ACCESS_LVH_GROUP_EM_SUCCESS,
  REQUEST_ACCESS_LVH_GROUP_FETCH_EM,
} from "./lvhGroup.types";

export const requestFetchAccessLvhGroupEm = () => {
  return {
    type: REQUEST_ACCESS_LVH_GROUP_FETCH_EM,
  };
};

export const fetchAccessLvhGroupEmSuccess = (response) => {
  return {
    type: FETCH_ACCESS_LVH_GROUP_EM_SUCCESS,
    payload: response,
  };
};

export const fetchAccessLvhGroupEmFailure = (error) => {
  return {
    type: FETCH_ACCESS_LVH_GROUP_EM_FAILURE,
    payload: error,
  };
};

export const clearFetchAccessLvhGroupEmStates = () => {
  return {
    type: CLEAR_FETCH_ACCESS_LVH_GROUP_EM_STATES,
  };
};
