import {
  CLEAR_FETCH_ACCESS_GROUP_USER_EM_STATES,
  FETCH_ACCESS_GROUP_USER_EM_FAILURE,
  FETCH_ACCESS_GROUP_USER_EM_SUCCESS,
  REQUEST_ACCESS_GROUP_USER_FETCH_EM,
} from "./groupUsers.types";

let initialState = {
  reload: false,
  response: {},
  error: null,
  loading: false,
};

const fetchAccessGroupUserEmReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACCESS_GROUP_USER_FETCH_EM:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_ACCESS_GROUP_USER_EM_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        reload: false,
      };

    case FETCH_ACCESS_GROUP_USER_EM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reload: false,
      };

    case CLEAR_FETCH_ACCESS_GROUP_USER_EM_STATES:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
        reload: false,
      };

    default:
      return state;
  }
};

export default fetchAccessGroupUserEmReducer;
