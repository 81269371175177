import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import DeleteIcon from "../../../assets/icon/delete.svg";
import FavouriteFilledIcon from "../../../assets/icon/favourites.svg";
import FavouriteOutlinedIcon from "../../../assets/icon/favuorite_white.svg";
import MoreIcon from "../../../assets/icon/horizontal_more.svg";
import Image from "../../common/v2/image/Image";

import "./live-feed-item.css";

const LiveFeedItem = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="mt-4"
      style={{
        border: "2px solid var(--low-emphasis)",
        borderRadius: "4px",
      }}
    >
      <div className="px-3 pt-3">
        <div className="d-flex justify-content-between align-items-start">
          <div className="d-flex align-items-center">
            <LazyLoadComponent>
              <Image
                src={props.thumbnail}
                alt=""
                containerCustomStyle={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
                imageCustomStyle={{
                  objectFit: "cover",
                  objectPosition: "center",
                  width: "100%",
                  height: "100%",
                }}
              />
            </LazyLoadComponent>
            <h5 className="pb16-lh24 mb-0 ml-2">{props.title}</h5>
          </div>
          {props.enableMoreOption && (
            <>
              <img
                className="py-2 pt-cursor"
                src={MoreIcon}
                alt=""
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {/* <MenuItem onClick={props.onEditPostClick}>
                                    <img 
                                        style={{
                                            width: '14px', 
                                            height: '14px'
                                        }} 
                                        alt="" 
                                        src={EditIcon}
                                    />
                                    <h6 className="pb14-lh21 mb-0 ml-2">Edit</h6>
                                </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.onDeletePostClick();
                  }}
                >
                  <img
                    style={{
                      width: "14px",
                      height: "14px",
                    }}
                    alt=""
                    src={DeleteIcon}
                  />
                  <h6 className="pb14-lh21 mb-0 ml-2">Delete</h6>
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
        {props.contentText && (
          <p className="pr12-lh24 mt-2 mb-0">{props.contentText}</p>
        )}
      </div>
      {props.contentImageUrl && (
        <div className="mt-3">
          <LazyLoadComponent>
            <Image
              src={props.contentImageUrl}
              alt=""
              containerCustomStyle={{
                width: "100%",
                maxWidth: "100%",
                height: "auto",
                backgroundColor: "var(--primary-color)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              imageCustomStyle={{
                maxWidth: "100%",
                maxHeight: "60vh",
                width: "auto",
                height: "auto",
              }}
              placeholderClassName="live-feed-placeholder"
            />
          </LazyLoadComponent>
        </div>
      )}
      <div className="p-3 d-flex align-items-center">
        <img
          src={
            Boolean(props.likeState)
              ? FavouriteFilledIcon
              : FavouriteOutlinedIcon
          }
          className="pt-cursor"
          alt=""
          onClick={props.onLikeClick}
        />
        {props.likesCount && (
          <label className="ml-2 mb-0 pr14-lh24">{`${props.likesCount} ${
            props.likesCount === 1 ? "like" : "likes"
          }`}</label>
        )}
      </div>
    </div>
  );
};

export default LiveFeedItem;
