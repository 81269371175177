import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { Box, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createMeeting,
  getMeetingByEvent,
  updateMeeting
} from "../../../../services/meeting.service";
import ButtonAccent from "../../../common/button-accent/ButtonAccent";
import {
  getLocalDateString,
  getLocalTimeString
} from "../../../../utils/constant";

const formatDateTime = (date, time) => {
  return `${date}T${time}:00+05:30`;
};

const SectionMeetings = ({ selected }) => {
  const currAction = selected.subFolder;
  const meetingState = useSelector((state) => state.meetingEm);
  const meetingData = meetingState.response?.data;
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      meetingStartTime: "",
      meetingStartDate: "",
      venue: "",
      meetingLateTime: "",
      nextMeetingDate: ""
    }
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const payload = {
      start_date: formatDateTime(data.meetingStartDate, data.meetingStartTime),
      very_late_time: formatDateTime(
        data.meetingStartDate,
        data.meetingLateTime
      ),
      venue: data.venue
    };
    if (meetingData != null) {
      try {
        const res = await updateMeeting(
          payload,
          meetingData.id,
          params.collectionId
        );
        if (res.error_data == null) {
          toast.success("Meeting updated successfully");
          return;
        }
        toast.error("Failed to update meeting", res.error_data?.message);
      } catch (error) {
        toast.error("Failed to update meeting", error);
      } finally {
        setIsSubmitting(false);
        dispatch(getMeetingByEvent(params.collectionId));
      }
    } else {
      try {
        const res = await createMeeting(payload, params.collectionId);
        if (res.error_data == null) {
          toast.success("Meeting created successfully");
          return;
        }
        toast.error("Failed to create meeting", res.error_data?.message);
      } catch (error) {
        toast.error("Failed to create meeting", error);
      } finally {
        setIsSubmitting(false);
        dispatch(getMeetingByEvent(params.collectionId));
      }
    }
  };

  useEffect(() => {
    if (!meetingState.loading && isEmpty(meetingState.response)) {
      dispatch(getMeetingByEvent(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!meetingState.loading && !isEmpty(meetingState.response)) {
      reset({
        meetingStartTime: getLocalTimeString(meetingData?.start_date),
        meetingStartDate: getLocalDateString(meetingData?.start_date),
        venue: meetingData?.venue,
        meetingLateTime: getLocalTimeString(meetingData?.very_late_time),
        nextMeetingDate: getLocalDateString(meetingData?.next_meeting_date)
      });
      setIsLoading(false);
    }
  }, [meetingState]);

  return (
    <>
      {!isLoading ? (
        <Box>
          {currAction === "Attendance" ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <Typography variant="h5">Meeting</Typography>
                <Paper sx={{ p: 4, marginTop: 2 }}>
                  <Grid container spacing={2}>
                    <Grid container spacing={2} direction="row">
                      <Grid item xs={12} sm={3}>
                        <Stack direction="row" spacing={2}>
                          <AccessTimeFilledIcon />
                          <Typography sx={{ color: "#29292999" }}>
                            Meeting Start Time
                          </Typography>
                        </Stack>
                        <TextField
                          type="time"
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{ step: 60 }}
                          size="small"
                          sx={{ marginTop: 2, marginBottom: 2 }}
                          fullWidth
                          {...register("meetingStartTime", { required: true })}
                          error={!!errors.meetingStartTime}
                          helperText={
                            errors.meetingStartTime
                              ? "Meeting Start Time is required"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Stack direction="row" spacing={2}>
                          <AssignmentLateIcon />
                          <Typography sx={{ color: "#29292999" }}>
                            Meeting Late Time
                          </Typography>
                        </Stack>
                        <TextField
                          type="time"
                          size="small"
                          sx={{ marginTop: 2, marginBottom: 2 }}
                          fullWidth
                          {...register("meetingLateTime", { required: true })}
                          error={!!errors.meetingLateTime}
                          helperText={
                            errors.meetingLateTime
                              ? "Meeting Late Time is required"
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row">
                      <Grid item xs={12} sm={3}>
                        <Stack direction="row" spacing={2}>
                          <EventAvailableIcon />
                          <Typography sx={{ color: "#29292999" }}>
                            Meeting Start Date
                          </Typography>
                        </Stack>
                        <TextField
                          type="date"
                          InputLabelProps={{
                            shrink: true
                          }}
                          size="small"
                          sx={{ marginTop: 2, marginBottom: 2 }}
                          fullWidth
                          {...register("meetingStartDate", {
                            required: true
                          })}
                          error={!!errors.meetingStartDate}
                          helperText={
                            errors.meetingStartDate
                              ? "Meeting Start Date is required"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <Stack direction="row" spacing={2}>
                          <PendingActionsIcon />
                          <Typography sx={{ color: "#29292999" }}>
                            Next Meeting Date
                          </Typography>
                        </Stack>
                        <TextField
                          type="date"
                          size="small"
                          disabled
                          value={getLocalDateString(
                            meetingData?.next_meeting_date
                          )}
                          fullWidth
                          sx={{ marginTop: 2 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} direction="row">
                      <Grid item xs={12} sm={3}>
                        <Stack direction="row" spacing={2}>
                          <LocationOnIcon />
                          <Typography sx={{ color: "#29292999" }}>
                            Venue
                          </Typography>
                        </Stack>
                        <TextField
                          size="small"
                          fullWidth
                          sx={{ marginTop: 2 }}
                          {...register("venue", { required: true })}
                          error={!!errors.venue}
                          helperText={errors.venue ? "Venue is required" : ""}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box sx={{ padding: 2 }}>
                    <ButtonAccent
                      label={meetingData == null ? "Save" : "Update"}
                      type="submit"
                      disabled={isSubmitting}
                      fullWidth
                    />
                  </Box>
                </Paper>
              </Box>
            </form>
          ) : currAction === "Payments" ? (
            <Box>
              <Typography>Hello there !</Typography>
              <Typography>Welcome to {currAction}</Typography>
            </Box>
          ) : (
            <Box>
              <Typography>Meetings</Typography>
              <Typography>Meetings for the wedding</Typography>
            </Box>
          )}
        </Box>
      ) : null}
    </>
  );
};

export default SectionMeetings;
