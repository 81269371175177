import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import "./profile.css";

import BackArrow from "../../assets/icon/back_arrow.svg";
import Logout from "../../assets/icon/logout.svg";

import {
  getPartnerType,
  removeUserCredentials,
  setUserName,
  setUserStudioName,
} from "../../utils/utils";
import { BUCKET_PATH } from "../../utils/constant";

import {
  fetchProfile,
  updateProfilePromise,
} from "../../services/profile.service";
import {
  getPresignedUrlPromise,
  uploadImageUsingPresignedUrlPromise,
} from "../../services/uploadImage.service";

import ButtonDanger from "../../components/common/button-danger/ButtonDanger";
import ButtonPrimary from "../../components/common/button-primary/ButtonPrimary";
import InputBox from "../../components/common/input-box/InputBox";
import CustomNavbar from "../../components/layout/navbar/Navbar";
import CustomSpinner from "../../components/common/spinner/CustomSpinner";

import editWhiteIcon from "../../assets/icon/edit_white.svg";
import placeholder from "../../assets/placeholder/collection_thumbnail_fill_200x112.svg";
import TextField from "../../components/common/text-field/TextField";
import AccentLoadingButton from "../../components/common/v2/loading-button/AccentLoadingButton";
import { readProfileSuccess } from "../../redux/profile-read/readProfile.action";
import { logout } from "../../services/firebase-auth.service";

const Profile = () => {
  const imageRef = useRef();
  const history = useHistory();

  const [pageLoading, setPageLoading] = useState(true);
  const [profileFile, setProfileFile] = useState("");
  const [profilePreviewImage, setProfilePreviewImage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const readProfileStateEm = useSelector((state) => state.readProfileEm);
  const dispatch = useDispatch();

  const profileData = readProfileStateEm.response.data;

  const initialValues = {
    enterprise_name:
      !isEmpty(profileData) && !isEmpty(profileData.enterprise_name)
        ? profileData.enterprise_name
        : "",
    website:
      !isEmpty(profileData) && !isEmpty(profileData.website)
        ? profileData.website
        : "",
    name:
      !isEmpty(profileData) && !isEmpty(profileData.name)
        ? profileData.name
        : "",
    mobile:
      !isEmpty(profileData) && !isEmpty(profileData.mobile)
        ? profileData.mobile
        : "",
  };

  const onSubmit = (values) => {
    if (!submitting) {
      setSubmitting(true);
      if (profileFile) {
        let imageId = "";
        const presignedUrlPayload = [
          {
            bucket_path: BUCKET_PATH.profile,
            image_name: profileFile.name,
          },
        ];

        getPresignedUrlPromise(presignedUrlPayload)
          .then((res) => {
            imageId = res?.res?.data?.data?.[0]?.image_id;
            return uploadImageUsingPresignedUrlPromise(
              res?.res?.data?.data?.[0]?.upload_url,
              profileFile
            );
          })
          .then((response) => {
            const valuesCopy = { ...values };
            valuesCopy.thumbnail = imageId;
            return updateProfilePromise(valuesCopy);
          })
          .then((response) => {
            setSubmitting(false);
            dispatch(readProfileSuccess(response.data));
            setUserName(response?.data?.data?.name);
            setUserStudioName(response?.data?.data?.enterprise_name);
            setProfileFile("");
            setProfilePreviewImage(response?.data?.data?.thumbnail);
          });
      } else {
        updateProfilePromise(values).then((response) => {
          setSubmitting(false);
          dispatch(readProfileSuccess(response.data));
          setUserName(response?.data?.data?.name);
          setUserStudioName(response?.data?.data?.enterprise_name);
          setProfileFile("");
          setProfilePreviewImage(response?.data?.data?.thumbnail);
        });
      }
    }
  };

  const validationSchema = Yup.object({
    enterprise_name: Yup.string().required("Please enter your studio name."),
    website: Yup.string()
      .required("Please enter your website.")
      .url("Invalid url"),
    name: Yup.string().required("Please enter your name."),
    mobile: Yup.string()
      .required("Please enter your mobile number.")
      .matches(/^[6-9]\d{9}$/, "Invalid mobile number"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });

  function handleLogout(event) {
    dispatch({
      type: "USER_LOGOUT",
    });
    const partner = getPartnerType();
    removeUserCredentials();
    logout();
    history.push(`/${partner}/sign-in`);
  }

  useEffect(() => {
    if (!readProfileStateEm.loading && !isEmpty(readProfileStateEm.response)) {
      setPageLoading(false);
      setProfilePreviewImage(
        !isEmpty(readProfileStateEm.response.data.thumbnail)
          ? readProfileStateEm?.response?.data?.thumbnail
          : ""
      );
    }

    if (!readProfileStateEm.loading && !isEmpty(readProfileStateEm.error)) {
      console.log(readProfileStateEm.error);
      setPageLoading(false);
      toast.error(readProfileStateEm.error?.error_data);
    }
  }, [readProfileStateEm]);

  useEffect(() => {
    if (isEmpty(readProfileStateEm.response)) {
      dispatch(fetchProfile());
    }
  }, []);

  useEffect(() => {
    if (profileFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePreviewImage(reader.result);
      };
      reader.readAsDataURL(profileFile);
    }
  }, [profileFile]);

  return (
    <>
      {!pageLoading ? (
        <>
          <CustomNavbar />
          <div className="container pb-4">
            <div>
              <form onSubmit={formik.handleSubmit}>
                <div className="profile-header pb24-lh40">
                  <img src={BackArrow} onClick={history.goBack}></img>
                  <span className="ml-2 align-middle">Profile</span>
                </div>
                <div className="mb-3">
                  <label className="pb14-lh21 pb-1 d-flex">Studio Banner</label>
                  <div className="position-relative preview-image-container">
                    <img
                      className="preview-image"
                      src={
                        profilePreviewImage ? profilePreviewImage : placeholder
                      }
                    />
                    <button
                      className="image-button"
                      onClick={(event) => {
                        event.preventDefault();
                        imageRef.current.click();
                      }}
                    >
                      <img src={editWhiteIcon}></img>
                    </button>
                  </div>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={imageRef}
                    accept="image/png, image/jpeg, image/jpg"
                    name="banner"
                    onChange={(event) => {
                      const file = event.target.files[0];
                      if (file && file.type.substr(0, 5) === "image") {
                        setProfileFile(file);
                      }
                      // const formData = new FormData()
                      //     formData.append(BANNER_ATTACHMENT_NAMES.bucketPath, `users/${params.partnerId}/${file.name}`)
                      //     formData.append(BANNER_ATTACHMENT_NAMES.file, file)
                      //     uploadProfileBanner(formData)
                    }}
                  />
                </div>
                <label className="pb14-lh21 pb-1">Studio Name</label>
                <InputBox
                  type="text"
                  name="enterprise_name"
                  placeholder="Studio Name"
                  value={formik.values.enterprise_name}
                  onChange={formik.handleChange}
                  errorMsg={
                    formik.touched.enterprise_name &&
                    formik.errors.enterprise_name
                  }
                  onBlur={formik.handleBlur}
                />
                <label className="pb14-lh21 pb-1">Studio Website</label>
                <InputBox
                  type="url"
                  name="website"
                  placeholder="Website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  errorMsg={formik.touched.website && formik.errors.website}
                  onBlur={formik.handleBlur}
                />
                <label className="pb14-lh21 pb-1">Name</label>
                <InputBox
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  errorMsg={formik.touched.name && formik.errors.name}
                  onBlur={formik.handleBlur}
                />
                <label className="pb14-lh21 pb-1">Email ID</label>
                <TextField
                  className={"mb-3"}
                  label="Email ID"
                  value={
                    profileData && profileData.email ? profileData.email : ""
                  }
                />
                <label className="pb14-lh21 pb-1">Mobile Number</label>
                <InputBox
                  type="tel"
                  name="mobile"
                  placeholder="Mobile Number"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  errorMsg={formik.touched.mobile && formik.errors.mobile}
                  onBlur={formik.handleBlur}
                />
                <div className="d-flex flex-end justify-content-between align-items-center flex-wrap mt-3 mb-5">
                  {/* <ButtonAccent label="Save" className=" pb14-lh21 my-1 " type='submit' /> */}
                  <AccentLoadingButton
                    label="Save"
                    className="my-1"
                    type="submit"
                    loadingState={submitting}
                  />
                  <div>
                    <ButtonPrimary
                      label="Change password"
                      className=" pb14-lh21 my-1 ml-2 "
                      onClick={() => history.push("/reset-password")}
                    />
                    <ButtonDanger
                      label="LOGOUT"
                      className=" ml-2 my-1 pb14-lh21"
                      variant="icon-button"
                      onClick={handleLogout}
                      endIcon={Logout}
                      endIconClassName="ml-2"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <CustomSpinner />
      )}
    </>
  );
};

export default Profile;
