import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import useCountDownTimer from "../../../hooks/useCountDownTimer";

import Error from "../../common/v2/error/Error";
import PhoneInput from "../../common/v2/phone-input/PhoneInput";

import "./phone-authentication-form.css";

import { ROLE_CATEGORIES, STD_ERROR_MESSAGE } from "../../../utils/constant";
import { auth } from "../../../utils/init-firebase";
import {
  getOriginalUrl,
  removeOriginalUrl,
  storeUserCredentials,
} from "../../../utils/utils";

import { signUpUser } from "../../../services/auth.service";
import InputBox from "../../common/input-box/InputBox";
import AccentLoadingButton from "../../common/v2/loading-button/AccentLoadingButton";

const PhoneAuthenticationForm = (props) => {
  const recaptchaContainerRef = useRef();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [time, start, isTicking] = useCountDownTimer();

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  function generateRecaptcha() {
    resetRecaptcha();
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  }

  function requestOtp(mobileNumber) {
    generateRecaptcha();
    signInWithPhoneNumber(auth, mobileNumber, window.recaptchaVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        hideLoading();
        formik.setFieldValue("isExpanded", true);
        start();
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        hideLoading();
        toast.error(error?.code || STD_ERROR_MESSAGE);
      });
  }

  function resetConfirmationResult() {
    const confirmationResult = window.confirmationResult;
    if (confirmationResult) window.confirmationResult = null;
  }

  function resetRecaptcha() {
    const recaptcha = window.recaptchaVerifier;
    if (recaptcha) window.recaptchaVerifier = null;
  }

  function verifyOTP(otp, mobileNumber) {
    const confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otp)
      .then((result) => {
        // User signed in successfully.
        const payload = {
          firebase_id: result?.user?.uid,
          category: ROLE_CATEGORIES.other,
          mobile: mobileNumber,
        };
        signUpUser(payload)
          .then((response) => {
            storeUserCredentials({
              authToken: response.data.access_token,
              userId: response.data.user.id,
              userRole: response.data.user.category || ROLE_CATEGORIES.other,
            });
            hideLoading();
            const originalUrl = getOriginalUrl();
            if (originalUrl) {
              history.push(originalUrl);
              removeOriginalUrl();
            } else {
              history.push("/collections");
            }
          })
          .catch((err) => {
            hideLoading();
            toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
          });
      })
      .catch((error) => {
        hideLoading();
        toast.error(error?.code || STD_ERROR_MESSAGE);
      });
  }

  function onSubmit(values) {
    showLoading();
    if (values.isExpanded) {
      verifyOTP(values.otp, values.mobile);
    } else {
      requestOtp(values.mobile);
    }
  }

  const initialValues = {
    isExpanded: false,
    mobile: "",
    otp: "",
  };

  const validationSchema = Yup.object({
    isExpanded: Yup.boolean().required(),
    mobile: Yup.string()
      .required("Please enter your mobile number.")
      .test("validatePhoneNumber", "Invalid mobile number", (val) => {
        return val && isValidPhoneNumber(val);
      }),
    otp: Yup.string().when("isExpanded", {
      is: (value) => value,
      then: Yup.string()
        .required("Enter OTP")
        .min(6, "Invalid OTP")
        .max(6, "Invalid OTP"),
      otherwise: Yup.string(),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleOtpInputChange = (e) => {
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (
      (e.target.value === "" || re.test(e.target.value)) &&
      e.target.value.length <= 6
    ) {
      formik.setFieldValue("otp", e.target.value);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h1 className="pb18-lh32 text-center mb-3">
        {formik.values.isExpanded ? "Verify with OTP" : "Sign in to TrueCo"}
      </h1>
      <div className="mb-3">
        <PhoneInput
          value={formik.values.mobile}
          disabled={formik.values.isExpanded}
          onChange={(value) => formik.setFieldValue("mobile", value)}
          name="mobile"
          placeholder="Mobile Number"
          onBlur={formik.handleBlur}
          error={formik.touched.mobile && formik.errors.mobile}
        />

        {formik.touched.mobile && formik.errors.mobile && (
          <Error errorMsg={formik.errors.mobile} className="mt-2" />
        )}
        {formik.values.isExpanded && (
          <h4
            className="high-emphasis-color pb12-lh18 mt-2 mb-0 pt-cursor"
            style={{ textAlign: "end" }}
            onClick={() => {
              resetConfirmationResult();
              formik.setFieldValue("isExpanded", false);
            }}
          >
            Edit
          </h4>
        )}
      </div>
      {formik.values.isExpanded && (
        <>
          <InputBox
            name="otp"
            type="text"
            placeholder="Enter OTP"
            value={formik.values.otp}
            onChange={handleOtpInputChange}
            onBlur={formik.handleBlur}
          />
          <div className="d-flex align-items-center mb-3">
            <h4 className="mr-2 medium-emphasis-color pr14-lh24 mb-0">
              Didn't receive OTP?
            </h4>
            {!isTicking() ? (
              <h4
                className="resend-otp pb14-lh21 mb-0 information-color pt-cursor"
                onClick={() => {
                  resetConfirmationResult();
                  requestOtp(formik.values.mobile);
                }}
              >
                RESEND
              </h4>
            ) : (
              <h4 className="resend-otp pr14-lh21 mb-0">
                Resend in <span className="pb14-lh21 mb-0">{time}s</span>
              </h4>
            )}
          </div>
        </>
      )}
      <AccentLoadingButton
        loadingState={loading}
        type="submit"
        className="w-100 pb14-lh21 mb-4"
        label={formik.values.isExpanded ? "Verify" : "Get OTP"}
      />
      <div
        ref={recaptchaContainerRef}
        className="d-none"
        id="recaptcha-container"
      ></div>
    </form>
  );
};

export default PhoneAuthenticationForm;
