import {
  fetchMeetingFailure,
  fetchMeetingSuccess,
  requestFetchMeeting,
} from "../redux/meeting/meeting.action";
import {
  fetchMeetingHistoryFailure,
  fetchMeetingHistorySuccess,
  requestFetchHistoryMeeting,
} from "../redux/meeting/meetingHistory.action";
import protectedWebClient from "../utils/protected-web-client";

export const getMeetingByEvent = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchMeeting());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/meeting`
      );
      dispatch(fetchMeetingSuccess(response?.data));
    } catch (error) {
      dispatch(fetchMeetingFailure(error?.response));
    }
  };
};

export const getHistoricalMeetingByEvent = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchHistoryMeeting());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/meeting/history`
      );
      dispatch(fetchMeetingHistorySuccess(response?.data));
    } catch (error) {
      dispatch(fetchMeetingHistoryFailure(error?.response));
    }
  };
};

export const createMeeting = (meeting, collectionId) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .post(`/event/${collectionId}/meeting`, meeting)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const getMeeting = (meetingId, collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchMeeting());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/meeting/${meetingId}`
      );
      dispatch(fetchMeetingSuccess(response?.data));
    } catch (error) {
      dispatch(fetchMeetingFailure(error?.response));
    }
  };
};

export const deleteMeeting = (meetingId, collectionId) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .delete(`/event/${collectionId}/meeting/${meetingId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updateMeeting = (payload, meetingId, collectionId) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .put(`/event/${collectionId}/meeting/${meetingId}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};
