import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";

const BreadCrumbs = ({ title, subTitle, titleLink }) => {
  return (
    <Box>
      <Stack direction="row" spacing={1} sx={{ display: "inline-flex" }}>
        <Link to={titleLink}>
          <Typography variant="h5" fontSize={{ xs: 18, md: 23 }}>
            {title}
          </Typography>
        </Link>
        <ChevronRightIcon sx={{ margin: "0.3rem" }} />
        <Typography variant="h6" fontSize={{ xs: 16, md: 22 }}>
          {subTitle}
        </Typography>
      </Stack>
    </Box>
  );
};

export default BreadCrumbs;
