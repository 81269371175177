import {
  fetchGroupAccessMappingEmFailure,
  fetchGroupAccessMappingEmSuccess,
} from "../redux/group-access-mapping/groupAccessMapping.action";
import {
  fetchAccessGroupEmFailure,
  fetchAccessGroupEmSuccess,
  requestFetchAccessGroupEm,
} from "../redux/group-for-auth/authGroup.action";
import {
  fetchAccessGroupUserEmFailure,
  fetchAccessGroupUserEmSuccess,
  requestFetchAccessGroupUserEm,
} from "../redux/group-users/groupUsers.action";
import ProtectedWebClient from "../utils/protected-web-client";

//For Groups
export const getAllAccessGroups = (entityId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAccessGroupEm());
      const fetchedAccessGroupResponse = await ProtectedWebClient.get(
        `/entity/${entityId}/group`
      );
      dispatch(fetchAccessGroupEmSuccess(fetchedAccessGroupResponse?.data));
    } catch (error) {
      dispatch(fetchAccessGroupEmFailure(error?.response));
    }
  };
};

export const createAccessGroupPromise = (entityId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/entity/${entityId}/group`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const getAccessGroupId = (entityId, groupId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAccessGroupEm());
      const fetchedAccessGroupResponse = await ProtectedWebClient.get(
        `/entity/${entityId}/group/${groupId}`
      );
      dispatch(fetchAccessGroupEmSuccess(fetchedAccessGroupResponse?.data));
    } catch (error) {
      dispatch(fetchAccessGroupEmFailure(error?.response));
    }
  };
};

export const deleteGroupById = (entityId, groupId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/entity/${entityId}/group/${groupId}`)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

//For GroupMembers

export const getAccessGroupUsers = (entityId, groupId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAccessGroupUserEm());
      const fetchedAccessGroupResponse = await ProtectedWebClient.get(
        `/entity/${entityId}/group/${groupId}/user`
      );
      dispatch(fetchAccessGroupUserEmSuccess(fetchedAccessGroupResponse?.data));
    } catch (error) {
      dispatch(fetchAccessGroupUserEmFailure(error?.response));
    }
  };
};

export const addUsersToAccessGroup = (entityId, groupId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(
      `/entity/${entityId}/group/${groupId}/user`,
      payload
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const removeUserFromAccessGroup = (entityId, groupId, userId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(
      `/entity/${entityId}/group/${groupId}/user/${userId}`
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

//For Group access mapping

export const getAccessGroupForGallery = (entityId, galleryId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAccessGroupUserEm());
      const fetchedAccessGroupResponse = await ProtectedWebClient.get(
        `/entity/${entityId}/gallery/${galleryId}/access`
      );
      dispatch(
        fetchGroupAccessMappingEmSuccess(fetchedAccessGroupResponse?.data)
      );
    } catch (error) {
      dispatch(fetchGroupAccessMappingEmFailure(error?.response));
    }
  };
};

export const getAccessGroupForFeed = (entityId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAccessGroupUserEm());
      const fetchedAccessGroupResponse = await ProtectedWebClient.get(
        `/entity/${entityId}/feedAccess`
      );
      dispatch(
        fetchGroupAccessMappingEmSuccess(fetchedAccessGroupResponse?.data)
      );
    } catch (error) {
      dispatch(fetchGroupAccessMappingEmFailure(error?.response));
    }
  };
};

export const addOrUpdateGalleryAccess = (entityId, galleryId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(
      `/entity/${entityId}/gallery/${galleryId}/access`,
      payload
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const addOrUpdateEntityFeedAccess = (entityId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/entity/${entityId}/feedAccess`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const removeAccessForGroup = (entityId, groupId, galleryId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(
      `/entity/${entityId}/group/${groupId}/gallery/${galleryId}/access`
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};
