import "./infoscreen.css";

const AboutUs = () => {
  return (
    <div className="container">
      <h5 className="heading">About Us</h5>
      <div className="pr16-lh24">
        <p className="mb-2">
          TrueCo is an online platform that offers social microsites for
          independent businesses. We can help you easily create a strong online
          presence among your customers.
        </p>
        <p className="mb-2">The main benefits that TrueCo offers are -</p>
        <ul className="ml-4">
          <li>
            It is cost effective, being significantly cheaper than building a
            website
          </li>
          <li>
            It is a self-service platform, changes to the microsite don't need a
            software developer
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AboutUs;
