import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from "react-toastify";
import useWindowDimension from "../../../hooks/useWindowDimension";
import Welcome from "../games/welcome/Welcome";
import Question from "../games/question/Question";
import HighScore from "../games/high-score/HighScore";
import Caution from "../games/caution/Caution";
import { fetchGameQuestionUser } from "../../../services/games.service";
import {
  SECTION_GAMES_USER_COMPONENTS,
  STD_ERROR_MESSAGE,
} from "../../../utils/constant";
import { changeGameStartedState } from "../../../redux/games/games.action";

export default function UserGamesSection(props) {
  const isMobileOrTablet = useMediaQuery("(max-width: 599px)");
  const heightToBeSubtracted = isMobileOrTablet ? 56 + 56 + 40 : 64 + 56 + 40;

  const [loading, setLoading] = useState(true);
  const [currComponent, setCurrComponent] = useState(null);
  const params = useParams();
  const dispatch = useDispatch();
  const gameQuestionsState = useSelector((state) => state.gamesEm);

  const questionData = gameQuestionsState.response?.data;

  const marginVertical = 16;

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { width, height } = useWindowDimension();

  useEffect(() => {
    if (isEmpty(gameQuestionsState.response)) {
      dispatch(fetchGameQuestionUser(props.collectionId));
    }
  }, []);
  useEffect(() => {
    if (
      !gameQuestionsState.loading &&
      !isEmpty(gameQuestionsState.response) &&
      !gameQuestionsState.isStarted
    ) {
      if (
        !questionData.total_questions ||
        questionData.current_question === 1
      ) {
        setCurrComponent(SECTION_GAMES_USER_COMPONENTS.welcome);
      } else {
        dispatch(changeGameStartedState(true));
      }
    }

    if (!gameQuestionsState.loading && !isEmpty(gameQuestionsState.error)) {
      toast.error(STD_ERROR_MESSAGE);
    }
  }, [gameQuestionsState.loading, gameQuestionsState.response]);

  useEffect(() => {
    if (
      gameQuestionsState.isStarted &&
      currComponent !== SECTION_GAMES_USER_COMPONENTS.question
    ) {
      setCurrComponent(SECTION_GAMES_USER_COMPONENTS.question);
    }
  }, [gameQuestionsState.isStarted]);

  useEffect(() => {
    if (loading) setLoading(false);
  }, [currComponent]);

  useEffect(() => {
    setContainerDimensions();
  }, [width, height]);

  function setContainerDimensions() {
    getContainerDimensions(458 / 689, 458 / 689).then((res) => {
      setDimensions(res);
    });
  }

  function getContainerDimensions(widthBasedRatio, heightBasedRatio) {
    return new Promise((resolve, reject) => {
      const contentWidth = width;
      const contentHeight =
        height - marginVertical - marginVertical - heightToBeSubtracted;

      if (contentWidth > contentHeight) {
        resolve(
          getWidthFromHeight(contentWidth, contentHeight, heightBasedRatio)
        );
      } else {
        resolve(
          getHeightFromWidth(contentWidth, contentHeight, widthBasedRatio)
        );
      }
    });
  }

  function changeCurrComponent(component) {
    setCurrComponent(component);
  }

  // width based
  function getHeightFromWidth(maxWidth, maxHeight, ratio) {
    let mHeight = 0;
    let mWidth = maxWidth;
    mHeight = Math.floor(maxWidth / ratio);
    if (mHeight > maxHeight) {
      mHeight = maxHeight;
      mWidth = Math.floor(maxHeight * ratio);
    }
    return {
      width: mWidth,
      height: mHeight,
    };
  }

  // height based
  function getWidthFromHeight(maxWidth, maxHeight, ratio) {
    let mHeight = maxHeight;
    let mWidth = 0;
    mWidth = Math.floor(ratio * mHeight);
    if (mWidth > maxWidth) {
      mWidth = maxWidth;
      mHeight = Math.floor(maxWidth / ratio);
    }
    return {
      width: mWidth,
      height: mHeight,
    };
  }

  function getComponent(component) {
    switch (component) {
      case SECTION_GAMES_USER_COMPONENTS.welcome:
        return <Welcome />;
      case SECTION_GAMES_USER_COMPONENTS.question:
        return <Question changeCurrComponent={changeCurrComponent} />;
      case SECTION_GAMES_USER_COMPONENTS.leaderBoard:
        return <HighScore changeCurrComponent={changeCurrComponent} />;
      case SECTION_GAMES_USER_COMPONENTS.caution:
        return <Caution changeCurrComponent={changeCurrComponent} />;
      default:
        return null;
    }
  }

  return (
    <div
      className="d-flex align-items-center"
      style={{
        maxWidth: `${dimensions.width}px`,
        minHeight: `calc(100vh - ${heightToBeSubtracted}px)`,
        marginLeft: "auto",
        marginRight: "auto",
        overflow: "overflow",
      }}
    >
      <div
        style={{
          display: "flex",
          minHeight: `${dimensions.height}px`,
          maxHeight: `${dimensions.height}px`,
          flex: 1,
          overflow: "auto",
        }}
      >
        {!loading ? getComponent(currComponent) : null}
      </div>
    </div>
  );
}
