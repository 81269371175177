import React, { useEffect, useState } from "react";
import AddCoupleStory from "../../add-couple-story/AddCoupleStory.js";
import KnowTheHost from "../../know_the_host/KnowTheHost.jsx";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchKnowTheHostSection } from "../../../../services/knowTheHost.service.js";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import {
  SECTION_KNOW_THE_HOST_COMPONENTS,
  STD_ERROR_MESSAGE,
  WEDDING_FOLDERS,
} from "../../../../utils/constant.js";

const SectionKnowTheCouple = (props) => {
  const [loading, setLoading] = useState(true);
  const knowTheHostState = useSelector((state) => state.knowTheHostEm);
  const dispatch = useDispatch();
  const params = useParams();

  const [currentMainComponent, setCurrentMainComponent] = useState({
    currComponent: null,
    prevComponent: null,
  });

  useEffect(() => {
    if (isEmpty(knowTheHostState.response)) {
      dispatch(fetchKnowTheHostSection(params.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!knowTheHostState.loading && !isEmpty(knowTheHostState.response)) {
      if (!isEmpty(knowTheHostState.response.data)) {
        setCurrentMainComponent((prevState) => {
          return {
            currComponent:
              SECTION_KNOW_THE_HOST_COMPONENTS.list_know_the_host_sections,
            prevComponent: prevState.component,
          };
        });
      } else {
        setCurrentMainComponent((prevState) => {
          return {
            currComponent:
              SECTION_KNOW_THE_HOST_COMPONENTS.add_know_the_host_sections,
            prevComponent: prevState.component,
          };
        });
      }
      setLoading(false);
    }
    if (!knowTheHostState.loading && !isEmpty(knowTheHostState.error)) {
      setLoading(false);
      toast.error(
        knowTheHostState.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [knowTheHostState]);

  function getMainComponent() {
    switch (currentMainComponent.currComponent) {
      case SECTION_KNOW_THE_HOST_COMPONENTS.add_know_the_host_sections:
        return (
          <AddCoupleStory
            category={props.category}
            setCurrentMainComponent={setCurrentMainComponent}
            currentMainComponent={currentMainComponent}
          />
        );
      case SECTION_KNOW_THE_HOST_COMPONENTS.list_know_the_host_sections:
        return (
          <KnowTheHost
            category={props.category}
            setCurrentMainComponent={setCurrentMainComponent}
            currentMainComponent={currentMainComponent}
            onMasterInviteBtnClick={() =>
              props.setSelected({
                folder: WEDDING_FOLDERS.master_invite_list,
                subFolder: null,
              })
            }
          />
        );
      default:
        return null;
    }
  }

  return <>{!loading ? getMainComponent() : null}</>;
};

export default SectionKnowTheCouple;
