import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import editWhiteIcon from "../../../assets/icon/edit_white.svg";
import uploadIcon from "../../../assets/icon/upload.svg";

import ButtonAccent from "../../common/button-accent/ButtonAccent";
import ButtonDanger from "../../common/button-danger/ButtonDanger";
import InputBox from "../../common/input-box/InputBox";
import PopUp from "../../common/pop-up/PopUp";
import PhoneInput from "../../common/v2/phone-input/PhoneInput";

import "./logout-popup.css";

import {
  removeUserCredentials,
  getUserId,
  getImageUrl,
} from "../../../utils/utils";
import {
  fetchProfile,
  updateProfilePromise,
} from "../../../services/profile.service";
import { BUCKET_PATH, STD_ERROR_MESSAGE } from "../../../utils/constant";
import {
  deleteFaceRecData,
  detectFacePromise,
  getPresignedUrlPromise,
  uploadImageUsingPresignedUrlPromise,
} from "../../../services/uploadImage.service";
import { clearMyPhotosGalleryFoldersEmStates } from "../../../redux/gallery-folders/myPhotosGalleryFolders.action";
import AccentLoadingButton from "../../common/v2/loading-button/AccentLoadingButton";

export const PreviewProfile = (props) => {
  const { src } = props;
  return (
    <div style={{ textAlign: "center" }}>
      <img
        style={{
          width: "auto",
          height: "auto",
          maxWidth: "250px",
          maxHeight: "250px",
        }}
        src={src}
        alt=""
      />
    </div>
  );
};

const LogoutPopup = (props) => {
  const { handleClose } = props;

  const profileState = useSelector((state) => state.readProfileEm);
  const dispatch = useDispatch();
  const history = useHistory();
  const imageRef = useRef({ profilePic: null });
  const [errorMessage, setErrorMessage] = useState({});
  const [imageFiles, setImageFiles] = useState({ profilePic: null });
  const profileData = profileState.response?.data;
  const [profilePicUploading, setProfilePicUploading] = useState(false);

  useEffect(() => {
    if (imageFiles.current && imageFiles[imageFiles.current]?.file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFiles((ps) => {
          return {
            ...ps,
            current: null,
            [ps.current]: {
              ...ps[ps.current],
              preview: reader.result,
            },
          };
        });
      };
      reader.readAsDataURL(imageFiles[imageFiles.current].file);
    }
  }, [imageFiles]);

  useEffect(() => {
    if (isEmpty(profileState.response)) {
      dispatch(fetchProfile(getUserId()));
    }
  }, []);

  useEffect(() => {
    if (!profileState.loading && !isEmpty(profileState.error)) {
      toast.error(STD_ERROR_MESSAGE);
    }
  }, [profileState]);

  const initialValues = {
    name: profileData?.name || "",
    mobile: profileData?.mobile || "",
    email: profileData?.email || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter your name"),
  });

  const onSubmit = (values) => {
    if (imageFiles?.profilePic?.file) {
      setProfilePicUploading(true);
      uploadImagePromise(imageFiles.profilePic?.file, "profilePic");
    }
    if (values.name !== profileData?.name) {
      setProfilePicUploading(true);
      updateProfilePromise({ name: values.name })
        .then((res) => {
          setProfilePicUploading(false);
          toast.success("Updated name successfully.");
          dispatch(fetchProfile(getUserId()));
        })
        .catch((err) => {
          setProfilePicUploading(false);
          toast.error(STD_ERROR_MESSAGE);
        });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });

  function handleLogOutBtnClick() {
    logOutUser();
    removeUserCredentials();
    history.push(`/sign-in`);
  }

  function handleImageChange(event, profilePic) {
    const file = event.target.files[0];
    if (errorMessage?.profilePic) {
      setErrorMessage((ps) => {
        const { profilePic, ...rest } = ps;
        return {
          ...rest,
        };
      });
    }
    setImageFiles((ps) => {
      return {
        ...ps,
        current: profilePic,
        [profilePic]: {
          file,
        },
      };
    });
  }

  function uploadImagePromise(imageFile, profilePic) {
    let imageId = "";
    const presignedUrlPayload = [
      {
        bucket_path: `${BUCKET_PATH.user}/${getUserId()}/${
          BUCKET_PATH.profile
        }/${imageFile.name}`,
        image_name: imageFile.name,
      },
    ];
    detectFacePromise(imageFile, { profilePic })
      .then((res) => {
        getPresignedUrlPromise(presignedUrlPayload, { profilePic })
          .then((response) => {
            imageId = response?.res?.data?.data?.[0]?.image_id;
            return uploadImageUsingPresignedUrlPromise(
              response?.res?.data?.data?.[0]?.upload_url,
              imageFile,
              { profilePic, imageId }
            );
          })
          .then((res) => {
            return updateProfilePromise({ thumbnail: imageId });
          })
          .then((res) => {
            dispatch(fetchProfile(res.data));
            setProfilePicUploading(false);
            toast.info(
              "We are scanning the gallery for your photos. Please refresh this page after 5 minutes."
            );
          });
      })
      .then((res) => {
        return deleteFaceRecData();
      })
      .catch((error) => {
        setProfilePicUploading(false);
        toast.error(
          !isEmpty(error?.err?.response?.data?.error_data)
            ? error?.err?.response?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
    dispatch(clearMyPhotosGalleryFoldersEmStates());
  }

  function logOutUser() {
    dispatch({
      type: "USER_LOGOUT",
    });
  }

  return (
    <PopUp containerClassName="logout-container" handleClose={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        {profileData?.email ? (
          <div
            style={{
              pointerEvents: "none",
              opacity: "0.6",
            }}
          >
            <InputBox
              type="text"
              name="Email"
              placeholder="Enter Your Email Id"
              value={profileData?.email}
              onChange={formik.handleChange}
            />
          </div>
        ) : null}
        {profileData?.mobile ? (
          <div className="mb-3">
            <PhoneInput
              value={profileData?.mobile}
              disabled={true}
              name="mobile"
              placeholder="Mobile Number"
              onChange={(value) => formik.setFieldValue("mobile", value)}
            />
          </div>
        ) : null}
        <InputBox
          type="text"
          name="name"
          placeholder="Enter Your Name here"
          value={formik.values.name}
          onChange={formik.handleChange}
          errorMsg={formik.touched.name && formik.errors.name}
          onBlur={formik.handleBlur}
        />
        <div className="profilePic">
          <button
            className={`collection-thumbnail-edit pt-cursor ${
              imageFiles.profilePic?.preview || profileData.image ? "" : "hide"
            }`}
            onClick={(event) => {
              event.preventDefault();
              imageRef.current.click();
            }}
          >
            <img
              className="edit-collection-thumbnail-icon"
              src={editWhiteIcon}
              alt=""
            />
          </button>

          {imageFiles.profilePic?.preview || profileData.image ? (
            <PreviewProfile
              src={
                imageFiles.profilePic?.preview ||
                getImageUrl(profileData.image?.bucket_path)
              }
            />
          ) : (
            <div
              className="dropzone"
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                event.preventDefault();
                imageRef.current.click();
              }}
            >
              <img
                className="upload-icon-size mb-3"
                src={uploadIcon}
                alt="Upload Icon"
              ></img>
              <ButtonAccent
                className="upload-btn-padding pb14-lh21"
                label="Upload your picture here"
              />
            </div>
          )}

          <input
            type="file"
            style={{ display: "none" }}
            ref={imageRef}
            accept="image/png, image/jpeg, image/jpg"
            onChange={(event) => handleImageChange(event, "profilePic")}
          />
        </div>
        <div className="d-flex justify-content-between mt-3">
          <AccentLoadingButton
            label="Save"
            type="submit"
            className="save-btn pb14-lh21"
            loadingState={profilePicUploading}
          />
          <ButtonDanger
            label="LOGOUT"
            className="pb14-lh21"
            onClick={handleLogOutBtnClick}
          />
        </div>
      </form>
    </PopUp>
  );
};

export default LogoutPopup;
