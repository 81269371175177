import {
  Box,
  Button,
  Checkbox,
  Chip,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import {
  DataGrid,
  GridRowEditStopReasons,
  GridRowModes,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonAccent from "../../components/common/button-accent/ButtonAccent";
import {
  getAttendanceSummaryByEventAndMeetingId,
  updateAttendanceSummaryMember,
} from "../../services/attendanceMember.service";
import { getStatusbgColor, getStatusColor } from "./AttendanceRecord";

const AttendanceRecordForMember = (props) => {
  const { collectionId, meetingData } = props;
  const [rowModesModel, setRowModesModel] = useState({});
  const [isSubmitting, setIsSubmitting] = useState({});

  const dispatch = useDispatch();

  const attendanceSummaryMemberState = useSelector(
    (state) => state.attendanceSummaryMemberEm
  );
  const attendanceSummaryMemberData =
    attendanceSummaryMemberState?.response?.data;

  useEffect(() => {
    if (
      !attendanceSummaryMemberState.loading &&
      !attendanceSummaryMemberData &&
      meetingData
    ) {
      dispatch(
        getAttendanceSummaryByEventAndMeetingId(collectionId, meetingData.id)
      );
    }
  }, []);

  const [popup, setPopup] = useState(false);

  function renderSubstitute(params) {
    console.log("params :", params);
    return <Checkbox checked={params.formattedValue} color="success" />;
  }

  function SubstituteEditInputCell(props) {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef(null);

    const handleChange = (event, newValue) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    useEnhancedEffect(() => {
      if (hasFocus && ref.current) {
        const input = ref.current.querySelector(`input[value="${value}"]`);
        input?.focus();
      }
    }, [hasFocus, value]);

    return (
      <Checkbox
        ref={ref}
        name="substitute"
        value={value}
        checked={value}
        color="success"
        onChange={handleChange}
      />
    );
  }

  const renderSubstituteEditInputCell = (params) => {
    return <SubstituteEditInputCell {...params} />;
  };

  const columns = [
    {
      field: "id",
      headerName: "SL No",
      width: 70,
      editable: false,
      renderCell: (params) => (
        <span>
          {params.api.getSortedRowIds().indexOf(params?.id ?? "") + 1}
        </span>
      ),
    },
    {
      field: "member_name",
      headerName: "Member Name",
      editable: false,
      width: 200,
    },
    {
      field: "attendance_status",
      headerName: "Attendance Status",
      width: 200,
      editable: false,
      renderCell: (params) => {
        let status = "";
        status = params.formattedValue;
        return (
          <>
            {status !== "NOT VALIDATE" && (
              <Chip
                label={status}
                style={{ margin: "0.8rem" }}
                sx={{
                  backgroundColor: `${getStatusbgColor(status)}`,
                  color: `${getStatusColor(status)}`,
                  fontWeight: "bold",
                  borderRadius: "16px",
                  "& .MuiChip-label": {
                    padding: "0 12px",
                  },
                }}
              />
            )}
          </>
        );
      },
    },
    {
      field: "substitute",
      headerName: "Substitute",
      width: 200,
      editable: true,
      renderCell: renderSubstitute,
      renderEditCell: renderSubstituteEditInputCell,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 200,
      editable: true,
      renderCell: (params) => {
        <TextField
          value={params.formattedValue}
          variant="standard"
          size="small"
          sx={{ p: 1 }}
        />;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      editable: false,
      renderCell: (params) => {
        const status = params.row.attendance_status;
        const isInEditMode =
          rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
        return (
          <Button
            variant="contained"
            disableElevation={true}
            size="small"
            onClick={() => {
              setIsSubmitting((prev) => {
                return { ...prev, [params.row.id]: true };
              });
              if (isInEditMode) {
                onClickValidate(params.row);
              } else {
                setRowModesModel({
                  ...rowModesModel,
                  [params.row.id]: { mode: GridRowModes.Edit },
                });
              }
            }}
            style={{
              color: status === "Not Validated" ? "black" : "white",
              backgroundColor:
                status === "Not Validated" ? "var(--accent-color)" : "black",
            }}
          >
            {status === "Not Validated"
              ? "Validate"
              : isInEditMode || isSubmitting[params.row.id]
              ? "Save"
              : "Edit"}
          </Button>
        );
      },
    },
  ];

  const onSubmit = async (data) => {
    setIsSubmitting((prev) => {
      return { ...prev, [data.id]: true };
    });
    const payload = {
      id: data.id,
      substitute: data.substitute,
      notes: data.notes,
    };
    try {
      const res = await updateAttendanceSummaryMember(payload, collectionId);
      if (res.error_data) {
        toast.error("Failed To update");
        return;
      }
      toast.success("Attendance updated successfully");
      setPopup(false);
    } catch (e) {
      toast.error("Failed To update");
    } finally {
      setIsSubmitting((prev) => {
        return { ...prev, [data.id]: false };
      });
      dispatch(
        getAttendanceSummaryByEventAndMeetingId(collectionId, meetingData.id)
      );
    }
  };

  const [popData, setPopData] = useState({});

  const onClickValidate = (data) => {
    const currentTime = new Date();
    const meetingTime = new Date(meetingData?.start_date);
    const lateTime = new Date(meetingData?.very_late_time);
    let attendanceStatus = "";
    if (currentTime <= meetingTime) {
      attendanceStatus = "On Time";
    } else if (currentTime >= lateTime) {
      attendanceStatus = "Late";
    } else {
      attendanceStatus = "Slightly Late";
    }
    setPopData({ ...data, attendance_status: attendanceStatus });
    setPopup(true);
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    onSubmit(newRow);
    return updatedRow;
  };

  const id = popup ? "simple-popover" : undefined;
  return (
    <Paper>
      <DataGrid
        sx={{
          "& ::-webkit-scrollbar": {
            width: "20px",
          },
          "& ::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
          "& ::-webkit-scrollbar-thumb": {
            borderRadius: "2px",
            backgroundColor: "#ffffff",
          },
        }}
        rows={attendanceSummaryMemberData}
        columns={columns}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        editMode="row"
        pageSize={5}
        rowsPerPageOptions={[5]}
        validate={({ row }) => row.some((cell) => cell.value !== "")}
      />
      {popup && (
        <Popover
          id={id}
          open={popup}
          onClose={() => setPopup(false)}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Typography sx={{ p: 2 }}>{popData.member_name} is </Typography>
            <Chip
              label={popData.attendance_status}
              style={{ margin: "0.8rem" }}
              sx={{
                backgroundColor: `${getStatusbgColor(
                  popData.attendance_status
                )}`,
                color: `${getStatusColor(popData.attendance_status)}`,
                fontWeight: "bold",
                borderRadius: "16px",
                "& .MuiChip-label": {
                  padding: "0 12px",
                },
              }}
            />
          </Stack>
          <Box
            sx={{
              p: 2,
              display: "flex",
              gap: 2,
            }}
          >
            <Button
              variant="text"
              label="Close"
              onClick={() => setPopup(false)}
              sx={{ color: "#EB4E2C" }}
            >
              Close
            </Button>
            <ButtonAccent
              label="Ok"
              type="submit"
              onClick={() => {
                setRowModesModel({
                  ...rowModesModel,
                  [popData.id]: { mode: GridRowModes.View },
                });
              }}
            />
          </Box>
        </Popover>
      )}
    </Paper>
  );
};

export default AttendanceRecordForMember;
