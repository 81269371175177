import {
  CLEAR_FETCH_ACCESS_LVH_GROUP_USER_EM_STATES,
  FETCH_ACCESS_LVH_GROUP_USER_EM_FAILURE,
  FETCH_ACCESS_LVH_GROUP_USER_EM_SUCCESS,
  REQUEST_ACCESS_LVH_GROUP_USER_FETCH_EM,
} from "./lvhGroupUsers.type";

export const requestFetchAccessLvhGroupUserEm = () => {
  return {
    type: REQUEST_ACCESS_LVH_GROUP_USER_FETCH_EM,
  };
};

export const fetchAccessLvhGroupUserEmSuccess = (response) => {
  return {
    type: FETCH_ACCESS_LVH_GROUP_USER_EM_SUCCESS,
    payload: response,
  };
};

export const fetchAccessLvhGroupUserEmFailure = (error) => {
  return {
    type: FETCH_ACCESS_LVH_GROUP_USER_EM_FAILURE,
    payload: error,
  };
};

export const clearFetchAccessLvhGroupUserEmStates = () => {
  return {
    type: CLEAR_FETCH_ACCESS_LVH_GROUP_USER_EM_STATES,
  };
};
