import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchPolicies } from "../../../services/policies.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { toast } from "react-toastify";
import "./user-policies.css";
import { useMediaQuery } from "@mui/material";

const UserPolicies = (props) => {
  const [pageLoading, setPageLoading] = useState(true);

  const params = useParams();
  const policiesState = useSelector((state) => state.policiesAd);
  const dispatch = useDispatch();

  const policiesData = policiesState?.response?.data;

  const isMobile = useMediaQuery("(max-width: 480px)");

  const isMobileOrTablet = useMediaQuery("(max-width: 599px)");
  const minHeight = isMobileOrTablet ? 56 + 56 + 40 : 64 + 56 + 40;
  useEffect(() => {
    if (isEmpty(policiesState?.response)) {
      dispatch(fetchPolicies(props.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!policiesState?.loading && !isEmpty(policiesState?.response)) {
      setPageLoading(false);
    }
    if (!policiesState?.loading && !isEmpty(policiesState?.error)) {
      setPageLoading(false);
      toast.error(policiesState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [policiesState]);

  return (
    <>
      <main
        style={{
          maxWidth: "90%",
          marginLeft: "auto",
          marginRight: "auto",
          minHeight: `calc(100vh - ${minHeight}px)`,
        }}
      >
        <div className="top-box policy-header">
          <div className="inner-text flex-1-1">
            <span className="text-messages">Policy</span>
            <div className="policy-divider" />
          </div>
          <div className="inner-text flex-2-1">
            <span className="text-messages">Description</span>
            <div className="policy-divider" />
          </div>
          <div className="inner-text flex-2-1">
            <span className="text-messages">Link</span>
          </div>
        </div>
        {!pageLoading && !isEmpty(policiesData) ? (
          policiesData?.map((p) => (
            <div key={p.id} className="detail-box policy-content">
              <div className="inner-text flex-1-1">
                <span className="text-messages">{p.policy_name}</span>
                <div className="policy-divider" />
              </div>

              <div className="inner-text flex-2-1">
                <span className="text-messages">{p.description}</span>
                <div className="policy-divider" />
              </div>

              <div className="inner-text flex-2-1">
                <span className="text-messages">
                  <a href={p.link} target="_blank">
                    {p.link}
                  </a>
                </span>
              </div>
            </div>
          ))
        ) : (
          <h4 className="text-center pr20-lh32 high-emphasis-color mt-5">
            You have no policies to view.
          </h4>
        )}
      </main>
    </>
  );
};

export default UserPolicies;
