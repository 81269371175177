import Looks5Icon from "@mui/icons-material/Looks5";
import { Chip, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAttendanceSummaryByEventAndMeetingId } from "../../services/attendanceMember.service";
import { getLocalTimeString } from "../../utils/constant";
import { getStatusbgColor, getStatusColor } from "./AttendanceRecord";

const AttendanceSummaryForMember = (props) => {
  const { collectionId, meetingId } = props;
  const dispatch = useDispatch();
  const attendanceSummaryMemberState = useSelector(
    (state) => state.attendanceSummaryMemberEm
  );
  const attendanceSummaryMemberData =
    attendanceSummaryMemberState?.response?.data;

  useEffect(() => {
    if (
      !attendanceSummaryMemberState.loading &&
      !attendanceSummaryMemberData &&
      meetingId !== ""
    ) {
      dispatch(
        getAttendanceSummaryByEventAndMeetingId(collectionId, meetingId)
      );
    }
  }, [meetingId]);

  const columns = [
    {
      field: "serialNumber",
      headerName: "Sl.no",
      width: 50,
      renderCell: (params) =>
        params.api.getSortedRowIds().indexOf(params?.id ?? "") + 1,
    },
    {
      field: "title",
      headerName: "Member Name",
      width: 190,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} spacing={1} sx={{ p: 2 }}>
            <Typography>{params.row.member_name}</Typography>
            {params.row.substitute && (
              <Looks5Icon sx={{ color: "#00A442", mt: 0.8 }} />
            )}
          </Stack>
        );
      },
    },
    {
      field: "attendance_status",
      headerName: "Attendance Status",
      width: 190,
      renderCell: (params) => {
        let status = params.formattedValue;
        return (
          <>
            {status !== "" && (
              <Chip
                label={status}
                style={{ margin: "0.8rem" }}
                sx={{
                  backgroundColor: `${getStatusbgColor(status)}`,
                  color: `${getStatusColor(status)}`,
                  fontWeight: "bold",
                  borderRadius: "16px",
                  "& .MuiChip-label": {
                    padding: "0 12px",
                  },
                }}
              />
            )}
          </>
        );
      },
    },
    {
      field: "attendance_date",
      headerName: "Attendance Time",
      width: 190,
      editable: false,
      renderCell: (params) => {
        return getLocalTimeString(params.row.attendance_time);
      },
    },
    {
      field: "registered_by",
      headerName: "Registered By",
      sortable: false,
      width: 200,
    },
    {
      field: "notes",
      headerName: "Notes",
      sortable: false,
      width: 250,
    },
  ];

  return (
    <DataGrid
      sx={{
        "& ::-webkit-scrollbar": {
          width: "20px",
        },
        "& ::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        "& ::-webkit-scrollbar-thumb": {
          borderRadius: "2px",
          backgroundColor: "#ffffff",
        },
        marginTop: 4,
      }}
      columns={columns}
      rows={attendanceSummaryMemberData}
    />
  );
};

export default AttendanceSummaryForMember;
