import { Field, Form, Formik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import * as Yup from "yup";
import cancelIcon from "../../../assets/icon/cancel.svg";
import {
  createEntityGalleryPromise,
  getAllEntityGallery,
} from "../../../services/gallery.service";
import {
  addOrUpdateGalleryAccess,
  getAllAccessGroups,
} from "../../../services/group-for-auth.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import InputBox from "../../common/input-box/InputBox";
import PopUp from "../../common/pop-up/PopUp";

const AddEntityFolder = (props) => {
  const params = useParams();
  const [pageLoading, setPageLoading] = useState(true);

  const dispatch = useDispatch();

  const [selectedGroups, setSelectedGroups] = useState([]);

  function handleChange(selectedGroups) {
    setSelectedGroups(selectedGroups || []);
  }

  const groupAccessState = useSelector((state) => state.accessGroupAd);
  const groupAccessData = groupAccessState?.response?.data;

  useEffect(() => {
    if (!groupAccessState?.loading && !isEmpty(groupAccessState?.response)) {
      setPageLoading(false);
    }
    if (!groupAccessState?.loading && !isEmpty(groupAccessState?.error)) {
      setPageLoading(false);
      toast.error(STD_ERROR_MESSAGE);
    }
  }, [groupAccessState]);

  useEffect(() => {
    if (isEmpty(groupAccessState?.response)) {
      dispatch(getAllAccessGroups(params.entityId));
    }
  }, []);

  const initialValues = {
    title: "",
    access: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Please enter the name of the folder"),
  });

  const onSubmit = (values) => {
    createEntityGalleryPromise(params.entityId, values)
      .then((res) => {
        const payload = [];
        const galleryId = res?.data?.id;
        for (const value of selectedGroups) {
          payload.push({ gallery_id: galleryId, group_id: value.value });
        }
        addOrUpdateGalleryAccess(params.entityId, galleryId, payload)
          .then((res) => {
            dispatch(getAllEntityGallery(params.entityId));
            props.handleClose();
          })
          .catch((err) => {
            toast.error(err?.error_data || STD_ERROR_MESSAGE);
          });
      })
      .catch((err) => {
        toast.error(err.data?.error_data || STD_ERROR_MESSAGE);
      });
  };
  const options = groupAccessData?.map((group) => {
    return {
      value: group.id,
      label: group.group_name,
    };
  });

  return (
    <>
      <PopUp overlayClassName={props.overlayClassName}>
        <img
          className="close-icon pt-cursor"
          onClick={props.handleClose}
          src={cancelIcon}
          alt=""
        ></img>
        <h1 className="pb18-lh32 text-center mb-3">Add Folder</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <Field name="title">
                {(props) => {
                  const { field, meta } = props;
                  return (
                    <>
                      <InputBox
                        type="text"
                        placeholder="Enter Folder Name"
                        {...field}
                        errorMsg={meta.touched && meta.error ? meta.error : ""}
                      />
                    </>
                  );
                }}
              </Field>
              <Select
                options={options}
                placeholder="Select groups..."
                isMulti
                className="mb-3"
                onChange={handleChange}
                value={selectedGroups}
              />
              <ButtonAccent
                type="submit"
                label="Add Folder"
                className="pb14-lh21 w-100"
              />
            </Form>
          )}
        </Formik>
      </PopUp>
    </>
  );
};

export default AddEntityFolder;
