import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  a11yProps,
  CustomTabPanel,
} from "../../../pages/attendance-record/AttendanceRecord";
import AttendanceRecordForVisitor from "../../../pages/attendance-record/AttendanceRecordForVisitor";
import AttendanceSummaryForMember from "../../../pages/attendance-record/AttendanceSummaryForMember";
import MeetingDetail from "../../../pages/attendance-record/MeetingDetail";
import { getHistoricalMeetingByEvent } from "../../../services/meeting.service";
import {
  BNI_CHAPTER_FOLDERS,
  getLocalDateString,
} from "../../../utils/constant";
import BreadCrumbs from "../../common/box/bread-crumbs/BreadCrumbs";

const AttendanceSummaryForLVHAndLT = (props) => {
  const [attendanceSummary, setAttendanceSummary] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  const [meetingId, setMeetingId] = useState("");
  const [value, setValue] = useState(0);
  const [meetingData, setMeetingData] = useState("");

  const meetingHistoryState = useSelector((state) => state.meetingHistoryEm);
  const meetingHistoryData = meetingHistoryState?.response?.data;

  useEffect(() => {
    if (isEmpty(meetingHistoryState.response)) {
      dispatch(getHistoricalMeetingByEvent(props.collectionId));
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className="page-content">
        {attendanceSummary && (
          <>
            <Box sx={{ mt: 8, mb: 4 }}>
              <BreadCrumbs
                title="Meetings"
                subTitle="Attendance Summary"
                titleLink={`${location.pathname}?selected=settings`}
              />
            </Box>
            <MeetingDetail
              collectionId={props.collectionId}
              value={value}
              meetingData={meetingData}
            />
            <Box sx={{ mt: 4, mb: 4 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textcolor="secondary"
                indicatorcolor="secondary"
              >
                <Tab label="Members" {...a11yProps(0)} />
                <Tab label="Visitors" {...a11yProps(1)} />
              </Tabs>
              <CustomTabPanel value={value} index={0}>
                <AttendanceSummaryForMember
                  collectionId={props.collectionId}
                  meetingId={meetingId}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <AttendanceRecordForVisitor
                  collectionId={props.collectionId}
                  meetingData={meetingData}
                  type={BNI_CHAPTER_FOLDERS.LT}
                />
              </CustomTabPanel>
            </Box>
          </>
        )}
        {!attendanceSummary && (
          <div className="page-content">
            <div className="py-5  ">
              <BreadCrumbs title="Meetings" subTitle="Attendance Summary" />
            </div>
            <Grid container spacing={2}>
              {meetingHistoryData?.map((row) => (
                <Grid item xs={12} sm={6} md={6} key={row}>
                  <Button
                    startIcon={<EventAvailableIcon />}
                    variant="contained"
                    size="large"
                    sx={{
                      backgroundColor: "var(--accent-color)",
                      color: "black",
                      boxShadow: "none",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "var(--accent-color)",
                        boxShadow: "none",
                      },

                      fontFamily: "Poppins",
                    }}
                    onClick={() => {
                      setAttendanceSummary(true);
                      setMeetingId(row.id);
                      setMeetingData(row);
                    }}
                  >
                    Meeting Date-{" "}
                    <Typography>
                      {getLocalDateString(row.start_date)}
                    </Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </Box>
    </>
  );
};

export default AttendanceSummaryForLVHAndLT;
