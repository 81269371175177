import {
  CLEAR_ATTENDANCE_SUMMARY_VISITOR,
  REQUEST_FETCH_ATTENDANCE_SUMMARY_VISITOR,
  REQUEST_FETCH_ATTENDANCE_SUMMARY_VISITOR_FAILED,
  REQUEST_FETCH_ATTENDANCE_SUMMARY_VISITOR_SUCCESS
} from "./attendanceSummaryVisitor.types";

export const requestFetchAttendanceSummaryVisitor = () => {
  return {
    type: REQUEST_FETCH_ATTENDANCE_SUMMARY_VISITOR
  };
};

export const fetchAttendanceSummaryVisitorSuccess = (response) => {
  return {
    type: REQUEST_FETCH_ATTENDANCE_SUMMARY_VISITOR_SUCCESS,
    payload: response
  };
};

export const fetchAttendanceSummaryVisitorFailure = (error) => {
  return {
    type: REQUEST_FETCH_ATTENDANCE_SUMMARY_VISITOR_FAILED,
    payload: error
  };
};

export const clearAttendanceSummaryVisitor = () => {
  return {
    type: CLEAR_ATTENDANCE_SUMMARY_VISITOR
  };
};
