import {
  CLEAR_GROUP_ACCESS_MAPPING_EM_STATES,
  FETCH_GROUP_ACCESS_MAPPING_EM_FAILURE,
  FETCH_GROUP_ACCESS_MAPPING_EM_SUCCESS,
  REQUEST_GROUP_ACCESS_MAPPING_FETCH_EM,
} from "./groupAccessMapping.types";

export const requestFetchGroupAccessMappingEm = () => {
  return {
    type: REQUEST_GROUP_ACCESS_MAPPING_FETCH_EM,
  };
};

export const fetchGroupAccessMappingEmSuccess = (response) => {
  return {
    type: FETCH_GROUP_ACCESS_MAPPING_EM_SUCCESS,
    payload: response,
  };
};

export const fetchGroupAccessMappingEmFailure = (error) => {
  return {
    type: FETCH_GROUP_ACCESS_MAPPING_EM_FAILURE,
    payload: error,
  };
};

export const clearGroupAccessMappingEmStates = () => {
  return {
    type: CLEAR_GROUP_ACCESS_MAPPING_EM_STATES,
  };
};
