import {
  fetchAccessLvhGroupEmFailure,
  fetchAccessLvhGroupEmSuccess,
  requestFetchAccessLvhGroupEm
} from "../redux/group-for-lvh/lvhGroup.action";
import {
  fetchAccessLvhGroupUserEmFailure,
  fetchAccessLvhGroupUserEmSuccess,
  requestFetchAccessLvhGroupUserEm
} from "../redux/lvh-group-users/lvhGroupUsers.action";
import {
  fetchTeamEmFailure,
  fetchTeamEmSuccess,
  requestTeamReducer
} from "../redux/team/team.action";
import ProtectedWebClient from "../utils/protected-web-client";

//For Teams

export const getAllTeams = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestTeamReducer());
      const fetchedAccessLvhGroupResponse = await ProtectedWebClient.get(
        `/event/${collectionId}/team`
      );
      dispatch(fetchTeamEmSuccess(fetchedAccessLvhGroupResponse?.data));
    } catch (error) {
      dispatch(fetchTeamEmFailure(error?.response));
    }
  };
};

//For Groups
export const getAllAccessLvhGroups = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAccessLvhGroupEm());
      const fetchedAccessLvhGroupResponse = await ProtectedWebClient.get(
        `/event/${collectionId}/lvhGroup`
      );
      dispatch(
        fetchAccessLvhGroupEmSuccess(fetchedAccessLvhGroupResponse?.data)
      );
    } catch (error) {
      dispatch(fetchAccessLvhGroupEmFailure(error?.response));
    }
  };
};

export const createAccessLvhGroupPromise = (collectionId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/event/${collectionId}/lvhGroup`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const getAccessGroupId = (collectionId, groupId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAccessLvhGroupEm());
      const fetchedAccessLvhGroupResponse = await ProtectedWebClient.get(
        `/event/${collectionId}/lvhGroup/${groupId}`
      );
      dispatch(
        fetchAccessLvhGroupEmSuccess(fetchedAccessLvhGroupResponse?.data)
      );
    } catch (error) {
      dispatch(fetchAccessLvhGroupEmFailure(error?.response));
    }
  };
};

export const deleteLvhGroupById = (collectionId, groupId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(`/event/${collectionId}/lvhGroup/${groupId}`)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

//For GroupMembers

export const getAccessLvhGroupUsers = (collectionId, groupId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAccessLvhGroupUserEm());
      const fetchedAccessLvhGroupResponse = await ProtectedWebClient.get(
        `/event/${collectionId}/lvhGroup/${groupId}/user`
      );
      dispatch(
        fetchAccessLvhGroupUserEmSuccess(fetchedAccessLvhGroupResponse?.data)
      );
    } catch (error) {
      dispatch(fetchAccessLvhGroupUserEmFailure(error?.response));
    }
  };
};

export const addUsersToAccessLvhGroup = (collectionId, groupId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(
      `/event/${collectionId}/lvhGroup/${groupId}/user`,
      payload
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const removeUserFromAccessLvhGroup = (collectionId, groupId, userId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.delete(
      `/event/${collectionId}/lvhGroup/${groupId}/user/${userId}`
    )
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const getGroupByUserId = (collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAccessLvhGroupUserEm());
      const fetchedAccessLvhGroupResponse = await ProtectedWebClient.get(
        `/event/${collectionId}/groupUser`
      );
      dispatch(
        fetchAccessLvhGroupUserEmSuccess(fetchedAccessLvhGroupResponse?.data)
      );
    } catch (error) {
      dispatch(fetchAccessLvhGroupUserEmSuccess(error?.response));
    }
  };
};
