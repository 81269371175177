import React, { useState } from "react";
import { isEmpty, size } from "lodash";
import { useParams } from "react-router-dom";
import FileUploading from "../files-uploading/FilesUploading";
import { useSelector, useDispatch } from "react-redux";

import { clearUploadFilesState } from "../../../redux/upload-files/uploadFiles.action.js";
import {
  cancelUploadingFiles,
  retryUploadErrors,
} from "../../../services/uploadImages.service.js";
import {
  createInvitesPromise,
  getAllInvitesInCollection,
} from "../../../services/invites.service.js";

import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import {
  SECTION_ENTITY_PROFILE_COMPONENTS,
  SECTION_INVITES_COMPONENTS,
} from "../../../utils/constant";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import {
  createEntityLocationPromise,
  getAllLocationInEntity,
} from "../../../services/entity.location.service";

const LocationFilesUploading = (props) => {
  const uploadFilesState = useSelector((state) => state.uploadFiles);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const params = useParams();

  function handleUploadImageStatusCount(statusRole) {
    if (
      size(uploadFilesState.fileProgress) ===
      size(uploadFilesState.uploadSuccess.ids) +
        size(uploadFilesState.uploadError.ids)
    ) {
      if (statusRole === "success") {
        return `Successful Uploads: ${size(
          uploadFilesState.uploadSuccess.ids
        )}`;
      } else {
        if (size(uploadFilesState.uploadError.ids) > 0) {
          return `Errors: ${size(uploadFilesState.uploadError.ids)}`;
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  }

  function isUploadProgressing() {
    return (
      size(uploadFilesState.fileProgress) >
      size(uploadFilesState.uploadSuccess.ids) +
        size(uploadFilesState.uploadError.ids)
    );
  }

  function handleRetryBtnEvent() {
    dispatch(retryUploadErrors());
  }

  function uploadHasErrors() {
    return size(uploadFilesState.uploadError.ids) > 0;
  }

  function handleProceedBtnEvent() {
    if (!submitting) {
      setSubmitting(true);
      if (size(uploadFilesState.uploadSuccess.ids)) {
        const entityLocationRequest = [];
        for (const id of uploadFilesState.uploadSuccess.ids) {
          const fileData = uploadFilesState.fileProgress[id];
          entityLocationRequest.push({
            image_id: fileData.imageId,
            sequence: fileData.id,
          });
        }
        createEntityLocationPromise(params.entityId, {
          entity_location: entityLocationRequest,
        })
          .then((res) => {
            setSubmitting(false);
            dispatch(getAllLocationInEntity(params.entityId));
            props.setCurrentMainComponent((prevState) => {
              return {
                currComponent: SECTION_ENTITY_PROFILE_COMPONENTS.location_list,
                prevComponent: prevState.currComponent,
              };
            });
            dispatch(clearUploadFilesState());
          })
          .catch((err) => {
            setSubmitting(false);
            toast.error(
              !isEmpty(err?.error_data) ? err?.error_data : STD_ERROR_MESSAGE
            );
          });
      } else {
        setSubmitting(false);
        props.setCurrentMainComponent((prevState) => {
          return {
            currComponent: SECTION_ENTITY_PROFILE_COMPONENTS.location_list,
            prevComponent: prevState.currComponent,
          };
        });
        dispatch(clearUploadFilesState());
      }
    }
  }

  function handleAddPhotosBtnEvent() {
    props.setCurrentMainComponent((prevState) => {
      return {
        currComponent: SECTION_ENTITY_PROFILE_COMPONENTS.upload_location,
        prevComponent: prevState.currComponent,
      };
    });
  }

  function handleCancelBtnEvent() {
    dispatch(cancelUploadingFiles());
    props.setCurrentMainComponent((prevState) => {
      return {
        currComponent: SECTION_ENTITY_PROFILE_COMPONENTS.upload_location,
        prevComponent: prevState.currComponent,
      };
    });
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title={`Adding Photos to Carousel`}
        uploadSuccessContent={handleUploadImageStatusCount("success")}
        uploadErrorContent={handleUploadImageStatusCount("failure")}
        cancelBtn={isUploadProgressing()}
        handleCancelBtnClick={handleCancelBtnEvent}
        retryBtn={!isUploadProgressing() && uploadHasErrors()}
        handleRetryBtnClick={handleRetryBtnEvent}
        addPhotosBtn={!isUploadProgressing()}
        addPhotosLabel="Add More"
        handleAddPhotosBtn={handleAddPhotosBtnEvent}
        loadingBtn={!isUploadProgressing()}
        loadingBtnProps={{
          loading: submitting,
          onClick: handleProceedBtnEvent,
        }}
        loadingBtnLabel="Proceed"
      />
      <FileUploading />
    </main>
  );
};

export default LocationFilesUploading;
