import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  const path = useLocation().pathname;
  const location = path.split("/")[1];

  return (
    <footer>
      <p className="pr12-lh24 primary-color">
        Copyright &copy; {new Date().getFullYear()} Picsea Digital. All rights
        reserved.
      </p>
      <div className="footer-link pb12-lh18">
        {/* {!location && <Link to="/partner/sign-up">Partner Signup</Link>} */}
        <Link to="/partner-signin">Partner Sign In</Link>
        <Link to="/about-us">About us</Link>
        <Link to="/help-and-support">Help & Support</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-of-use">Terms of Use</Link>
        <Link to="/contact-us">Contact us</Link>
      </div>
    </footer>
  );
};

export default Footer;
