import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import {
  getAllFeeds,
  liveFeedLikeOrUnlikePromise,
  deleteLiveFeedByIdPromise,
} from "../../../services/liveFeed.service";
import { getImageUrl } from "../../../utils/utils";
import LiveFeedCreateOrEdit from "../live-feed-create-or-edit/LiveFeedCreateOrEdit";
import LiveFeedItem from "../live-feed-item/LiveFeedItem";
import { IOSSwitch } from "../../common/ios-switch/IOSSwitch";
import {
  getACollection,
  updateCollection,
} from "../../../services/collections.service";
import { fetchCollectionEmSuccess } from "../../../redux/collection/collection.action";

const LiveUpdates = (props) => {
  const [pageLoading, setPageLoading] = useState(true);

  const params = useParams();
  const history = useHistory();

  const liveFeedsState = useSelector((state) => state.liveFeedEm);
  const collectionState = useSelector((state) => state.collectionEm);
  const dispatch = useDispatch();

  const settings = collectionState.response?.data?.settings
    ? JSON.parse(collectionState.response?.data?.settings)
    : {};

  const liveFeedsData = liveFeedsState?.response?.data;
  const collectionData = collectionState.response?.data;

  function onToggleChange() {
    settings.user_post = !settings.user_post;
    updateCollection({ settings: JSON.stringify(settings) }, collectionData.id)
      .then((res) => {
        dispatch(fetchCollectionEmSuccess(res.data));
      })
      .catch((err) =>
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        )
      );
  }
  useEffect(() => {
    if (!liveFeedsState?.loading && !isEmpty(liveFeedsState?.response)) {
      setPageLoading(false);
    }

    if (!liveFeedsState?.loading && !isEmpty(liveFeedsState?.error)) {
      setPageLoading(false);
      toast.error(liveFeedsState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [liveFeedsState]);

  useEffect(() => {
    if (isEmpty(liveFeedsState?.response)) {
      dispatch(getAllFeeds(params.collectionId));
    }
  }, []);

  function handleLiveFeedLike(id, currLikeState) {
    liveFeedLikeOrUnlikePromise(params.collectionId, id, {
      action: currLikeState ? "dislike" : "like",
    })
      .then((res) => {
        dispatch(getAllFeeds(params.collectionId));
      })
      .catch((err) => {
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  function handleDeleteFeed(id) {
    deleteLiveFeedByIdPromise(params.collectionId, id)
      .then((res) => {
        dispatch(getAllFeeds(params.collectionId));
      })
      .catch((err) => {
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title="Add Live updates"
        backToCollectionsBtn={true}
        handleBackToCollectionsBtn={() => history.goBack()}
        generalBtn={true}
        generalBtnLabel={"Master Invite List"}
        onGeneralBtnClick={props.onMasterInviteBtnClick}
      />
      <div className="mt-3">
        <button
          style={{
            display: "flex",
            height: "40px",
            border: "2px solid grey",
            borderRadius: "20px",
            padding: "06px 12px",
            backgroundColor: "transparent",
            gap: "1rem",
          }}
        >
          <label
            style={{
              lineHeight: "24px",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              alignItems: "flex-end",
            }}
          >
            Enable Guest Posting
          </label>
          <IOSSwitch
            onChange={onToggleChange}
            checked={!!settings?.user_post}
          />
        </button>
      </div>

      <LiveFeedCreateOrEdit action="create" />
      {!pageLoading
        ? !isEmpty(liveFeedsData)
          ? liveFeedsData.map((obj) => (
              <LiveFeedItem
                key={obj?.live_feed?.id}
                thumbnail={getImageUrl(collectionData?.image?.bucket_path)}
                enableMoreOption={true}
                title={collectionData?.name}
                // onEditPostClick={
                //     () => setEditItem(ps => {
                //         return [...ps, obj?.liveFeed?.id]
                //     })
                // }
                onDeletePostClick={() => handleDeleteFeed(obj?.live_feed?.id)}
                contentText={obj?.live_feed?.text}
                contentImageUrl={getImageUrl(
                  obj?.live_feed?.image?.bucket_path
                )}
                onLikeClick={() =>
                  handleLiveFeedLike(obj?.live_feed?.id, obj?.liked_by_user)
                }
                likesCount={obj?.live_feed?.likes_count}
                likeState={obj?.liked_by_user}
              />
            ))
          : null
        : null}
    </main>
  );
};

export default LiveUpdates;
