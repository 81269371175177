import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Select,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  DataGrid,
  GridEditInputCell,
  GridRowEditStopReasons,
  GridRowModes,
} from "@mui/x-data-grid";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonAccent from "../../components/common/button-accent/ButtonAccent";
import {
  createAttendanceSummaryVisitor,
  deleteAttendanceSummaryVisitor,
  getAttendanceSummaryVisitorByEvent,
  updateAttendanceSummaryVisitor,
} from "../../services/attendanceVisitor.service";
import { BNI_CHAPTER_FOLDERS } from "../../utils/constant";

const StyledBox = styled("div")(({ theme }) => ({
  width: "100%",
  "& .Mui-error": {
    backgroundColor: "rgb(126,10,15, 0.1)",
    color: "#750f0f",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgb(126,10,15, 0)",
      color: "#ff4343",
    }),
  },
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}

const AttendanceRecordForVisitor = (props, item) => {
  const [rowModesModel, setRowModesModel] = useState({});
  const [rows, setRows] = useState([]);
  const [editRow, setEditRow] = useState({});
  const dispatch = useDispatch();

  const type = props.type;

  const { collectionId, meetingData } = props;
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      visitorData: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "visitorData",
  });

  const attendanceSummaryVisitorState = useSelector(
    (state) => state.attendanceSummaryVisitorEm
  );

  const attendanceSummaryVisitorData =
    attendanceSummaryVisitorState?.response?.data;

  useEffect(() => {
    if (!attendanceSummaryVisitorState?.loading) {
      dispatch(
        getAttendanceSummaryVisitorByEvent(collectionId, meetingData.id)
      );
    }
  }, []);

  useEffect(() => {
    if (attendanceSummaryVisitorData) {
      reset({
        visitorData: attendanceSummaryVisitorData,
      });
      setRows(attendanceSummaryVisitorData);
    }
  }, [attendanceSummaryVisitorData, reset]);

  const [validatePopup, setValidatePopUp] = useState(false);
  const [editPopup, setEditPopUp] = useState(false);
  const [gridValidatePopup, setGridValidatePopUp] = useState(false);
  const [disableAddButton, setDisableAddButton] = useState(false);

  const id = validatePopup ? "simple-popover" : undefined;

  let columns = [
    {
      field: "serialNumber",
      headerName: "Sl.no",
      width: 50,
      renderCell: (params) =>
        params.api.getSortedRowIds().indexOf(params?.id ?? "") + 1,
    },
    {
      field: "visitor_name",
      headerName: "Name",
      editable: true,
      width: 150,
      preProcessEditCellProps: (params) => {
        const errorMessage = !params.props.value && "Name is required";
        return { ...params.props, error: errorMessage };
      },
      renderEditCell: renderEditName,
    },
    {
      field: "company_name",
      headerName: "Company",
      editable: true,
      width: 150,
      preProcessEditCellProps: (params) => {
        const errorMessage = !params.props.value && "Company is required";
        return { ...params.props, error: errorMessage };
      },
      renderEditCell: renderEditName,
    },
    {
      field: "invited_by",
      headerName: "Invited By",
      editable: true,
      width: 120,
      preProcessEditCellProps: (params) => {
        const errorMessage = !params.props.value && "Invited by is required";
        return { ...params.props, error: errorMessage };
      },
      renderEditCell: renderEditName,
    },
    {
      field: "amount_collected",
      headerName: "Amount Collected",
      type: "number",
      editable: true,
      width: 140,
      preProcessEditCellProps: (params) => {
        const errorMessage = !params.props.value && "Amount is required";
        return { ...params.props, error: errorMessage };
      },
      renderEditCell: renderEditName,
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Cash", "Card", "UPI", "Others"],
      width: 170,
    },
    {
      field: "notes",
      headerName: "Notes",
      editable: true,
      width: 180,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        const isInEditMode =
          rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
        return (
          <>
            <Button
              variant="contained"
              disableElevation={true}
              size="small"
              style={{
                color: isInEditMode ? "black" : "white",
                backgroundColor: isInEditMode ? "var(--accent-color)" : "Black",
              }}
              onClick={() => {
                if (isInEditMode) {
                  setRowModesModel({
                    ...rowModesModel,
                    [params.row.id]: {
                      mode: GridRowModes.View,
                    },
                  });
                } else {
                  setRowModesModel({
                    ...rowModesModel,
                    [params.row.id]: {
                      mode: GridRowModes.Edit,
                    },
                  });
                }
              }}
            >
              {isInEditMode ? "Save" : "Edit"}
            </Button>
            {gridValidatePopup && (
              <Popover
                id={id}
                open={gridValidatePopup}
                onClose={() => setGridValidatePopUp(false)}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
              >
                <Stack spacing={2} sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Do you want to validate?
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Button
                      onClick={() => {
                        setRowModesModel({
                          ...rowModesModel,
                          [params.row.id]: {
                            mode: GridRowModes.Edit,
                          },
                        });
                        setGridValidatePopUp(false);
                      }}
                      variant="text"
                      sx={{ color: "black" }}
                    >
                      Cancel
                    </Button>
                    <ButtonAccent
                      label="Save"
                      onClick={() => {
                        onSubmitGridRow(editRow);
                        setGridValidatePopUp(false);
                      }}
                    />
                  </Stack>
                </Stack>
              </Popover>
            )}
          </>
        );
      },
    },
    {
      width: 100,
      renderCell: (params) => {
        let index = params.api.getSortedRowIds().indexOf(params.id);
        return (
          <IconButton onClick={() => handleDelete(params.row.id)} color="red">
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        );
      },
    },
  ];

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    if (isEmpty(newRow.visitor_name)) {
      toast.error("Cannot save empty row");
      throw new Error("Cannot save empty row");
    }
    setEditRow(newRow);
    setGridValidatePopUp(true);
    // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return newRow;
  };

  const handleClick = () => {
    const id = Math.random();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        visitor_name: "",
        company_name: "",
        invited_by: "",
        amount_collected: "",
        payment_type: "",
        notes: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "visitor_name" },
    }));
    setDisableAddButton(true);
  };

  const [editId, setEditId] = useState("");

  const onEdit = async (data) => {
    try {
      const targetVisitor = data.visitorData.find((i) => i.id === editId);
      if (targetVisitor) {
        const res = await updateAttendanceSummaryVisitor(
          targetVisitor,
          collectionId,
          targetVisitor.id
        );
        if (res.error_data) {
          toast.error(res.error_data.message);
        } else {
          toast.success("Updated successfully");
        }
      } else {
        toast.error("Visitor data not found for the given ID.");
      }
    } catch (error) {
      toast.error("Something went wrong", error);
    } finally {
      dispatch(
        getAttendanceSummaryVisitorByEvent(collectionId, meetingData.id)
      );
      setDisableAddButton(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      const visitor = data.visitorData.find((i) => isEmpty(i.id));
      if (visitor) {
        const res = await createAttendanceSummaryVisitor(
          visitor,
          collectionId,
          meetingData.id
        );
        if (res.error_data) {
          toast.error(res.error_data?.message);
        } else {
          toast.success("Attendance summary created successfully");
        }
        dispatch(
          getAttendanceSummaryVisitorByEvent(collectionId, meetingData.id)
        );
      } else {
        toast.error("All visitors are already saved.");
      }
    } catch (error) {
      toast.error("Something went wrong", error);
    } finally {
      dispatch(
        getAttendanceSummaryVisitorByEvent(collectionId, meetingData.id)
      );
      setDisableAddButton(false);
    }
  };

  const onSubmitGridRow = async (data) => {
    const payload = data;
    payload.amount_collected = "" + data.amount_collected;
    try {
      let res;
      if (data.isNew) {
        data.id = "";
        res = await createAttendanceSummaryVisitor(
          data,
          collectionId,
          meetingData.id
        );
      } else {
        res = await updateAttendanceSummaryVisitor(data, collectionId, data.id);
        if (res.error_data) {
          toast.error(res.error_data.message);
        }
      }
      toast.success("Visitor added successfully");
    } catch (error) {
      toast.error("Something went wrong" + error);
    } finally {
      dispatch(
        getAttendanceSummaryVisitorByEvent(collectionId, meetingData.id)
      );
      setDisableAddButton(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteAttendanceSummaryVisitor(collectionId, id);
      if (res.error_data) {
        toast.error(res.error_data.message);
      }
      toast.success("Visitor deleted successfully");
    } catch (error) {
      toast.error("Something went wrong" + error);
    } finally {
      dispatch(
        getAttendanceSummaryVisitorByEvent(collectionId, meetingData.id)
      );
    }
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (rows) {
      setRows(rows);
    }
  }, [rows]);

  return (
    <Box sx={{ mt: 2 }}>
      <Paper sx={{ width: "100%", overflowX: "auto" }}>
        {isMobile ? (
          <form>
            {rows.map((item, index) => (
              <Paper
                key={item.id}
                sx={{ maxHeight: "30rem", overflow: "auto", p: 2, mt: 2 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      name={`visitorData.${index}.visitor_name`}
                      control={control}
                      defaultValue={item.visitor_name}
                      rules={{ required: "Name is required" }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          label="Name"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`visitorData.${index}.company_name`}
                      control={control}
                      defaultValue={item.company_name}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          label="Company"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`visitorData.${index}.invited_by`}
                      control={control}
                      defaultValue={item.invited_by}
                      rules={{ required: "This field is required" }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          label="Invited By"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`visitorData.${index}.amount_collected`}
                      control={control}
                      defaultValue={item.amount_collected}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          label="Amount"
                          type="number"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`visitorData.${index}.payment_type`}
                      control={control}
                      defaultValue={item.payment_type}
                      render={({ field, fieldState: { error } }) => (
                        <Select {...field} fullWidth>
                          <MenuItem value="Cash">Cash</MenuItem>
                          <MenuItem value="Card">Card</MenuItem>
                          <MenuItem value="UPI">UPI</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`visitorData.${index}.notes`}
                      control={control}
                      defaultValue={item.notes}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          label="Notes"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {attendanceSummaryVisitorData.some(
                      (i) => i.id === item.id
                    ) ? (
                      <Button
                        variant="contained"
                        disableElevation={true}
                        size="small"
                        style={{
                          padding: "2",
                          color: "white",
                          backgroundColor: "Black",
                        }}
                        onClick={() => {
                          setEditPopUp(true);
                          setEditId(item.id);
                        }}
                      >
                        Edit
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        disableElevation={true}
                        size="small"
                        style={{
                          padding: "2",
                          color: "black",
                          backgroundColor: "var(--accent-color)",
                        }}
                        onClick={() => {
                          setValidatePopUp(true);
                        }}
                      >
                        Validate
                      </Button>
                    )}
                    {validatePopup && (
                      <Popover
                        open={validatePopup}
                        onClose={() => setValidatePopUp(false)}
                        sx={{
                          marginTop: { xs: "20rem" },
                          marginLeft: { xs: "4rem" },
                        }}
                      >
                        <Stack spacing={2} sx={{ p: 2 }}>
                          <Typography variant="h6" gutterBottom>
                            Do you want to validate?
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            <Button
                              onClick={() => setValidatePopUp(false)}
                              variant="text"
                              sx={{ color: "black" }}
                            >
                              Cancel
                            </Button>
                            <ButtonAccent
                              label="Validate"
                              onClick={() => {
                                setValidatePopUp(false);
                                handleSubmit(onSubmit)();
                              }}
                            />
                          </Stack>
                        </Stack>
                      </Popover>
                    )}

                    {editPopup && (
                      <Popover
                        open={editPopup}
                        onClose={() => setEditPopUp(false)}
                        sx={{
                          marginTop: { xs: "20rem" },
                          marginLeft: { xs: "4rem" },
                        }}
                      >
                        <Stack spacing={2} sx={{ p: 2, marginLeft: { xs: 2 } }}>
                          <Typography variant="h6" gutterBottom>
                            Do you want to update?
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            <Button
                              onClick={() => setEditPopUp(false)}
                              variant="text"
                              sx={{ color: "black" }}
                            >
                              Cancel
                            </Button>
                            <ButtonAccent
                              label="Edit"
                              onClick={() => {
                                setEditPopUp(false);
                                handleSubmit(onEdit)();
                              }}
                            />
                          </Stack>
                        </Stack>
                      </Popover>
                    )}
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      style={{ marginLeft: "0.5rem" }}
                      onClick={() => handleDelete(item.id)}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ))}
            {type === BNI_CHAPTER_FOLDERS.LVH && (
              <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                <ButtonAccent
                  disabled={disableAddButton}
                  label="Add Visitor"
                  onClick={handleClick}
                />
              </Box>
            )}
          </form>
        ) : (
          <>
            <StyledBox>
              <DataGrid
                sx={{
                  "& ::-webkit-scrollbar": {
                    width: "20px",
                  },
                  "& ::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    borderRadius: "2px",
                    backgroundColor: "#ffffff",
                  },
                }}
                columns={columns}
                rows={rows}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                pageSize={5}
                rowsPerPageOptions={[5]}
              />
            </StyledBox>
            {type === BNI_CHAPTER_FOLDERS.LVH && (
              <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                <ButtonAccent
                  disabled={disableAddButton}
                  label="Add Visitor"
                  onClick={handleClick}
                />
              </Box>
            )}
          </>
        )}
      </Paper>
    </Box>
  );
};

export default AttendanceRecordForVisitor;
