import useMediaQuery from "@mui/material/useMediaQuery";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import arrowRight from "../../../assets/icon/arrow_right.svg";
import { PreviewLogo } from "../add-or-edit-venue/AddOrEditVenue";

import "./user-invite.css";

// import Image from '../../common/image/Image'
import CustomSpinner from "../../common/spinner/CustomSpinner";

import { ENTITY_TYPES, STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";

import { getAllInvitesInCollection } from "../../../services/invites.service";

import { getPermaLinkByEventId } from "../../../services/permalink";

const UserInvite = (props) => {
  const { collectionInfoLoading } = props;

  const isMobile = useMediaQuery("(max-width: 480px)");

  const [loading, setLoading] = useState(true);
  const [currInviteIndex, setCurrInviteIndex] = useState(0);

  const invitesState = useSelector((state) => state.invitesEm);
  const collectionState = useSelector((state) => state.collectionEm);
  const permalinkState = useSelector((state) => state.permalinkAd);

  const dispatch = useDispatch();

  const params = useParams();
  const history = useHistory();

  const invitesData = invitesState.response?.data;
  const collectionData = collectionState.response?.data;
  const permalinkData = permalinkState.response?.data;
  const settings = collectionData?.settings
    ? JSON.parse(collectionData.settings)
    : {};

  const handleSelect = (selectedIndex, e) => {
    setCurrInviteIndex(selectedIndex);
  };

  useEffect(() => {
    if (isEmpty(invitesState.response)) {
      dispatch(getAllInvitesInCollection(props.collectionId));
    }
    if (isEmpty(permalinkState.response)) {
      dispatch(getPermaLinkByEventId(props.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!invitesState.loading && !isEmpty(invitesState.response)) {
      setLoading(false);
    }
    if (!invitesState.loading && !isEmpty(invitesState.error)) {
      setLoading(false);
      toast.error(invitesState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [invitesState]);

  useEffect(() => {
    if (!permalinkState.loading && !isEmpty(permalinkState.response)) {
      setLoading(false);
    }
    if (!permalinkState.loading && !isEmpty(permalinkState.error)) {
      setLoading(false);
      toast.error(permalinkState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [permalinkState]);

  function handleLogoClickEvent() {
    window.open(`/${permalinkData.permalink.permalink}`, "_blank");
  }

  const getEntityType = (id) => {
    switch (id) {
      case ENTITY_TYPES.venue:
        return "Venue";
      case ENTITY_TYPES.planner:
        return "Planner";
      default:
        return "Host";
    }
  };

  return (
    <div style={{ minHeight: "calc(100vh - 64px - 56px - 40px)" }}>
      {!loading && !collectionInfoLoading ? (
        <>
          {!isEmpty(invitesData) && !isEmpty(collectionData) ? (
            <div
              style={{
                paddingBottom: "2rem",
                backgroundRepeat: "repeat",
                backgroundColor: "var(--secondary-color)",
              }}
            >
              <div
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url(${getImageUrl(
                    collectionData.image?.bucket_path
                  )})`, // header image.
                  height: isMobile ? "50vh" : "70vh",
                  backgroundPosition: "center",
                  backgroundRepeat: isMobile ? "100vw auto" : "auto 100vh",
                  position: "relative",
                }}
              >
                <h1
                  className={`${
                    isMobile ? "pb32-lh40" : "psb54-lh54"
                  } secondary-color`}
                  style={{
                    position: "absolute",
                    bottom: "1.5rem",
                    left: 0,
                    right: 0,
                    textAlign: "center",
                  }}
                >
                  {collectionData.id == "479b47db-a61f-4958-a456-be9732ce3948"
                    ? ""
                    : collectionData.name}
                  {/* {collectionData.name} */}
                </h1>
              </div>
              <div>
                <div
                  style={{
                    padding: "52px 16px",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "480px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <h1
                      className={`${
                        isMobile
                          ? "parisienne-normal-fs80-lh96"
                          : "parisienne-normal-fs96-lh96"
                      } grey-2-color text-center`}
                    >
                      Welcome
                    </h1>
                    {/* <h3 className="psb24-lh40 text-center grey-3-color">
                      TO OUR WEDDING
                    </h3> */}
                    {collectionData.invitation_title && (
                      <p className="pr18-lh32 text-center grey-2-color invitationTitle">
                        {collectionData.invitation_title}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    position: "static",
                  }}
                  className="invite-carousel-wrapper"
                >
                  <Carousel
                    indicators={true}
                    interval={null}
                    wrap={false}
                    activeIndex={currInviteIndex}
                    onSelect={handleSelect}
                  >
                    {invitesData.map((invite) => (
                      <Carousel.Item key={invite.id}>
                        <img
                          src={getImageUrl(invite.image?.bucket_path)}
                          alt=""
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>

                {permalinkData?.permalink?.publish ? (
                  <>
                    {permalinkData.entity?.logo ? (
                      <div className="mt-4 d-flex justify-content-center">
                        <div
                          onClick={handleLogoClickEvent}
                          className="entityAnchor"
                        >
                          <PreviewLogo
                            src={getImageUrl(
                              permalinkData?.entity?.logo?.bucket_path
                            )}
                          />

                          <div
                            style={{
                              display: "flex",
                              gap: "0.5rem",
                            }}
                          >
                            {`${getEntityType(
                              permalinkData.entity.entity_type
                            )}` + " details"}
                            <img
                              src={arrowRight}
                              style={{
                                width: "24px",
                                height: "24px",
                                top: "24px",
                                left: "24px",
                                padding: "6.5px, 3px, 6.5px, 3px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mt-4 d-flex justify-content-center">
                        <div
                          onClick={handleLogoClickEvent}
                          className="entityAnchor"
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5rem",
                              border: "1px solid black",
                              borderRadius: "52px",
                              padding: "10px 25px",
                            }}
                          >
                            {`${getEntityType(
                              permalinkData.entity.entity_type
                            )}` + " details"}
                            <img
                              src={arrowRight}
                              style={{
                                width: "24px",
                                height: "24px",
                                top: "24px",
                                left: "24px",
                                padding: "6.5px, 3px, 6.5px, 3px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          ) : (
            <h4 className="text-center pr20-lh32 high-emphasis-color pt-5">
              You have no invites
            </h4>
          )}
        </>
      ) : (
        <CustomSpinner />
      )}
    </div>
  );
};

export default UserInvite;
