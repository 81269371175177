import { Box, Grid, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonAccent from "../../components/common/button-accent/ButtonAccent";
import CustomSpinner from "../../components/common/spinner/CustomSpinner.js";
import UserMeetings from "../../components/layout/attendance-summary-for-lvh-and-lt/AttendanceSummaryForLVHAndLT.js";
import { getGroupByUserId } from "../../services/group-for-lvh.service";
import { getMeetingByEvent } from "../../services/meeting.service.js";
import { BNI_CHAPTER_FOLDERS, STD_ERROR_MESSAGE } from "../../utils/constant";
import AttendanceRecord from "../attendance-record/AttendanceRecord";
import UserMeetingHistory from "../../components/layout/user-meeting-history/UserMeetingHistory.js";
import AttendanceSummaryForLVHAndLT from "../../components/layout/attendance-summary-for-lvh-and-lt/AttendanceSummaryForLVHAndLT.js";

const Meetings = (props) => {
  const collectionId = props.collectionId;

  const [accessCheckLoading, setAccessCheckLoading] = useState(true);
  const [isMeetingLoading, setIsMeetingLoading] = useState(true);

  const [recordAttendance, setRecordAttendance] = React.useState(false);
  const [attendanceSummary, setAttendanceSummary] = React.useState(false);

  const dispatch = useDispatch();
  const lvhGroupAccessUserState = useSelector(
    (state) => state.accessLvhGroupUserEm
  );
  const lvhGroupAccessUserData = lvhGroupAccessUserState?.response?.data;

  const meetingState = useSelector((state) => state.meetingEm);
  const meetingData = meetingState.response?.data;

  useEffect(() => {
    if (
      !lvhGroupAccessUserState?.loading &&
      !isEmpty(lvhGroupAccessUserState?.response)
    ) {
      setAccessCheckLoading(false);
    }
    if (
      !lvhGroupAccessUserState?.loading &&
      !isEmpty(lvhGroupAccessUserState?.error)
    ) {
      setAccessCheckLoading(false);
      toast.error(STD_ERROR_MESSAGE);
    }
  }, [lvhGroupAccessUserState]);

  useEffect(() => {
    if (!meetingState.loading && !isEmpty(meetingState.response)) {
      setIsMeetingLoading(false);
    }
    if (!meetingState.loading && !isEmpty(meetingState.error)) {
      setIsMeetingLoading(false);
      toast.error(STD_ERROR_MESSAGE);
    }
  }, [meetingState]);

  useEffect(() => {
    if (isEmpty(lvhGroupAccessUserState?.response)) {
      dispatch(getGroupByUserId(collectionId));
    }
    if (!meetingState.loading && isEmpty(meetingState.response)) {
      dispatch(getMeetingByEvent(collectionId));
    }
  }, []);

  return (
    <div style={{ minHeight: "calc(100vh - 64px - 56px - 40px)" }}>
      {accessCheckLoading || isMeetingLoading ? (
        <CustomSpinner />
      ) : meetingData != null ? (
        <Box>
          {isEmpty(lvhGroupAccessUserData) && (
            <UserMeetingHistory collectionId={collectionId} />
          )}
          {!isEmpty(lvhGroupAccessUserData) && (
            <>
              {recordAttendance && (
                <AttendanceRecord
                  subtitle="Record Attendance"
                  type={BNI_CHAPTER_FOLDERS.LVH}
                  collectionId={collectionId}
                  meetingData={meetingData}
                  lvhGroupAccessUserData={lvhGroupAccessUserData}
                />
              )}
              {attendanceSummary && (
                <AttendanceSummaryForLVHAndLT
                  meetingData={meetingData}
                  collectionId={collectionId}
                />
              )}
              {!recordAttendance && !attendanceSummary && (
                <Box className="page-content" sx={{ mt: 8, mb: 4 }}>
                  <Typography variant="h5" component="div" gutterBottom>
                    Meetings
                  </Typography>
                  <Box
                    sx={{ mb: 4, p: 4, marginLeft: { xs: "0", md: "6rem" } }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Attendance
                    </Typography>
                    <hr />
                    <Grid container spacing={2}>
                      <Grid item>
                        {lvhGroupAccessUserData?.some(
                          (group) => group.team_id == 2 || group.team_id == 3
                        ) && (
                          <ButtonAccent
                            className="button-w100-mt21 pb14-lh25"
                            label="Record Attendance"
                            onClick={() => {
                              setRecordAttendance(true);
                            }}
                          />
                        )}
                      </Grid>
                      {lvhGroupAccessUserData?.some(
                        (group) =>
                          group.team_id == 2 ||
                          group.team_id == 3 ||
                          group.team_id == 1
                      ) && (
                        <Grid item>
                          <ButtonAccent
                            className="button-w100-mt21 pb14-lh25"
                            label="Attendance Summary"
                            onClick={() => {
                              setAttendanceSummary(true);
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>

                  <Box
                    sx={{ mb: 4, p: 4, marginLeft: { xs: "0", md: "6rem" } }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Payments
                    </Typography>
                    <hr />
                    <Grid container spacing={2}>
                      {lvhGroupAccessUserData?.some(
                        (group) => group.team_id == 2 || group.team_id == 5
                      ) && (
                        <Grid item>
                          <ButtonAccent
                            className="button-w100-mt21 pb14-lh25"
                            label="Payments Collection"
                          />
                        </Grid>
                      )}
                      {lvhGroupAccessUserData?.some(
                        (group) =>
                          group.team_id == 4 ||
                          group.team_id == 1 ||
                          group.team_id == 2 ||
                          group.team_id == 5
                      ) && (
                        <Grid item>
                          <ButtonAccent
                            className="button-w100-mt21 pb14-lh25"
                            label="Payments Summary"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      ) : (
        <h4 className="text-center pr20-lh32 high-emphasis-color pt-5">
          You have no meetings setup. Please reachout to your admin.
        </h4>
      )}
    </div>
  );
};

export default Meetings;
