import { useState } from "react";
import ButtonAccent from "../../../common/button-accent/ButtonAccent";
import AddOrEditLvhGroups from "../../add-lvh-group/AddOrEditLvhGroup";
import AccessLvhGroups from "../../access-lvh-groups/AccessLvhGroups";

const SectionLvhGroups = () => {
  const [addFolderPopup, setAddFolderPopup] = useState(false);

  function toggleAddFolderPopup() {
    setAddFolderPopup((ps) => !ps);
  }

  return (
    <div>
      <main
        style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
      >
        <>
          <div className="d-flex justify-content-center">
            <ButtonAccent
              label="Add LVH/LT Group"
              variant="span-button"
              startSpan="+"
              startSpanClassName="mr-1"
              onClick={toggleAddFolderPopup}
            />
          </div>
          <hr />
        </>
        {addFolderPopup && (
          <AddOrEditLvhGroups
            handleClose={toggleAddFolderPopup}
            open={addFolderPopup}
          />
        )}
      </main>
      <AccessLvhGroups />
    </div>
  );
};
export default SectionLvhGroups;
