import {
  CLEAR_TEAM_EM,
  FETCH_TEAM_EM_FAILURE,
  FETCH_TEAM_EM_SUCCESS,
  REQUEST_FETCH_TEAM_EM
} from "./team.types";

let initialState = {
  reload: false,
  response: {},
  error: null,
  loading: false
};

const fetchTeamEmReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FETCH_TEAM_EM:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_TEAM_EM_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        reload: false
      };

    case FETCH_TEAM_EM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reload: false
      };

    case CLEAR_TEAM_EM:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
        reload: false
      };

    default:
      return state;
  }
};

export default fetchTeamEmReducer;
