import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import "./siderbar.css";

import edit from "../../../assets/icon/edit.svg";
import editWhiteIcon from "../../../assets/icon/edit_white.svg";
import deleteIcon from "../../../assets/icon/delete.svg";
import { STD_ERROR_MESSAGE, WEDDING_FOLDERS } from "../../../utils/constant.js";
import EditCollectionNamePopup from "../edit-collection-name/EditCollectionName";
import { BUCKET_PATH } from "../../../utils/constant.js";
import {
  getPresignedUrlPromise,
  uploadImageUsingPresignedUrlPromise,
} from "../../../services/uploadImage.service";
import {
  deleteAGalleryFolderPromise,
  getAllGallery,
} from "../../../services/gallery.service";
import { updateCollection } from "../../../services/collections.service";
import { fetchCollectionEmSuccess } from "../../../redux/collection/collection.action";
import { getImageUrl } from "../../../utils/utils";
import AddFolderPopup from "../add-folder/AddFolder";
import DeleteFolderPopup from "../delete-popup/DeletePopup";
import EditFolderPopup from "../edit-folder/EditFolder";
import Image from "../../common/v2/image/Image";
import { IOSSwitch } from "../../common/ios-switch/IOSSwitch";

const ListItem = (props) => {
  const {
    className,
    onItemClick,
    checked,
    onToggleChange,
    label,
    removeSwitch,
  } = props;
  const defaultClassName =
    "folder-container d-flex justify-content-between pt-cursor";
  const mClassName = className
    ? defaultClassName + ` ${className}`
    : defaultClassName;
  return (
    <div className={mClassName} onClick={onItemClick}>
      <li className="pr12-lh24 primary-color">{label}</li>
      {!removeSwitch && (
        <IOSSwitch checked={checked} onChange={onToggleChange} />
      )}
    </div>
  );
};

const Sidebar = (props) => {
  const { loading, selected, setSelected, disableItems } = props;

  const imageRef = useRef();
  const [editCollectionNamePopup, setEditCollectionNamePopup] = useState(false);
  const [addFolderPopup, setAddFolderPopup] = useState(false);
  const [editFolderPopup, setEditFolderPopup] = useState({
    open: false,
    folderInfo: null,
  });
  const [deleteFolderPopup, setDeleteFolderPopup] = useState({
    open: false,
    id: null,
  });

  const collectionState = useSelector((state) => state.collectionEm);
  const galleryState = useSelector((state) => state.galleryEm);
  const uploadFilesState = useSelector((state) => state.uploadFiles);
  const dispatch = useDispatch();

  const params = useParams();

  const collection = collectionState.response?.data;
  const galleryData = galleryState.response?.data;

  const settings = collectionState.response?.data?.settings
    ? JSON.parse(collectionState.response?.data?.settings)
    : {};

  function handleFolderChange(folderId, subFolder = null) {
    setSelected({ folder: folderId, subFolder: subFolder });
  }

  function toggleEditCollectionPopup() {
    setEditCollectionNamePopup((ps) => !ps);
  }

  function openEditFolderPopup(folderInfo) {
    setEditFolderPopup({
      open: true,
      folderInfo,
    });
  }

  function isFilesUploading() {
    return !isEmpty(uploadFilesState.fileProgress);
  }

  function closeEditFolderPopup() {
    setEditFolderPopup({
      open: false,
      folderInfo: null,
    });
  }

  function toggleAddFolderPopup() {
    setAddFolderPopup((ps) => !ps);
  }

  function handleFolderDeleteClick(id) {
    setDeleteFolderPopup({
      open: true,
      id,
    });
  }

  function handleDeleteSubFolderEvent() {
    deleteAGalleryFolderPromise(params.collectionId, deleteFolderPopup.id)
      .then((res) => {
        dispatch(getAllGallery(params.collectionId));
        closeDeletePopup();
      })
      .catch((err) => {
        toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
      });
  }

  function closeDeletePopup() {
    setDeleteFolderPopup({
      open: false,
      id: null,
    });
  }

  function handleVisibilityChange(folder) {
    const currSettings = {
      description: settings.hasOwnProperty("description")
        ? Boolean(settings.description)
        : false,
      home: settings.hasOwnProperty("home") ? Boolean(settings.home) : false,
      associates: settings.hasOwnProperty("associates")
        ? Boolean(settings.associates)
        : false,
      residents: settings.hasOwnProperty("residents")
        ? Boolean(settings.residents)
        : false,
      feed: settings.hasOwnProperty("feed") ? Boolean(settings.feed) : false,
      events: settings.hasOwnProperty("events")
        ? Boolean(settings.events)
        : false,
      meetings: settings.hasOwnProperty("meetings")
        ? Boolean(settings.meetings)
        : false,
      policies: settings.hasOwnProperty("policies")
        ? Boolean(settings.policies)
        : false,
    };

    switch (folder) {
      case WEDDING_FOLDERS.description:
        currSettings.description = !currSettings.description;
        break;
      case WEDDING_FOLDERS.home:
        currSettings.home = !currSettings.home;
        break;
      case WEDDING_FOLDERS.associates:
        currSettings.associates = !currSettings.associates;
        break;
      case WEDDING_FOLDERS.residents:
        currSettings.residents = !currSettings.residents;
        break;
      case WEDDING_FOLDERS.feed:
        currSettings.feed = !currSettings.feed;
        break;
      case WEDDING_FOLDERS.events:
        currSettings.events = !currSettings.events;
        break;
      case WEDDING_FOLDERS.policies:
        currSettings.policies = !currSettings.policies;
        break;
      case WEDDING_FOLDERS.permalink:
        currSettings.permalink = !currSettings.permalink;
        break;
      case WEDDING_FOLDERS.team:
        currSettings.team = !currSettings.team;
        break;
      case WEDDING_FOLDERS.meetings:
        currSettings.meetings = !currSettings.meetings;
      default:
    }

    updateCollection({ settings: JSON.stringify(currSettings) }, collection.id)
      .then((res) => {
        dispatch(fetchCollectionEmSuccess(res.data));
      })
      .catch((err) =>
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        )
      );
  }

  function getAppendingClassName(item) {
    let appendingClassName = "";
    if (selected.folder === item) appendingClassName += "selected ";
    if (isFilesUploading()) appendingClassName += "disabled ";
    return appendingClassName;
  }

  function handleCollectionThumbnailChange(event) {
    const file = event.target.files[0];
    if (file) {
      let imageId = "";
      const presignedUrlPayload = [
        {
          bucket_path: `${BUCKET_PATH.collection}/${collection.id}/${file.name}`,
          image_name: file.name,
        },
      ];

      getPresignedUrlPromise(presignedUrlPayload)
        .then((res) => {
          imageId = res?.res?.data?.data?.[0]?.image_id;
          return uploadImageUsingPresignedUrlPromise(
            res?.res?.data?.data?.[0]?.upload_url,
            file
          );
        })
        .then((res) => {
          return updateCollection({ cover_image: imageId }, collection.id);
        })
        .then((res) => {
          dispatch(fetchCollectionEmSuccess(res.data));
        })
        .catch((err) => {
          toast.error(
            !isEmpty(err?.data?.error_data)
              ? err?.data?.error_data
              : STD_ERROR_MESSAGE
          );
        });
    }
  }

  const getAssociatesTabName = (category) => {
    switch (category) {
      case "t":
        return "Associates";
      case "r":
        return "LT/MC";
      default:
        return "Associates";
    }
  };

  const getResidentsTabName = (category) => {
    switch (category) {
      case "t":
        return "Residents";
      case "r":
        return "Members";
      default:
        return "Residents";
    }
  };

  return !loading.collection ? (
    !isEmpty(collection) ? (
      <aside
        style={{
          maxWidth: "280px",
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "auto",
        }}
        className="p-4 w-100 h-100"
      >
        <div>
          <div className="d-flex align-items-center justify-content-start">
            <h4 className="truncate pb16-lh24 m-0">{collection?.name}</h4>
            <img
              className="edit-icon pt-cursor"
              src={edit}
              alt=""
              onClick={toggleEditCollectionPopup}
            ></img>
          </div>
          <div className="my-1 d-flex align-items-center justify-content-between">
            <span className="pr12-lh24 medium-emphasis-color m-0">
              {moment(collection?.start_date).format("MMMM Do, YYYY")}
            </span>
            {/* <span className="justify-self-flex-end pr12-lh24 medium-emphasis-color m-0">Unpublished</span> */}
          </div>
          <div className="position-relative w-100" style={{ height: "120px" }}>
            <LazyLoadComponent>
              <Image
                src={getImageUrl(collection?.image?.bucket_path)}
                alt=""
                containerCustomStyle={{
                  height: "130px",
                  overflow: "hidden",
                }}
                imageCustomStyle={{
                  objectFit: "cover",
                  objectPosition: "center",
                  width: "100%",
                  height: "100%",
                }}
              />
            </LazyLoadComponent>
            <button
              className="collection-thumbnail-edit pt-cursor"
              onClick={(event) => {
                event.preventDefault();
                imageRef.current.click();
              }}
            >
              <img
                className="edit-collection-thumbnail-icon"
                src={editWhiteIcon}
                alt=""
              />
            </button>
            <input
              type="file"
              style={{ display: "none" }}
              ref={imageRef}
              accept="image/png, image/jpeg, image/jpg"
              name="wallpaper"
              onChange={handleCollectionThumbnailChange}
            />
          </div>
        </div>
        <div className="pt-3">
          <nav>
            <ul className="line-style-type-none">
              {collection.category_id == "t" ||
              collection.category_id == "r" ? (
                <>
                  <ListItem
                    className={getAppendingClassName(
                      WEDDING_FOLDERS.description
                    )}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.description)
                    }
                    checked={!!settings?.description}
                    removeSwitch
                    label="Description"
                  />
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.home)}
                    onItemClick={() => handleFolderChange(WEDDING_FOLDERS.home)}
                    checked={!!settings?.home}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.home)
                    }
                    label="Home"
                  />
                  <ListItem
                    className={getAppendingClassName(
                      WEDDING_FOLDERS.associates
                    )}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.associates)
                    }
                    checked={!!settings?.associates}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.associates)
                    }
                    label={getAssociatesTabName(collection.category_id)}
                  />
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.permalink)}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.permalink)
                    }
                    checked={!!settings?.permalink}
                    removeSwitch
                    label="Permalink"
                  />
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.team)}
                    onItemClick={() => handleFolderChange(WEDDING_FOLDERS.team)}
                    checked={!!settings?.team}
                    removeSwitch
                    label="LVH/LT Team"
                  />
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.meetings)}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.meetings)
                    }
                    checked={!!settings?.meetings}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.meetings)
                    }
                    label="Settings"
                  />
                  <div
                    className={`sub-folder-container d-flex justify-content-between pt-cursor ${
                      selected.subFolder === "Attendance" ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleFolderChange(
                        WEDDING_FOLDERS.meetings,
                        "Attendance"
                      );
                    }}
                  >
                    <li className="pr10-lh15 medium-emphasis-color">
                      Attendance
                    </li>
                    <div className="sub-folder-options">
                      <img
                        className="folder-item-icon mr-2"
                        src={edit}
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className={`sub-folder-container d-flex justify-content-between pt-cursor ${
                      selected.subFolder === "Payments" ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleFolderChange(WEDDING_FOLDERS.meetings, "Payments");
                    }}
                  >
                    <li className="pr10-lh15 medium-emphasis-color">
                      Payments
                    </li>
                    <div className="sub-folder-options">
                      <img
                        className="folder-item-icon mr-2"
                        src={edit}
                        alt=""
                      />
                    </div>
                  </div>
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.residents)}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.residents)
                    }
                    checked={!!settings?.residents}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.residents)
                    }
                    label={getResidentsTabName(collection.category_id)}
                  />
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.feed)}
                    onItemClick={() => handleFolderChange(WEDDING_FOLDERS.feed)}
                    checked={!!settings?.feed}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.feed)
                    }
                    label="Feed"
                  />
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.events)}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.events)
                    }
                    checked={!!settings?.events}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.events)
                    }
                    label="Gallery"
                  />
                  <div>
                    {!loading.gallery
                      ? !isEmpty(galleryData) &&
                        galleryData.map((galleryItem) => (
                          <div
                            key={galleryItem.id}
                            className={`sub-folder-container d-flex justify-content-between pt-cursor ${
                              selected.subFolder === galleryItem.id
                                ? "selected"
                                : ""
                            } ${isFilesUploading() ? "disabled" : ""}`}
                            onClick={() =>
                              handleFolderChange(
                                WEDDING_FOLDERS.photos_gallery,
                                galleryItem.id
                              )
                            }
                          >
                            <li className="pr10-lh15 medium-emphasis-color">
                              {galleryItem.title}
                            </li>
                            <div className="sub-folder-options">
                              <img
                                className="folder-item-icon mr-2"
                                src={edit}
                                onClick={() => openEditFolderPopup(galleryItem)}
                                alt=""
                              />
                              <img
                                className="folder-item-icon"
                                src={deleteIcon}
                                onClick={() =>
                                  handleFolderDeleteClick(galleryItem.id)
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        ))
                      : null}
                    <div
                      className="add-folder-container pt-cursor d-flex flex-column"
                      onClick={toggleAddFolderPopup}
                    >
                      <span className="pr10-lh15 medium-emphasis-color">
                        + Add Folder
                      </span>
                    </div>
                  </div>
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.policies)}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.policies)
                    }
                    checked={!!settings?.policies}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.policies)
                    }
                    label="Policies"
                  />
                </>
              ) : (
                <>
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.invites)}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.invites)
                    }
                    checked={!!settings?.invitation}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.invites)
                    }
                    label="Invites"
                  ></ListItem>
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.register)}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.register)
                    }
                    removeSwitch
                    label="Register Accessibility"
                  />
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.venue)}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.venue)
                    }
                    checked={!!settings?.venue}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.venue)
                    }
                    label="Venue"
                  />
                  <ListItem
                    className={getAppendingClassName(
                      WEDDING_FOLDERS.notification
                    )}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.notification)
                    }
                    removeSwitch={true}
                    label="Notifications"
                  />
                  <ListItem
                    className={getAppendingClassName(
                      WEDDING_FOLDERS.know_the_couple
                    )}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.know_the_couple)
                    }
                    checked={!!settings?.know_the_host}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.know_the_couple)
                    }
                    label="About"
                  />
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.itinerary)}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.itinerary)
                    }
                    checked={!!settings?.itinerary}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.itinerary)
                    }
                    label="Itinerary"
                  />
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.live_feed)}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.live_feed)
                    }
                    checked={!!settings?.live_updates}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.live_feed)
                    }
                    label="Live Feed"
                  />
                  <ListItem
                    className={getAppendingClassName(WEDDING_FOLDERS.games)}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.games)
                    }
                    checked={!!settings?.games}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.games)
                    }
                    label="Games"
                  />
                  <ListItem
                    className={getAppendingClassName(
                      WEDDING_FOLDERS.face_recognition
                    )}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.face_recognition)
                    }
                    removeSwitch
                    label="Face Recognition"
                  />
                  {/* <ListItem
                className={getAppendingClassName(WEDDING_FOLDERS.rsvp)}
                onItemClick={() => handleFolderChange(WEDDING_FOLDERS.rsvp)}
                checked={!!settings?.rsvp}
                onToggleChange={() =>
                  handleVisibilityChange(WEDDING_FOLDERS.rsvp)
                }
                label="RSVP"
              /> */}
                  <ListItem
                    className={getAppendingClassName(
                      WEDDING_FOLDERS.preferences
                    )}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.preferences)
                    }
                    checked={!!settings?.preferences}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.preferences)
                    }
                    label="RSVP & Preference"
                  />
                  <ListItem
                    className={getAppendingClassName(
                      WEDDING_FOLDERS.photos_gallery
                    )}
                    onItemClick={() =>
                      handleFolderChange(WEDDING_FOLDERS.photos_gallery)
                    }
                    checked={!!settings?.gallery}
                    onToggleChange={() =>
                      handleVisibilityChange(WEDDING_FOLDERS.photos_gallery)
                    }
                    label="Photo Gallery"
                  />
                  <div>
                    {!loading.gallery
                      ? !isEmpty(galleryData) &&
                        galleryData.map((galleryItem) => (
                          <div
                            key={galleryItem.id}
                            className={`sub-folder-container d-flex justify-content-between pt-cursor ${
                              selected.subFolder === galleryItem.id
                                ? "selected"
                                : ""
                            } ${isFilesUploading() ? "disabled" : ""}`}
                            onClick={() =>
                              handleFolderChange(
                                WEDDING_FOLDERS.photos_gallery,
                                galleryItem.id
                              )
                            }
                          >
                            <li className="pr10-lh15 medium-emphasis-color">
                              {galleryItem.title}
                            </li>
                            <div className="sub-folder-options">
                              <img
                                className="folder-item-icon mr-2"
                                src={edit}
                                onClick={() => openEditFolderPopup(galleryItem)}
                                alt=""
                              />
                              <img
                                className="folder-item-icon"
                                src={deleteIcon}
                                onClick={() =>
                                  handleFolderDeleteClick(galleryItem.id)
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        ))
                      : null}
                    <div
                      className="add-folder-container pt-cursor d-flex flex-column"
                      onClick={toggleAddFolderPopup}
                    >
                      <span className="pr10-lh15 medium-emphasis-color">
                        + Add Folder
                      </span>
                    </div>
                  </div>
                </>
              )}
            </ul>
          </nav>
        </div>
        {editCollectionNamePopup && (
          <EditCollectionNamePopup handleClose={toggleEditCollectionPopup} />
        )}
        {addFolderPopup && (
          <AddFolderPopup handleClose={toggleAddFolderPopup} />
        )}
        {deleteFolderPopup.open && (
          <DeleteFolderPopup
            heading="DELETE FOLDER"
            text="This folder will be deleted permanently. This cannot be undone."
            handleDelete={handleDeleteSubFolderEvent}
            handleClose={closeDeletePopup}
          />
        )}
        {editFolderPopup.open && (
          <EditFolderPopup
            handleClose={closeEditFolderPopup}
            folderInfo={editFolderPopup.folderInfo}
          />
        )}
      </aside>
    ) : null
  ) : null;
};

export default Sidebar;
