import {
  CLEAR_FETCH_MEETINGHISTORY_STATES,
  FETCH_MEETINGHISTORY_FAILURE,
  FETCH_MEETINGHISTORY_SUCCESS,
  REQUEST_FETCH_MEETINGHISTORY,
} from "./meetingHistory.types";

export const requestFetchHistoryMeeting = () => {
  return {
    type: REQUEST_FETCH_MEETINGHISTORY,
  };
};

export const fetchMeetingHistorySuccess = (response) => {
  return {
    type: FETCH_MEETINGHISTORY_SUCCESS,
    payload: response,
  };
};

export const fetchMeetingHistoryFailure = (error) => {
  return {
    type: FETCH_MEETINGHISTORY_FAILURE,
    payload: error,
  };
};

export const clearFetchMeetingHistoryStates = () => {
  return {
    type: CLEAR_FETCH_MEETINGHISTORY_STATES,
  };
};
