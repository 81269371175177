import { Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import cancelIcon from "../../../assets/icon/cancel.svg";
import errorInfoIcon from "../../../assets/icon/info-red.svg";
import ButtonAccent from "../../common/button-accent/ButtonAccent";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { clearFetchAccessGroupUserEmStates } from "../../../redux/group-users/groupUsers.action";
import {
  addUsersToAccessGroup,
  getAccessGroupUsers,
  removeUserFromAccessGroup,
} from "../../../services/group-for-auth.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";

const AddGroupUsers = (props) => {
  const { editItem } = props;
  const inputRef = useRef(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const params = useParams();
  const dispatch = useDispatch();

  const groupAccessUserState = useSelector((state) => state.accessGroupUserAd);
  const groupAccessUserData = groupAccessUserState?.response?.data;

  console.log(groupAccessUserData);

  useEffect(() => {
    if (
      !groupAccessUserState?.loading &&
      !isEmpty(groupAccessUserState?.response)
    ) {
      setPageLoading(false);
    }
    if (
      !groupAccessUserState?.loading &&
      !isEmpty(groupAccessUserState?.error)
    ) {
      setPageLoading(false);
      toast.error(STD_ERROR_MESSAGE);
    }
  }, [groupAccessUserState]);

  useEffect(() => {
    if (isEmpty(groupAccessUserState?.response)) {
      dispatch(getAccessGroupUsers(params.entityId, editItem.id));
    }
    return () => {
      dispatch(clearFetchAccessGroupUserEmStates());
    };
  }, []);

  const initialValues = {
    guest: [],
  };

  const validationSchema = Yup.object().shape({
    guest: Yup.array()
      .of(
        Yup.string().test("text_validation", "Invalid", async (value) => {
          if (!value) return false;
          const emailSchema = Yup.string().email().required();

          const isValidEmail = await emailSchema.isValid(value);
          const isValidNumber = isValidPhoneNumber(value);

          return isValidEmail || isValidNumber;
        })
      )
      .min(1, "Enter at least a mobile number / email ID."),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  function handleEnter(event) {
    if (
      event.key.toLowerCase() === "enter" ||
      event.key.toLowerCase() === ","
    ) {
      const value = event.target.value;
      if (value) {
        const existingValues = formik.values.guest;
        const values = value.split(" ");
        console.log(existingValues);
        const filteredValues = values
          .map((item) =>
            item.length > 10 && !item.startsWith("+") ? "+" + item : item
          )
          .map((item) => (item.length === 10 ? "+91" + item : item))
          .filter((item) => !existingValues.includes(item));
        formik.setFieldValue("guest", [...existingValues, ...filteredValues]);
        inputRef.current.value = "";
      }
      event.preventDefault();
    }
  }

  function onSubmit(values, { resetForm }) {
    if (!submitting) {
      setSubmitting(true);
      const payload = [];
      for (const value of values.guest) {
        payload.push({ mobile: value });
      }
      return addUsersToAccessGroup(params.entityId, editItem.id, payload)
        .then((res) => {
          setSubmitting(false);
          dispatch(getAccessGroupUsers(params.entityId, editItem.id));
        })
        .catch((err) => {
          setSubmitting(false);
          toast.error(err?.data || STD_ERROR_MESSAGE);
        });
    }
  }
  const handleDelete = (index, value) => {
    const filteredValues = formik.values.guest?.filter(
      (item, i) => i !== index
    );
    formik.setFieldValue("guest", [...filteredValues]);
  };

  function onRemoveTriggered(id) {
    return removeUserFromAccessGroup(params.entityId, editItem.id, id)
      .then((res) => {
        dispatch(getAccessGroupUsers(params.entityId, editItem.id));
        toast.info("Removed User");
      })
      .catch((err) => {
        toast.error(err?.error_data || STD_ERROR_MESSAGE);
      });
  }

  return (
    <div className="popup">
      <div className="box">
        <img
          className="close-icon"
          onClick={props.onCancel}
          src={cancelIcon}
          alt=""
        />
        <main
          style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
        >
          <CollectionHeaderPartner title={editItem.name} />
          <div style={{ marginTop: "27px" }}>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-start">
                <div className="mr-3" style={{ flex: "1 1 auto" }}>
                  <div
                    style={{
                      border: "1px solid var(--information-color)",
                      background: "white",
                      borderRadius: "4px",
                      padding: "0.25rem",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {formik.values.guest?.map((item, index) => (
                      <Chip
                        sx={{
                          margin: "0.25rem",
                        }}
                        key={item}
                        label={item}
                        variant="outlined"
                        onDelete={() => handleDelete(index, item)}
                        {...(formik.errors.guest?.[index]
                          ? { color: "error" }
                          : {})}
                      />
                    ))}
                    <input
                      ref={inputRef}
                      placeholder="Enter email ID / guest number"
                      className="pr16-lh24"
                      style={{
                        minWidth: "300px",
                        border: "none",
                        outline: "none",
                        margin: "0.25rem",
                      }}
                      onKeyDown={handleEnter}
                    />
                  </div>
                  {!isEmpty(formik.errors.guest) ? (
                    formik.errors.guest instanceof Array ? (
                      formik.errors.guest?.includes("Invalid") ? (
                        <div className="d-flex align-items-center justify-content-start mt-2">
                          <img src={errorInfoIcon} alt="" />
                          <span className="margin-left-5 pr10-lh15 error-color">
                            Invalid
                          </span>
                        </div>
                      ) : null
                    ) : (
                      <div className="d-flex align-items-center justify-content-start mt-2">
                        <img src={errorInfoIcon} alt="" />
                        <span className="margin-left-5 pr10-lh15 error-color">
                          {formik.errors.guest}
                        </span>
                      </div>
                    )
                  ) : null}
                </div>
                <ButtonAccent
                  label="Add to List"
                  type="submit"
                  customStyling={{ whiteSpace: "nowrap" }}
                />
              </div>
            </form>
            <div style={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                {groupAccessUserData?.map((guest) => (
                  <Grid
                    key={guest.id}
                    item
                    md={6}
                    sx={{ paddingRight: "20px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            background: "blue",
                          }}
                        />
                        <div
                          style={{
                            paddingLeft: "5px",
                          }}
                        >
                          <h4
                            className="pb14-lh21 primary-color"
                            style={{
                              marginBottom: "2px",
                            }}
                          >
                            {guest.name || "Name"}
                          </h4>

                          {guest.mobile && (
                            <p
                              className="pr12-lh18 medium-emphasis-color"
                              style={{
                                marginBottom: "0px",
                              }}
                            >
                              {guest.mobile}
                            </p>
                          )}
                        </div>
                      </div>
                      <div style={{ marginLeft: "auto" }}>
                        <p
                          className="pb12-lh18 error-color pt-cursor"
                          style={{
                            marinBottom: "0px",
                          }}
                          onClick={() => onRemoveTriggered(guest.id)}
                        >
                          Remove
                        </p>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default AddGroupUsers;
