// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const env = process.env.REACT_APP_ENV;
const firebaseConfig =
  env === "production"
    ? {
        apiKey: "AIzaSyB3XLUtbvJB17JxNzM5VGoICBSvjwzVfLw",
        authDomain: "trueco-82e4b.firebaseapp.com",
        projectId: "trueco-82e4b",
        storageBucket: "trueco-82e4b.appspot.com",
        messagingSenderId: "1033467733928",
        appId: "1:1033467733928:web:ad367f34cf652920c41f9d",
        measurementId: "G-SNGEJTGZZ0",
      }
    : {
        apiKey: "AIzaSyCH4ElR9_r6i4GQ1OtsR7G7e5HUcfRiV_w",
        authDomain: "trueco-staging.firebaseapp.com",
        projectId: "trueco-staging",
        storageBucket: "trueco-staging.appspot.com",
        messagingSenderId: "500320904708",
        appId: "1:500320904708:web:db89a9b323cb934b04f751",
        measurementId: "G-XYKGS3G0C4",
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
