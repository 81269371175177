import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import InputBox from "../../common/input-box/InputBox";
import ButtonPrimary from "../../common/button-accent/ButtonAccent";
import PhoneInput from "../../common/v2/phone-input/PhoneInput";
import { STD_ERROR_MESSAGE, ROLE_CATEGORIES } from "../../../utils/constant";
import { storeUserCredentials } from "../../../utils/utils";

import "./sign-up-form.css";

import { signUpByEmailAndPassword } from "../../../services/firebase-auth.service";
import { signUpUser } from "../../../services/auth.service";
import Error from "../../common/v2/error/Error";

import { isValidPhoneNumber } from "react-phone-number-input";

function SignUp(props) {
  const { category, partner } = props;

  const history = useHistory();

  const onSubmit = (values) => {
    signUpByEmailAndPassword(values.email, values.password)
      .then((res) => {
        const { password, ...rest } = values;
        const payload = { ...rest, firebase_id: res?.user?.uid };
        signUpUser(payload)
          .then((response) => {
            if (category === ROLE_CATEGORIES.eventManager) {
              storeUserCredentials({
                authToken: response.data.access_token,
                userId: response.data.user.id,
                userRole: response.data.user.category,
                userName: response.data.user.name,
                studioName: response.data.user.enterprise_name,
                partnerType: partner,
              });
              history.push(`/${partner}/${response.data.user.id}`);
            } else if (category === ROLE_CATEGORIES.other) {
              storeUserCredentials({
                authToken: response.data.access_token,
                userId: response.data.user.id,
                userRole: response.data.user.category,
              });
              history.push("/collections");
            }
          })
          .catch((err) => {
            toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
          });
      })
      .catch((error) => {
        toast.error(error?.message || STD_ERROR_MESSAGE);
      });
  };

  const getInitialValues = () => {
    switch (category) {
      case ROLE_CATEGORIES.eventManager:
        return {
          enterprise_name: "",
          website: "",
          email: "",
          name: "",
          mobile: "",
          password: "",
          category: category,
        };
      case ROLE_CATEGORIES.other:
        return {
          email: "",
          password: "",
          category: category,
        };
      default:
        return null;
    }
  };

  const getValidationSchema = () => {
    switch (category) {
      case ROLE_CATEGORIES.eventManager:
        return Yup.object({
          enterprise_name: Yup.string().required(
            "Please enter your studio name."
          ),
          email: Yup.string()
            .required("Please enter your email.")
            .email("Invalid email Id"),
          name: Yup.string().required("Please enter your name."),
          mobile: Yup.string()
            .required("Please enter your mobile number.")
            // .matches(/^[6-9]\d{9}$/, "Invalid mobile number"),
            .test("validatePhoneNumber", "Invalid mobile number", (val) => {
              return val && isValidPhoneNumber(val);
            }),
          password: Yup.string()
            .required("Please enter your password.")
            .test(
              "len",
              "Must contain atleast 1 character and must not contain exceeds more than 15 Characters",
              (val) => {
                return val && val.trim().length < 16 && val.trim().length > 0;
              }
            ),
        });
      case ROLE_CATEGORIES.other:
        return Yup.object({
          email: Yup.string()
            .required("Please enter your email.")
            .email("Invalid email Id"),
          password: Yup.string()
            .required("Please enter your password.")
            .test(
              "len",
              "Must contain atleast 1 character and must not contain exceeds more than 15 Characters",
              (val) => {
                return val && val.trim().length < 16 && val.trim().length > 0;
              }
            ),
        });
      default:
        return null;
    }
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validationSchema: getValidationSchema(),
  });

  function getFormInputs() {
    switch (category) {
      case ROLE_CATEGORIES.eventManager:
        return (
          <>
            <InputBox
              type="text"
              name="enterprise_name"
              placeholder="Studio Name"
              value={formik.values.enterprise_name}
              onChange={formik.handleChange}
              errorMsg={
                formik.touched.enterprise_name && formik.errors.enterprise_name
              }
              onBlur={formik.handleBlur}
            />

            <InputBox
              type="email"
              name="email"
              placeholder="Email ID"
              value={formik.values.email}
              onChange={formik.handleChange}
              errorMsg={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
            />
            <InputBox
              type="text"
              name="name"
              placeholder="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              errorMsg={formik.touched.name && formik.errors.name}
              onBlur={formik.handleBlur}
            />
            {/* <InputBox 
                            type='tel' 
                            name='mobile' 
                            placeholder='Mobile Number' 
                            value={ formik.values.mobile } 
                            onChange={formik.handleChange} 
                            errorMsg={formik.touched.mobile && formik.errors.mobile} 
                            onBlur={formik.handleBlur} 
                        /> */}
            <div className="mb-3">
              <PhoneInput
                value={formik.values.mobile}
                onChange={(value) => formik.setFieldValue("mobile", value)}
                name="mobile"
                placeholder="Mobile Number"
                onBlur={formik.handleBlur}
                error={formik.touched.mobile && formik.errors.mobile}
              />
              {formik.touched.mobile && formik.errors.mobile && (
                <Error errorMsg={formik.errors.mobile} className="mt-2" />
              )}
            </div>
            <InputBox
              type="password"
              name="password"
              placeholder="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              errorMsg={formik.touched.password && formik.errors.password}
              onBlur={formik.handleBlur}
            />
          </>
        );
      case ROLE_CATEGORIES.other:
        return (
          <>
            <InputBox
              type="email"
              name="email"
              placeholder="Email ID"
              value={formik.values.email}
              onChange={formik.handleChange}
              errorMsg={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
            />
            <InputBox
              type="password"
              name="password"
              placeholder="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              errorMsg={formik.touched.password && formik.errors.password}
              onBlur={formik.handleBlur}
            />
          </>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {getFormInputs()}
        <ButtonPrimary
          className="button-w100-mt21 pb14-lh21"
          label="Sign up"
          type="submit"
        />
        <p className="sign-up-declarator pr12-lh16">
          By Signing up, you agree with TrueCo's
          <span
            className="pb12-lh16 p4-horizontal pt-cursor"
            onClick={() => history.push("/terms-of-use")}
          >
            Terms of Services
          </span>
          and
          <span
            className="pb12-lh18 p4-horizontal pt-cursor"
            onClick={() => history.push("/privacy-policy")}
          >
            Privacy Policy
          </span>
        </p>
      </form>
    </>
  );
}

export default SignUp;
