import { useHistory } from "react-router-dom";
import BackgroundImgLandscape from "../../assets/image/wedding-couple-walks-along-ocean-shore-landscape.png";
import BackgroundImgMobile from "../../assets/image/wedding-couple-walks-along-ocean-shore-mobile.png";
import BackgroundImgTab from "../../assets/image/wedding-couple-walks-along-ocean-shore-tab.png";
import MessageIcon from "../../assets/icon/message-primary.svg";
import CallingIcon from "../../assets/icon/calling-primary.svg";
import WhatsUpIcon from "../../assets/icon/whats-up.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AD_GOOGLE_FORM_URL } from "../../utils/constant";

export default function Advertisement() {
  const isMobile = useMediaQuery("(max-width: 480px)");
  const isDesktop = useMediaQuery("(min-width: 992px)");

  const history = useHistory();

  if (isMobile) {
    return (
      <div
        className="p-4"
        style={{
          background: `url(${BackgroundImgMobile}) center center / cover no-repeat`,
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <h1
          style={{ maxWidth: "800px" }}
          className="montserrat-extra-bold-fs30-lh42 primary-color mb-3"
        >
          Share the joy of your wedding with family and friends!
        </h1>
        <h3
          style={{ maxWidth: "700px", marginBottom: "6vh" }}
          className="montserrat-medium-fs22-lh28 medium-emphasis-color"
        >
          Now send online invites, photos and more to all your guests.
        </h3>
        <div style={{ marginBottom: "8vh" }}>
          <h4
            style={{ textShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}
            className="montserrat-medium-fs18-lh24 high-emphasis-color mb-5"
          >
            Sign up{" "}
            <u
              className="montserrat-bold-fs18-lh24 pt-cursor error-color"
              onClick={() => window.open(AD_GOOGLE_FORM_URL, "_blank").focus()}
            >
              here
            </u>{" "}
            to set up your wedding's online experience
          </h4>
          <div className="d-flex align-items-center mb-2">
            <img
              style={{ width: "20px", height: "20px" }}
              className="d-inline-block mr-2"
              src={MessageIcon}
              alt=""
            />
            <h6
              style={{ textShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}
              className="mb-0 montserrat-medium-fs16-lh20 high-emphasis-color"
            >
              hello@pic-sea.com
            </h6>
          </div>
          <div className="d-flex align-items-center">
            <img
              style={{ width: "20px", height: "20px" }}
              className="d-inline-block mr-2"
              src={CallingIcon}
              alt=""
            />
            <img
              style={{ width: "20px", height: "20px" }}
              className="d-inline-block mr-2"
              src={WhatsUpIcon}
              alt=""
            />
            <h6
              style={{ textShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}
              className="mb-0 montserrat-medium-fs16-lh20 high-emphasis-color"
            >
              +91 9886 283 665
            </h6>
          </div>
        </div>
        <h3
          className="rubik-extra-bold-fs32-lh40 high-emphasis-color pt-cursor"
          onClick={() => history.push("/")}
        >
          TrueCo
        </h3>
      </div>
    );
  } else {
    return (
      <div
        className="px-5 pt-5 pb-3"
        style={{
          background: `url(${
            isDesktop ? BackgroundImgLandscape : BackgroundImgTab
          }) center bottom / cover no-repeat`,
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <h1
          style={{ maxWidth: "800px" }}
          className="montserrat-extra-bold-fs48-lh72 primary-color mb-3"
        >
          Share the joy of your wedding with family and friends!
        </h1>
        <h3
          style={{ maxWidth: "700px", marginBottom: "8vh" }}
          className="montserrat-medium-fs32-lh39 medium-emphasis-color"
        >
          Now send online invites, photos and more to all your guests.
        </h3>
        <div style={{ marginBottom: "15vh" }}>
          <h4
            style={{ textShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}
            className="montserrat-medium-fs24-lh29 high-emphasis-color mb-5"
          >
            Sign up{" "}
            <u
              className="montserrat-bold-fs24-lh29 error-color pt-cursor"
              onClick={() => window.open(AD_GOOGLE_FORM_URL, "_blank").focus()}
            >
              here
            </u>{" "}
            to set up your wedding's online experience
          </h4>
          <div className="d-flex align-items-center mb-3">
            <img className="d-inline-block mr-3" src={MessageIcon} alt="" />
            <h6
              style={{ textShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}
              className="mb-0 montserrat-medium-fs22-lh28 high-emphasis-color"
            >
              hello@pic-sea.com
            </h6>
          </div>
          <div className="d-flex align-items-center">
            <img className="d-inline-block mr-3" src={CallingIcon} alt="" />
            <img className="d-inline-block mr-3" src={WhatsUpIcon} alt="" />
            <h6
              style={{ textShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }}
              className="mb-0 montserrat-medium-fs22-lh28 high-emphasis-color"
            >
              +91 9886 283 665
            </h6>
          </div>
        </div>
        <h3
          className="rubik-extra-bold-fs54-lh64 high-emphasis-color pt-cursor"
          onClick={() => history.push("/")}
        >
          TrueCo
        </h3>
      </div>
    );
  }
}
