import {
  fetchAttendanceSummaryVisitorFailure,
  fetchAttendanceSummaryVisitorSuccess,
  requestFetchAttendanceSummaryVisitor,
} from "../redux/attendance-summary-visitor/attendanceSummaryVisitor.action";
import protectedWebClient from "../utils/protected-web-client";

export const createAttendanceSummaryVisitor = (
  payload,
  collectionId,
  meetingId
) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .post(
        `/event/${collectionId}/attendanceSummaryVisitor/meeting/${meetingId}`,
        payload
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const getAttendanceSummaryVisitorByEvent = (collectionId, meetingId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAttendanceSummaryVisitor());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/attendanceSummaryVisitor/meeting/${meetingId}`
      );
      dispatch(fetchAttendanceSummaryVisitorSuccess(response?.data));
    } catch (error) {
      dispatch(fetchAttendanceSummaryVisitorFailure(error?.response));
    }
  };
};

export const getAnAttendanceSummaryVisitor = (attendanceId, collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAttendanceSummaryVisitor());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/attendanceSummaryVisitor/${attendanceId}`
      );
      dispatch(fetchAttendanceSummaryVisitorSuccess(response?.data));
    } catch (error) {
      dispatch(fetchAttendanceSummaryVisitorFailure(error?.response));
    }
  };
};

export const deleteAttendanceSummaryVisitor = (collectionId, visitorId) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .delete(`/event/${collectionId}/attendanceSummaryVisitor/${visitorId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updateAttendanceSummaryVisitor = (
  payload,
  collectionId,
  visitorId
) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .put(
        `/event/${collectionId}/attendanceSummaryVisitor/${visitorId}`,
        payload
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};
