import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomSpinner from "../../../components/common/spinner/CustomSpinner";
import { fetchEventPermalink } from "../../../services/collections.service";
import Collection from "../collection/Collection";
import Entity from "./Entity";
import { fetchEntityPermalink } from "../../../services/entities.service";

const PermalinkResolver = () => {
  const [isEntityPermalinkLoading, setIsEntityPermalinkLoading] =
    useState(true);
  const [isEventPermalinkLoading, setIsEventPermalinkLoading] = useState(true);
  const entityPermalinkState = useSelector((state) => state.permalinkAd);
  const eventPermalinkState = useSelector((state) => state.eventPermalinkEm);
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const permalink = params?.permalink;

  const entityId = entityPermalinkState.response?.data?.entity_id;
  const eventId = eventPermalinkState.response?.data?.event_id;

  useEffect(() => {
    if (isEmpty(eventPermalinkState.response)) {
      dispatch(fetchEventPermalink(permalink));
    }
  }, []);
  useEffect(() => {
    if (isEmpty(entityPermalinkState.response)) {
      dispatch(fetchEntityPermalink(permalink));
    }
  }, []);

  useEffect(() => {
    if (
      !entityPermalinkState.loading &&
      !isEmpty(entityPermalinkState.response)
    ) {
      setIsEntityPermalinkLoading(false);
    }

    if (!entityPermalinkState.loading && !isEmpty(entityPermalinkState.error)) {
      setIsEntityPermalinkLoading(false);
      toast.error("Page not found.");
      history.push(`/collections`);
    }
  }, [entityPermalinkState]);

  useEffect(() => {
    if (
      !eventPermalinkState.loading &&
      !isEmpty(eventPermalinkState.response)
    ) {
      setIsEventPermalinkLoading(false);
    }

    if (!eventPermalinkState.loading && !isEmpty(eventPermalinkState.error)) {
      setIsEventPermalinkLoading(false);
      toast.error("Page not found.");
      history.push(`/collections`);
    }
  }, [eventPermalinkState]);

  return (
    <>
      {isEventPermalinkLoading && isEntityPermalinkLoading && <CustomSpinner />}
      {!isEventPermalinkLoading && !isEntityPermalinkLoading && entityId && (
        <Entity entityId={entityId} entityName={permalink} />
      )}
      {!isEventPermalinkLoading && !isEntityPermalinkLoading && eventId && (
        <Collection collectionId={eventId} collectionName={permalink} />
      )}
    </>
  );
};

export default PermalinkResolver;
