import {
  CLEAR_FETCH_MEETING_STATES,
  FETCH_MEETING_FAILURE,
  FETCH_MEETING_SUCCESS,
  REQUEST_FETCH_MEETING,
} from "./meeting.types";

export const requestFetchMeeting = () => {
  return {
    type: REQUEST_FETCH_MEETING,
  };
};

export const fetchMeetingSuccess = (response) => {
  return {
    type: FETCH_MEETING_SUCCESS,
    payload: response,
  };
};

export const fetchMeetingFailure = (error) => {
  return {
    type: FETCH_MEETING_FAILURE,
    payload: error,
  };
};

export const clearFetchMeetingStates = () => {
  return {
    type: CLEAR_FETCH_MEETING_STATES,
  };
};
