import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ENTITY_TYPES,
  EVENT_TYPES,
  STD_ERROR_MESSAGE,
} from "../../../utils/constant";
import { getImageUrl, getUserId } from "../../../utils/utils";
import { getAllCollections } from "../../../services/collections.service";
import CustomSpinner from "../../../components/common/spinner/CustomSpinner";
import ClientFoldersLayout from "../../../components/layout/client-folders-layout/ClientFoldersLayout";
import CustomNavbar from "../../../components/layout/navbar/Navbar";
import { getAllEntities } from "../../../services/entities.service";
import { fetchProfile } from "../../../services/profile.service";

const Collections = () => {
  const collectionsState = useSelector((state) => state.collectionsEm);
  const entityContainerState = useSelector((state) => state.entitiesAd);
  const profileState = useSelector((state) => state.readProfileEm);

  const profileData = profileState?.response?.data;
  const dispatch = useDispatch();

  const [collectionLoading, setCollectionLoading] = useState(true);
  const [entityLoading, setEntityLoading] = useState(true);

  const [organizedCollections, setOrganizedCollections] = useState([]);
  const [organizedEntities, setOrganizedEntities] = useState([]);

  const collections = collectionsState?.response?.data;
  const entityContainers = entityContainerState?.response?.data;

  const history = useHistory();

  useEffect(() => {
    if (isEmpty(collectionsState.response) || collectionsState.reload)
      dispatch(getAllCollections());
    if (isEmpty(entityContainerState.response) || entityContainerState.reload)
      dispatch(getAllEntities());
    if (isEmpty(profileState.response)) {
      dispatch(fetchProfile(getUserId()));
    }
  }, []);

  useEffect(() => {
    if (!collectionsState.loading && !isEmpty(collectionsState.response)) {
      setCollectionLoading(false);
      setOrganizedCollections(organizeCollection(collections));
    }
    if (!collectionsState.loading && collectionsState.error) {
      setCollectionLoading(false);
      toast.error(
        collectionsState?.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [collectionsState]);

  useEffect(() => {
    if (!profileState.loading && !isEmpty(profileState.error)) {
      toast.error("Couldn't load user profile.");
    }
  }, [profileState]);

  useEffect(() => {
    if (
      !entityContainerState.loading &&
      !isEmpty(entityContainerState.response)
    ) {
      setEntityLoading(false);
      setOrganizedEntities(organizeEntities(entityContainers));
    }
    if (!entityContainerState.loading && entityContainerState.error) {
      setEntityLoading(false);
      toast.error(
        entityContainerState?.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [entityContainerState]);

  function organizeCollection(collections) {
    if (!collections) return [];

    const weddingEvents = [];
    const birthdayEvents = [];
    const otherEvents = [];
    const apartmentEvents = [];
    const bnichapters = [];

    const publishedEvents = collections.filter(
      (container) => container?.permalink?.publish
    );

    console.log(publishedEvents);

    for (const collection of collections) {
      if (collection.event.category_id === EVENT_TYPES.wedding)
        weddingEvents.push(collection);
      else if (collection.event.category_id === EVENT_TYPES.birthday)
        birthdayEvents.push(collection);
      else if (collection.event.category_id === EVENT_TYPES.event)
        otherEvents.push(collection);
      else if (collection.event.category_id === EVENT_TYPES.apartment)
        apartmentEvents.push(collection);
      else if (collection.event.category_id === EVENT_TYPES.bnichapter)
        bnichapters.push(collection);
    }

    const resultCollections = [];
    if (!isEmpty(weddingEvents))
      resultCollections.push({ name: "Weddings", events: weddingEvents });
    if (!isEmpty(birthdayEvents))
      resultCollections.push({ name: "Birthdays", events: birthdayEvents });
    if (!isEmpty(otherEvents))
      resultCollections.push({ name: "Events", events: otherEvents });
    if (!isEmpty(apartmentEvents))
      resultCollections.push({ name: "Apartments", events: apartmentEvents });
    if (!isEmpty(bnichapters))
      resultCollections.push({
        name: "Business Networks",
        events: bnichapters,
      });

    return resultCollections;
  }

  function organizeEntities(entityContainers) {
    if (!entityContainers) return [];

    const education = [];
    const events = [];
    const mnc = [];
    const food = [];
    const marketing = [];
    const property = [];
    const health = [];
    const business = [];
    const finance = [];
    const wedding = [];
    const photographers = [];
    const bakers = [];
    const makeup = [];
    const resorts = [];
    const caterers = [];
    const fashion = [];
    const travel = [];
    const artists = [];

    const publishedEntities = entityContainers.filter(
      (container) => container?.permalink?.publish
    );
    for (const publishedEntity of publishedEntities) {
      if (publishedEntity.entity.entity_type === ENTITY_TYPES.education)
        education.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.events_celebrations
      )
        events.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.mnc_hr)
        mnc.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.food_hospitality
      )
        food.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.marketing)
        marketing.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.property)
        property.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.health_wellness
      )
        health.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.business_owners
      )
        business.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.finance)
        finance.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.wedding_planners
      )
        wedding.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.photographers
      )
        photographers.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.bakers_chocolatiers
      )
        bakers.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.makeup_stylists
      )
        makeup.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.resorts)
        resorts.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.caterers)
        caterers.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.fashion)
        fashion.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.travel)
        travel.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.artists)
        artists.push(publishedEntity);
    }

    const resultEntities = [];
    if (!isEmpty(education))
      resultEntities.push({ name: "Education", entities: education });
    if (!isEmpty(events))
      resultEntities.push({ name: "Events & Celebrations", entities: events });
    if (!isEmpty(mnc)) resultEntities.push({ name: "MNC & HR", entities: mnc });
    if (!isEmpty(food))
      resultEntities.push({ name: "Food & Hospitality", entities: food });
    if (!isEmpty(marketing))
      resultEntities.push({ name: "Marketing", entities: marketing });
    if (!isEmpty(property))
      resultEntities.push({ name: "Property", entities: property });
    if (!isEmpty(health))
      resultEntities.push({ name: "Health & wellness", entities: health });
    if (!isEmpty(business))
      resultEntities.push({ name: "Business Owners", entities: business });
    if (!isEmpty(finance))
      resultEntities.push({ name: "Finance", entities: finance });
    if (!isEmpty(wedding))
      resultEntities.push({ name: "Wedding Planners", entities: wedding });
    if (!isEmpty(photographers))
      resultEntities.push({ name: "Photographers", entities: photographers });
    if (!isEmpty(bakers))
      resultEntities.push({ name: "Bakers Chocolatiers", entities: bakers });
    if (!isEmpty(makeup))
      resultEntities.push({ name: "Makeup Stylists", entities: makeup });
    if (!isEmpty(resorts))
      resultEntities.push({ name: "Resorts", entities: resorts });
    if (!isEmpty(caterers))
      resultEntities.push({ name: "Caterers", entities: caterers });
    if (!isEmpty(fashion))
      resultEntities.push({ name: "Fashion", entities: fashion });
    if (!isEmpty(travel))
      resultEntities.push({ name: "Travel", entities: travel });
    if (!isEmpty(artists))
      resultEntities.push({ name: "Artists", entities: artists });
    return resultEntities;
  }

  const handleCollectionTrigger = (collection) => {
    if (collection?.permalink?.publish) {
      history.push(`/${collection.permalink.permalink}`, "_blank");
      if (window.scrollY) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    } else {
      history.push(`/collection/${collection.event.id}`);
      if (window.scrollY) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  };

  const handleEntityTrigger = (entityContainers) => {
    if (entityContainers?.permalink?.publish) {
      history.push(`/${entityContainers.permalink.permalink}`, "_blank");
      if (window.scrollY) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    } else {
      history.push(`/entity/${entityContainers.entity.id}`);
      if (window.scrollY) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <>
      <CustomNavbar userProfile={profileData} />
      {!collectionLoading && !entityLoading ? (
        <div className="container pt-4 pb-1">
          {!isEmpty(organizedCollections) &&
            organizedCollections.map((eventsGroup, index) => (
              <div className="mb-4" key={index}>
                <h1 className="pb20-lh32 mb-3">{eventsGroup.name}</h1>
                <div className="collection-grid">
                  {eventsGroup.events.map((collection) => (
                    <LazyLoadComponent key={collection.event.id}>
                      <ClientFoldersLayout
                        thumbnail={getImageUrl(
                          collection?.event.image?.bucket_path
                        )}
                        collectionName={collection.event.name}
                        // enterpriseName={collection.creator.enterprise_name}
                        createdAt={moment(collection.event.start_date).format(
                          "MMMM Do, YYYY"
                        )}
                        handleCardClick={() =>
                          handleCollectionTrigger(collection)
                        }
                      />
                    </LazyLoadComponent>
                  ))}
                </div>
              </div>
            ))}

          {!isEmpty(organizedEntities) &&
            organizedEntities.map((entitiesGroup, index) => (
              <div className="mb-4" key={index}>
                <h1 className="pb20-lh32 mb-3">{entitiesGroup.name}</h1>
                <div className="collection-grid">
                  {entitiesGroup.entities.map((entityGroup) => (
                    <LazyLoadComponent key={entityGroup.entity.id}>
                      <ClientFoldersLayout
                        thumbnail={getImageUrl(
                          entityGroup.entity?.image?.bucket_path
                        )}
                        entityName={entityGroup.entity.name}
                        handleCardClick={() => handleEntityTrigger(entityGroup)}
                      />
                    </LazyLoadComponent>
                  ))}
                </div>
              </div>
            ))}

          {isEmpty(organizedCollections) && isEmpty(organizedEntities) && (
            <div className="userCenter">
              <h1 className="mb-4 pr20-lh32 high-emphasis-color text-center">
                You have no items to view yet.
              </h1>
            </div>
          )}
        </div>
      ) : (
        <CustomSpinner />
      )}
    </>
  );
};

export default Collections;
