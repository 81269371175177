import React, { useState, useEffect, Component } from "react";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import CustomSpinner from "../../common/spinner/CustomSpinner";

import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";

import { fetchResidents } from "../../../services/residents.service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const UserResidentsSection = (props) => {
  const {
    isMobile,
    name,
    description,
    website_link,
    imageUrl,
    layout,
    containerClassName,
  } = props;

  return (
    <Grid container spacing={2} className={containerClassName || ""}>
      <Grid
        item
        xs={6}
        className="d-flex justify-content-start align-items-center"
      >
        <img
          style={{
            width: "100%",
            maxWidth: "40rem",
            aspectRatio: "8/5",
            borderRadius: "10rem",
            objectFit: "cover",
            objectPosition: "center",
            marginBottom: "3rem",
          }}
          src={imageUrl}
          alt=""
        />
      </Grid>
      <Grid className="d-flex mt-3" item xs={6}>
        <div>
          <h4 className={isMobile ? "pb24-lh40" : "pb36-lh56"}>{name}</h4>
          <p className={`${isMobile ? "pr14-lh24" : "pr24-lh40"} mb-0`}>
            {description}
          </p>

          <div className={`${isMobile ? "pr14-lh24" : "pr24-lh40"} mb-0 mt-2`}>
            <a href={website_link} target="_blank">
              {website_link}
            </a>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

const UserResidents = (props) => {
  const residentsState = useSelector((state) => state.residentsAd);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const residentsData = residentsState?.response?.data;

  const isMobile = useMediaQuery("(max-width: 480px)");

  const isMobileOrTablet = useMediaQuery("(max-width: 599px)");
  const minHeight = isMobileOrTablet ? 56 + 56 + 40 : 64 + 56 + 40;

  useEffect(() => {
    if (isEmpty(residentsState.response)) {
      dispatch(fetchResidents(props.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!residentsState.loading && !isEmpty(residentsState.response)) {
      setLoading(false);
    }
    if (!residentsState.loading && !isEmpty(residentsState.error)) {
      setLoading(false);
      toast.error(residentsState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [residentsState]);

  return (
    <div style={{ minHeight: `calc(100vh - ${minHeight}px)` }}>
      {!loading ? (
        !isEmpty(residentsData) ? (
          <div className="py-5 px-4">
            <h2
              className={`${
                isMobile
                  ? "parisienne-normal-fs40-lh80"
                  : "parisienne-normal-fs72-lh80"
              } text-center`}
            >
              {props.eventType == "r" ? "Members" : "Residents"}
            </h2>
            <div
              className="mt-4"
              style={{
                maxWidth: "1200px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {residentsData.map((residentsSection, index) => (
                <UserResidentsSection
                  layout={index}
                  key={residentsSection.id}
                  isMobile={isMobile}
                  name={residentsSection.title}
                  description={residentsSection.custom_text}
                  website_link={residentsSection.website_link}
                  imageUrl={getImageUrl(residentsSection.image?.bucket_path)}
                  containerClassName="mb-3"
                />
              ))}
            </div>
          </div>
        ) : (
          <h4 className="text-center pr20-lh32 high-emphasis-color pt-5">
            There are no {props.eventType == "r" ? "members" : "residents"}
          </h4>
        )
      ) : (
        <CustomSpinner />
      )}
    </div>
  );
};

export default UserResidents;
