import {
  CLEAR_FETCH_MEETING_STATES,
  FETCH_MEETING_FAILURE,
  FETCH_MEETING_SUCCESS,
  REQUEST_FETCH_MEETING,
} from "./meeting.types";

let initialState = {
  reload: false,
  response: {},
  error: null,
  loading: false,
};

const meetingReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FETCH_MEETING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        reload: false,
      };

    case FETCH_MEETING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reload: false,
      };

    case CLEAR_FETCH_MEETING_STATES:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
        reload: false,
      };

    default:
      return state;
  }
};

export default meetingReducer;
