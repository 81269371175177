import {
  CLEAR_ATTENDANCE_FOR_SINGLE_MEMBER,
  FETCH_ATTENDANCE_SUMMARY_FOR_SINGLE_MEMBER_FAILURE,
  FETCH_ATTENDANCE_SUMMARY_FOR_SINGLE_MEMBER_SUCCESS,
  REQUEST_FETCH_ATTENDANCE_SUMMARY_FOR_SINGLE_MEMBER
} from "./attendanceSummaryForSingleMember.types";

export const requestFetchAttendanceSummaryForSingleMember = () => {
  return {
    type: REQUEST_FETCH_ATTENDANCE_SUMMARY_FOR_SINGLE_MEMBER
  };
};

export const fetchAttendanceSummaryForSingleMemberSuccess = (response) => {
  return {
    type: FETCH_ATTENDANCE_SUMMARY_FOR_SINGLE_MEMBER_SUCCESS,
    payload: response
  };
};

export const fetchAttendanceSummaryForSingleMemberFailure = (error) => {
  return {
    type: FETCH_ATTENDANCE_SUMMARY_FOR_SINGLE_MEMBER_FAILURE,
    payload: error
  };
};

export const clearAttendanceForSingleMember = () => {
  return {
    type: CLEAR_ATTENDANCE_FOR_SINGLE_MEMBER
  };
};
