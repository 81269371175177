import { Box, Tab, Tabs } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import BreadCrumbs from "../../components/common/box/bread-crumbs/BreadCrumbs";
import { getMeetingByEvent } from "../../services/meeting.service";
import AttendanceRecordForMember from "./AttendanceRecordForMember";
import AttendanceRecordForVisitor from "./AttendanceRecordForVisitor";
import MeetingDetail from "./MeetingDetail";

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: "2rem 0rem" }}>{children}</Box>}
    </div>
  );
}

export const getStatusColor = (status) => {
  switch (status) {
    case "On Time":
      return "#0080ff";
    case "Very Late":
    case "Late":
      return "#EB4E2C";
    case "Slightly Late":
      return "#CDC500";
    case "Absent":
      return "#ffffff";
    default:
      return null;
  }
};

export const getStatusbgColor = (status) => {
  switch (status) {
    case "On Time":
      return "#e6f3ff";
    case "Very Late":
    case "Late":
      return "#FFC6BA";
    case "Slightly Late":
      return "#FFFDCE";
    case "Absent":
      return "#1a1a1a";
    default:
      return null;
  }
};

const AttendanceRecord = (props) => {
  const collectionId = props.collectionId;
  const subtitle = props.subtitle;
  const { type } = props;

  const location = useLocation();
  const dispatch = useDispatch();

  const [value, setValues] = useState(0);

  const meetingState = useSelector((state) => state.meetingEm);
  const meetingData = meetingState?.response?.data;

  useEffect(() => {
    if (isEmpty(meetingState.response)) {
      dispatch(getMeetingByEvent(props.collectionId));
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };

  return (
    <Box className="page-content">
      <Box
        sx={{
          mt: { xs: 5, md: 8 },
          mb: { xs: 3, md: 6 },
        }}
      >
        <BreadCrumbs
          title="Meetings"
          subTitle={subtitle}
          titleLink={`${location.pathname}?selected=settings`}
        />
      </Box>
      <MeetingDetail
        collectionId={collectionId}
        value={value}
        meetingData={meetingData}
        type={type}
      />
      <Box sx={{ mt: 4, mb: 4 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Members" {...a11yProps(0)} />
          <Tab label="Visitors" {...a11yProps(1)} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <AttendanceRecordForMember
            collectionId={collectionId}
            meetingData={meetingData}
            type={type}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <AttendanceRecordForVisitor
            collectionId={collectionId}
            meetingData={meetingData}
            type={type}
          />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default AttendanceRecord;
