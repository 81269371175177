import {
  fetchAttendanceSummaryForSingleMemberFailure,
  fetchAttendanceSummaryForSingleMemberSuccess,
  requestFetchAttendanceSummaryForSingleMember,
} from "../redux/attendance-summary-for-single-member/attendanceSummaryForSingleMember.action";
import {
  fetchAttendanceSummaryMemberFailure,
  fetchAttendanceSummaryMemberSuccess,
  requestFetchAttendanceSummaryMember,
} from "../redux/attendance-summary-member/attendanceMember.action";
import protectedWebClient from "../utils/protected-web-client";

export const createAttendanceSummaryMember = (payload, collectionId) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .post(`/event/${collectionId}/attendanceSummaryMember`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const getAttendanceSummaryByEventAndMeetingId = (
  collectionId,
  meetingId
) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAttendanceSummaryMember());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/attendanceSummaryMember/meeting/${meetingId}`
      );
      dispatch(fetchAttendanceSummaryMemberSuccess(response?.data));
    } catch (error) {
      dispatch(fetchAttendanceSummaryMemberFailure(error?.response));
    }
  };
};

export const closeAttendanceForMemberPromise = (collectionId, meetingId) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .post(`/event/${collectionId}/meeting/${meetingId}/close`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const getAnAttendanceSummaryMember = (attendanceId, collectionId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAttendanceSummaryMember());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/attendanceSummaryMember/${attendanceId}`
      );
      dispatch(fetchAttendanceSummaryMemberSuccess(response?.data));
    } catch (error) {
      dispatch(fetchAttendanceSummaryMemberFailure(error?.response));
    }
  };
};

export const deleteAttendanceSummaryMember = (attendanceId, collectionId) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .delete(`/event/${collectionId}/attendanceSummaryMember/${attendanceId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

export const updateAttendanceSummaryMember = (payload, collectionId) => {
  return new Promise((resolve, reject) => {
    protectedWebClient
      .put(`/event/${collectionId}/attendanceSummaryMember`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};

//For Single member

export const getAttendanceSummaryByMember = (collectionId, memberId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchAttendanceSummaryForSingleMember());
      const response = await protectedWebClient.get(
        `/event/${collectionId}/attendanceSummaryMember/member`
      );
      dispatch(fetchAttendanceSummaryForSingleMemberSuccess(response?.data));
    } catch (error) {
      dispatch(fetchAttendanceSummaryForSingleMemberFailure(error?.response));
    }
  };
};
