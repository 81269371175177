import { isEmpty, size } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Field, FieldArray, Form, Formik } from "formik";
import errorInfoIcon from "../../../assets/icon/error_icon_red.svg";
import * as Yup from "yup";
import ImagePlaceholder from "../../../assets/icon/black-image.svg";
import SquareCloseIcon from "../../../assets/icon/cancel.svg";
import editWhiteIcon from "../../../assets/icon/edit_white.svg";
import {
  createKnowTheEntitySectionPromise,
  fetchKnowTheEntitySection
} from "../../../services/knowTheHost.service";
import {
  getPresignedUrlPromise,
  uploadImageUsingPresignedUrlPromise
} from "../../../services/uploadImage.service";
import {
  AD_BUCKET_PATH,
  SECTION_KNOW_THE_HOST_COMPONENTS
} from "../../../utils/constant";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";

const AddAboutEntity = (props) => {
  const imagesRef = useRef({});
  const params = useParams();
  const [submitting, setSubmitting] = useState(false);

  const knowTheHostState = useSelector((state) => state.knowTheHostEm);
  const dispatch = useDispatch();

  const knowTheHostSections = knowTheHostState?.response?.data;

  const [imageFiles, setImageFiles] = useState({
    currId: null,
    files: null //{1: {file: null, preview: null}}
  });

  const initialValues = {
    kth_request: [
      {
        title: "",
        custom_text: "",
        sequence: !isEmpty(knowTheHostSections)
          ? getSequence(knowTheHostSections)
          : 1,
        image_id: ""
      }
    ]
  };

  function getSequence(arr) {
    const sequenceNumbers = arr.map((item) => item.sequence);
    return Math.max(...sequenceNumbers) + 1;
  }

  const getEmptySection = (values) => {
    return {
      title: "",
      custom_text: "",
      sequence: getSequence(values.kth_request),
      image_id: ""
    };
  };

  useEffect(() => {
    if (imageFiles.currId && !isEmpty(imageFiles.files[imageFiles.currId])) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFiles((ps) => {
          return {
            ...ps,
            currId: null,
            files: {
              ...ps.files,
              [ps.currId]: {
                ...ps.files?.[ps.currId],
                preview: reader.result
              }
            }
          };
        });
      };
      reader.readAsDataURL(imageFiles.files[imageFiles.currId].file);
    }
  }, [imageFiles]);

  const validationSchema = Yup.object().shape({
    //TODO limit custom text to 255 characters
    kth_request: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Please enter a title"),
        custom_text: Yup.string().required("Please enter a text"),
        image_id: Yup.mixed().required("Please select an image")
      })
    )
  });

  function onSubmit(values) {
    if (!submitting) {
      setSubmitting(true);
      Promise.allSettled(
        values.kth_request.map((section) => {
          const imageFile = imageFiles.files[section.sequence];

          let imageId = "";
          const presignedUrlPayload = [
            {
              bucket_path: `${AD_BUCKET_PATH.entity}/${params.entityId}/${AD_BUCKET_PATH.know_the_entity}/${imageFile.file.name}`,
              image_name: imageFile.file.name
            }
          ];

          return getPresignedUrlPromise(presignedUrlPayload, section.sequence)
            .then((response) => {
              imageId = response?.res?.data?.data?.[0]?.image_id;
              return uploadImageUsingPresignedUrlPromise(
                response?.res?.data?.data?.[0]?.upload_url,
                imageFile.file,
                section.sequence
              );
            })
            .then((response) => {
              const sectionCopy = { ...section };
              sectionCopy.image_id = imageId;
              const payload = { kth_request: [sectionCopy] };
              return createKnowTheEntitySectionPromise(
                payload,
                params.entityId,
                section.sequence
              );
            });
        })
      ).then((res) => {
        setSubmitting(false);
        const uploadSectionSuccess = [];
        const uploadSectionFailure = [];

        for (const item of res) {
          if (item.status === "fulfilled") {
            uploadSectionSuccess.push(item.value);
          } else {
            uploadSectionFailure.push(item.reason);
          }
        }

        if (size(uploadSectionSuccess) === size(values.kth_request)) {
          dispatch(fetchKnowTheEntitySection(params.entityId));
          props.setCurrentMainComponent((prevState) => {
            return {
              currComponent:
                SECTION_KNOW_THE_HOST_COMPONENTS.list_know_the_host_sections,
              prevComponent: prevState.currComponent
            };
          });
        } else {
          //TODO Error handling
        }
      });
    } else {
      //TODO already submitting
    }
  }

  function handleSectionImageChange(event, id) {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setImageFiles((ps) => {
        return {
          ...ps,
          currId: id,
          files: {
            ...ps.files,
            [id]: { file: file }
          }
        };
      });
    }
  }

  function removeASection(index, id, remove) {
    remove(index);
    if (!isEmpty(imageFiles?.files?.[id])) {
      setImageFiles((ps) => {
        const files = ps.files;
        delete files[id];
        return {
          ...ps,
          files: {
            ...ps.files
          }
        };
      });
    }
    const { [index]: removedObj, ...rest } = imagesRef.current;
    imagesRef.current = rest;
  }

  function handleCancelBtnClick() {
    props.setCurrentMainComponent((prevState) => {
      return {
        currComponent:
          SECTION_KNOW_THE_HOST_COMPONENTS.list_know_the_host_sections,
        prevComponent: prevState.component
      };
    });
  }

  const checkForErrors = (errors, index, touched) => {
    const errorsObj = errors?.kth_request?.[index];
    const touchedObj = touched?.kth_request?.[index];

    if (isEmpty(errorsObj) || isEmpty(touchedObj)) return null;

    if (errorsObj.title && touchedObj.title) {
      return errorElement(errorsObj.title);
    } else if (errorsObj.custom_text && touchedObj.custom_text) {
      return errorElement(errorsObj.custom_text);
    } else if (errorsObj.image_id && touchedObj.image_id) {
      return errorElement(errorsObj.image_id);
    } else {
      return null;
    }

    function errorElement(msg) {
      return (
        <div className="d-flex align-items-center mt-3 pr12-lh18 error-color">
          <img
            className="mr-2"
            style={{ width: "16px", height: "16px" }}
            src={errorInfoIcon}
            alt=""
          />
          <span className="mb-0">{msg}</span>
        </div>
      );
    }
  };

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <CollectionHeaderPartner
              title="Add About Entity"
              loadingBtn={true}
              loadingBtnProps={{
                type: "submit",
                loading: submitting
              }}
              loadingBtnLabel="Save"
              cancelBtn={true}
              handleCancelBtnClick={handleCancelBtnClick}
            />
            <FieldArray name="kth_request">
              {(fieldArrayProps) => {
                const { push, form, remove } = fieldArrayProps;
                const { values, errors, touched } = form;
                const { kth_request } = values;
                return (
                  <div className="mt-3">
                    {kth_request.map((kthSection, index) => (
                      <div
                        className="d-flex p-3 mb-3"
                        style={{
                          border: "1px solid rgba(41, 41, 41, 0.38)",
                          borderRadius: "8px"
                        }}
                        key={index}
                      >
                        <div
                          className="secondary-color-bg"
                          style={{
                            width: "180px",
                            height: "180px",
                            borderRadius: "50%"
                          }}
                        >
                          <>
                            <Field name={`kth_request[${index}].image_id`}>
                              {(props) => {
                                const { field } = props;
                                const { onChange, ...rest } = field;
                                return (
                                  <input
                                    type="file"
                                    style={{ display: "none" }}
                                    ref={(element) =>
                                      (imagesRef.current[index] = element)
                                    }
                                    accept="image/png,image/jpg,image/jpeg"
                                    onChange={(event) => {
                                      onChange(event);
                                      handleSectionImageChange(
                                        event,
                                        kthSection.sequence
                                      );
                                    }}
                                    {...rest}
                                  />
                                );
                              }}
                            </Field>
                            {!isEmpty(
                              imageFiles?.files?.[kthSection.sequence]
                            ) &&
                            !isEmpty(
                              imageFiles?.files?.[kthSection.sequence]?.preview
                            ) ? (
                              <div className="h-100 position-relative">
                                <img
                                  src={
                                    imageFiles?.files?.[kthSection.sequence]
                                      ?.preview
                                  }
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    objectPosition: "center",
                                    borderRadius: "50%"
                                  }}
                                />
                                <div
                                  className="d-flex justify-content-center align-items-center pt-cursor"
                                  style={{
                                    position: "absolute",
                                    background: "rgba(0, 0, 0, 0.8)",
                                    bottom: "12px",
                                    right: "12px",
                                    width: "35px",
                                    height: "35px",
                                    borderRadius: "50%"
                                  }}
                                >
                                  <img
                                    className="edit-collection-thumbnail-icon"
                                    style={{
                                      width: "20px",
                                      height: "20px"
                                    }}
                                    src={editWhiteIcon}
                                    alt=""
                                    onClick={(event) => {
                                      event.preventDefault();
                                      imagesRef.current[index].click();
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                className="d-flex justify-content-center align-items-center flex-column h-100 pt-cursor"
                                onClick={(event) => {
                                  event.preventDefault();
                                  imagesRef.current[index].click();
                                }}
                              >
                                <img
                                  src={ImagePlaceholder}
                                  style={{
                                    width: "37px",
                                    height: "37px"
                                  }}
                                  alt=""
                                />
                                <p className="mb-0 pr16-lh24 high-emphasis-color mt-3">
                                  Add Image here
                                </p>
                              </div>
                            )}
                          </>
                        </div>
                        <div className="flex-1 ml-3">
                          <div>
                            <div className="mb-1 d-flex justify-content-between">
                              <Field name={`kth_request[${index}].title`}>
                                {(props) => {
                                  const { field } = props;
                                  return (
                                    <input
                                      className="pb18-lh32 high-emphasis-color"
                                      style={{
                                        outline: "none",
                                        border: "none"
                                      }}
                                      type="text"
                                      placeholder="Type Header here..."
                                      {...field}
                                      // errorMsg={meta.touched && meta.error ? meta.error : ""}
                                    />
                                  );
                                }}
                              </Field>
                              {size(kth_request) > 1 ? (
                                <img
                                  className="pt-cursor"
                                  src={SquareCloseIcon}
                                  alt=""
                                  onClick={() =>
                                    removeASection(
                                      index,
                                      kthSection.sequence,
                                      remove
                                    )
                                  }
                                />
                              ) : null}
                            </div>
                            <Field name={`kth_request[${index}].custom_text`}>
                              {(props) => {
                                const { field, form, meta } = props;
                                return (
                                  <div>
                                    <textarea
                                      placeholder="Type caption / body text here..."
                                      className="img-description-textarea pr16-lh24"
                                      style={{
                                        border: "none",
                                        resize: "none",
                                        height: "135px"
                                      }}
                                      {...field}
                                      // errorMsg={meta.touched && meta.error ? meta.error : ""}
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </div>
                          {checkForErrors(errors, index, touched)}
                        </div>
                      </div>
                    ))}
                    {/* <div className="mt-4 d-flex justify-content-center">
                      <ButtonAccent
                        variant="span-button"
                        startSpan="+"
                        startSpanClassName="icon-medium margin-right-8 vertical-align-bottom"
                        label="Add Section"
                        onClick={() => push(getEmptySection(values))}
                      />
                    </div> */}
                  </div>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </main>
  );
};

export default AddAboutEntity;
