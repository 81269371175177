import axios from 'axios'
import { getAuthToken, removeUserCredentials } from './utils'
import store from '../redux/store';
import config from '../config'

const {SERVER_URL} = config()

const protectedWebClient = axios.create({
    baseURL : `${SERVER_URL}/api`
})

const UNAUTHORIZED = 401;
const { dispatch } = store;

export const setupInterceptors = history => {

    protectedWebClient.interceptors.request.use(config => {
        config.headers = config.headers || {}
        config.headers['Authorization'] = `Bearer ${getAuthToken()}`
        return config
    })

    protectedWebClient.interceptors.response.use(res => {
        return res
    }, err => {
        const { status } = err.response
  
        if (status === UNAUTHORIZED) {
            removeUserCredentials()
            dispatch({
                type : "USER_LOGOUT"
            })
            // here we have access of the useHistory() from current Router
            history.push('/')
        }
        return Promise.reject(err)
    })
}

export default protectedWebClient