import {
  CLEAR_ATTENDANCE_FOR_SINGLE_MEMBER,
  FETCH_ATTENDANCE_SUMMARY_FOR_SINGLE_MEMBER_FAILURE,
  FETCH_ATTENDANCE_SUMMARY_FOR_SINGLE_MEMBER_SUCCESS,
  REQUEST_FETCH_ATTENDANCE_SUMMARY_FOR_SINGLE_MEMBER
} from "./attendanceSummaryForSingleMember.types";

let initialState = {
  reload: false,
  response: {},
  error: null,
  loading: false
};

const attendanceForSingleMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FETCH_ATTENDANCE_SUMMARY_FOR_SINGLE_MEMBER:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_ATTENDANCE_SUMMARY_FOR_SINGLE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        reload: false
      };

    case FETCH_ATTENDANCE_SUMMARY_FOR_SINGLE_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reload: false
      };

    case CLEAR_ATTENDANCE_FOR_SINGLE_MEMBER:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
        reload: false
      };

    default:
      return state;
  }
};

export default attendanceForSingleMemberReducer;
