import { REQUEST_ACCESS_GROUP_FETCH_EM } from "../group-for-auth/authGroup.types";
import {
  CLEAR_FETCH_ACCESS_GROUP_USER_EM_STATES,
  FETCH_ACCESS_GROUP_USER_EM_FAILURE,
  FETCH_ACCESS_GROUP_USER_EM_SUCCESS,
  REQUEST_ACCESS_GROUP_USER_FETCH_EM,
} from "./groupUsers.types";

export const requestFetchAccessGroupUserEm = () => {
  return {
    type: REQUEST_ACCESS_GROUP_USER_FETCH_EM,
  };
};

export const fetchAccessGroupUserEmSuccess = (response) => {
  return {
    type: FETCH_ACCESS_GROUP_USER_EM_SUCCESS,
    payload: response,
  };
};

export const fetchAccessGroupUserEmFailure = (error) => {
  return {
    type: FETCH_ACCESS_GROUP_USER_EM_FAILURE,
    payload: error,
  };
};

export const clearFetchAccessGroupUserEmStates = () => {
  return {
    type: CLEAR_FETCH_ACCESS_GROUP_USER_EM_STATES,
  };
};
