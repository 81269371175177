import {
  CLEAR_FETCH_ACCESS_GROUP_EM_STATES,
  FETCH_ACCESS_GROUP_EM_FAILURE,
  FETCH_ACCESS_GROUP_EM_SUCCESS,
  REQUEST_ACCESS_GROUP_FETCH_EM,
} from "./authGroup.types";

export const requestFetchAccessGroupEm = () => {
  return {
    type: REQUEST_ACCESS_GROUP_FETCH_EM,
  };
};

export const fetchAccessGroupEmSuccess = (response) => {
  return {
    type: FETCH_ACCESS_GROUP_EM_SUCCESS,
    payload: response,
  };
};

export const fetchAccessGroupEmFailure = (error) => {
  return {
    type: FETCH_ACCESS_GROUP_EM_FAILURE,
    payload: error,
  };
};

export const clearFetchAccessGroupEmStates = () => {
  return {
    type: CLEAR_FETCH_ACCESS_GROUP_EM_STATES,
  };
};
