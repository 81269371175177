import {
  CLEAR_ATTENDANCE_SUMMARY_MEMBER,
  REQUEST_FETCH_ATTENDANCE_SUMMARY_MEMBER,
  REQUEST_FETCH_ATTENDANCE_SUMMARY_MEMBER_FAILED,
  REQUEST_FETCH_ATTENDANCE_SUMMARY_MEMBER_SUCCESS,
} from "./attendanceMember.types";

export const requestFetchAttendanceSummaryMember = () => {
  return {
    type: REQUEST_FETCH_ATTENDANCE_SUMMARY_MEMBER,
  };
};

export const fetchAttendanceSummaryMemberSuccess = (response) => {
  return {
    type: REQUEST_FETCH_ATTENDANCE_SUMMARY_MEMBER_SUCCESS,
    payload: response,
  };
};

export const fetchAttendanceSummaryMemberFailure = (error) => {
  return {
    type: REQUEST_FETCH_ATTENDANCE_SUMMARY_MEMBER_FAILED,
    payload: error,
  };
};

export const clearAttendanceSummaryMember = () => {
  return {
    type: CLEAR_ATTENDANCE_SUMMARY_MEMBER,
  };
};
