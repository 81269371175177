import { Box, Chip, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  getStatusbgColor,
  getStatusColor,
} from "../../../pages/attendance-record/AttendanceRecord";
import { getAttendanceSummaryByMember } from "../../../services/attendanceMember.service";
import {
  getLocalDateString,
  getLocalTimeString,
} from "../../../utils/constant";
import { getUserId } from "../../../utils/utils";
import BreadCrumbs from "../../common/box/bread-crumbs/BreadCrumbs";
import ButtonAccent from "../../common/button-accent/ButtonAccent";

const UserMeetingHistory = (props) => {
  const [meetingHistory, setMeetingHistory] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const collectionId = props.collectionId;

  const attendanceSummarySingleMemberState = useSelector(
    (state) => state.attendanceForSingleMemberEm
  );

  const attendanceSummarySingleMemberData =
    attendanceSummarySingleMemberState?.response?.data;

  useEffect(() => {
    if (
      !attendanceSummarySingleMemberState?.loading &&
      isEmpty(attendanceSummarySingleMemberState?.response)
    ) {
      dispatch(getAttendanceSummaryByMember(collectionId, getUserId()));
    }
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Sl.no",
      width: 90,
      renderCell: (params) =>
        params.api.getSortedRowIds().indexOf(params?.row.id ?? "") + 1,
    },
    {
      headerName: "Meeting Date",
      width: 260,
      renderCell: (params) => {
        if (params?.row !== "") {
          return getLocalDateString(params?.row?.meeting_date);
        } else {
          return null;
        }
      },
    },
    {
      field: "venue",
      headerName: "Meeting Venue",
      width: 260,
      renderCell: (params) => (
        <Typography sx={{ p: 2 }}>{params?.row.meeting_venue}</Typography>
      ),
    },
    {
      field: "entry_time",
      headerName: "Attendance Time",
      width: 260,
      editable: true,
      renderCell: (params) => {
        return getLocalTimeString(params?.row.attendance_time);
      },
    },
    {
      field: "attendance_status",
      headerName: "Attendance Status",
      sortable: true,
      width: 260,
      renderCell: (params) => {
        let status = params.formattedValue;
        return (
          <>
            <Chip
              label={status}
              style={{ margin: "0.8rem" }}
              sx={{
                backgroundColor: `${getStatusbgColor(status)}`,
                color: `${getStatusColor(status)}`,
                fontWeight: "bold",
                borderRadius: "16px",
                "& .MuiChip-label": {
                  padding: "0 12px",
                },
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <Box className="page-content">
      {meetingHistory && (
        <Box>
          <Box sx={{ mt: 8, mb: 4 }}>
            <BreadCrumbs
              title="Meetings"
              subTitle="Attendance"
              titleLink={`${location.pathname}?selected=settings`}
            />
            <Paper>
              <DataGrid
                sx={{
                  "& ::-webkit-scrollbar": {
                    width: "20px",
                  },
                  "& ::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    borderRadius: "2px",
                    backgroundColor: "#ffffff",
                  },
                  marginTop: 4,
                }}
                rows={attendanceSummarySingleMemberData}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
              />
            </Paper>
          </Box>
        </Box>
      )}
      {!meetingHistory && (
        <>
          <div className="py-5">
            <h2 style={{ backgroundColor: "" }}>Meeting History</h2>
          </div>
          <Grid
            container
            spacing={6}
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 10px",
              gap: "10px",
            }}
          >
            <Grid item xs={12} sm={4}>
              <ButtonAccent
                onClick={() => setMeetingHistory(true)}
                label="Attendance"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ButtonAccent label="Payment" />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default UserMeetingHistory;
