import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Masonry from "react-masonry-css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import backArrow from "../../../assets/icon/back_arrow.svg";

import {
  getAllGallery,
  getImagesOfGalleryFolder,
  getImagesOfGalleryFolderForUser,
} from "../../../services/gallery.service.js";

import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";

import CustomSpinner from "../../../components/common/spinner/CustomSpinner";
import Image from "../../../components/common/v2/image/Image";

const StyledTabs = styled((props) => <Tabs {...props} />)({
  // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)',
  // backgroundColor: 'var(--secondary-color)',
  "& .MuiTabs-indicator": {
    backgroundColor: "var(--high-emphasis)",
    height: "2.5px",
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: 400,
  fontFamily: '"Poppins" , sans-serif',
  lineHeight: "32px",
  fontSize: "18px",
  color: "var(--medium-emphasis)",
  marginRight: theme.spacing(1),
  // '&:hover': {
  //     color: 'var(--high-emphasis)',
  //     fontWeight: 600,
  // },
  "&.Mui-selected": {
    color: "var(--high-emphasis)",
    fontWeight: 600,
  },
  // '&.Mui-focusVisible': {
  //     backgroundColor: '#d1eaff',
  // },
}));

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // style={{minHeight: 'calc(100vh - 64px - 56px - 40px)'}}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Columns = {
  default: 4,
  1140: 3,
  700: 2,
  360: 1,
};

const PhotoGallery = (props) => {
  const { myPhotosOnly } = props;
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [currGalleryFolderInfo, setCurrGalleryFolderInfo] = useState({});

  const galleryState = useSelector((state) => state.galleryEm);
  const galleryFoldersState = useSelector((state) => state.galleryFoldersEm);
  const myPhotosGalleryFoldersState = useSelector(
    (state) => state.myPhotosGalleryFoldersEm
  );
  const dispatch = useDispatch();

  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const history = useHistory();
  const params = useParams();

  const galleryData = galleryState.response?.data;
  const currGalleryFolderData = myPhotosOnly
    ? myPhotosGalleryFoldersState?.response?.[currGalleryFolderInfo?.id]?.data
    : galleryFoldersState.response?.[currGalleryFolderInfo?.id]?.data;

  useEffect(() => {
    if (isEmpty(galleryState.response)) {
      dispatch(getAllGallery(props.collectionId));
    }
  }, []);

  useEffect(() => {
    if (!galleryState.loading && !isEmpty(galleryState.response)) {
      setLoading(false);
    }
    if (!galleryState.loading && !isEmpty(galleryState.error)) {
      setLoading(false);
      toast.error(galleryState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [galleryState]);

  useEffect(() => {
    if (!isEmpty(galleryData)) {
      setCurrGalleryFolderInfo((ps) => {
        return galleryData[value];
      });
    }
  }, [value, galleryState]);

  useEffect(() => {
    if (!isEmpty(currGalleryFolderInfo)) {
      if (
        !myPhotosOnly &&
        isEmpty(galleryFoldersState.response?.[currGalleryFolderInfo?.id])
      ) {
        if (!imageLoading) setImageLoading(true);
        dispatch(
          getImagesOfGalleryFolder(props.collectionId, currGalleryFolderInfo.id)
        );
      }
    }
  }, [currGalleryFolderInfo, myPhotosOnly]);

  useEffect(() => {
    if (!isEmpty(currGalleryFolderInfo)) {
      if (
        myPhotosOnly &&
        isEmpty(
          myPhotosGalleryFoldersState?.response?.[currGalleryFolderInfo?.id]
        )
      ) {
        if (!imageLoading) setImageLoading(true);
        dispatch(
          getImagesOfGalleryFolderForUser(
            props.collectionId,
            currGalleryFolderInfo.id
          )
        );
      }
    }
  }, [currGalleryFolderInfo, myPhotosOnly]);

  useEffect(() => {
    if (
      !myPhotosOnly &&
      !galleryFoldersState.loading &&
      !isEmpty(galleryFoldersState.response?.[currGalleryFolderInfo.id])
    ) {
      setImageLoading(false);
    }

    if (
      !myPhotosOnly &&
      !galleryFoldersState.loading &&
      !isEmpty(galleryFoldersState.error)
    ) {
      setImageLoading(false);
      toast.error(
        galleryFoldersState.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [myPhotosOnly, galleryFoldersState, currGalleryFolderInfo]);

  useEffect(() => {
    if (
      myPhotosOnly &&
      !myPhotosGalleryFoldersState?.loading &&
      !isEmpty(
        myPhotosGalleryFoldersState?.response?.[currGalleryFolderInfo.id]
      )
    ) {
      setImageLoading(false);
    }

    if (
      myPhotosOnly &&
      !myPhotosGalleryFoldersState?.loading &&
      !isEmpty(myPhotosGalleryFoldersState?.error)
    ) {
      setImageLoading(false);
      toast.error(
        myPhotosGalleryFoldersState?.error?.data?.error_data ||
          STD_ERROR_MESSAGE
      );
    }
  }, [myPhotosOnly, myPhotosGalleryFoldersState, currGalleryFolderInfo]);

  function handleTabs(event, key) {
    setValue(key);
    if (window.pageYOffset) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  return (
    <div
      style={{
        minHeight: "calc(100vh - 64px - 40px)",
      }}
    >
      {!loading ? (
        !isEmpty(galleryData) ? (
          <>
            <Box
              sx={{
                width: "100%",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
                backgroundColor: "var(--secondary-color)",
                position: "sticky",
                zIndex: 10,
                ...(isSmallScreen ? { top: "56px" } : { top: "64px" }),
              }}
            >
              <div className="d-flex">
                <div className="d-flex align-items-center ml-1">
                  <img
                    className="pt-cursor"
                    src={backArrow}
                    alt=""
                    style={{ width: "20px" }}
                    onClick={props.onBackBtnClick}
                  />
                  <p className="pl-2 mb-0 pr16-lh24" style={{ width: "72px" }}>
                    Gallery
                  </p>
                  <span className="ml-2 pb-2 line">|</span>
                </div>
                <StyledTabs
                  value={value}
                  onChange={handleTabs}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  allowScrollButtonsMobile
                >
                  {galleryData.map((folder, index) => (
                    <StyledTab
                      key={folder.id}
                      label={folder.title}
                      {...a11yProps(index)}
                    />
                  ))}
                </StyledTabs>
              </div>
            </Box>
            {galleryData.map((folder, index) => (
              <TabPanel key={folder.id} value={value} index={index}>
                {!imageLoading ? (
                  !isEmpty(currGalleryFolderData) ? (
                    <Masonry
                      breakpointCols={Columns}
                      className="masonry-grid"
                      columnClassName="masonry-grid_column"
                    >
                      {currGalleryFolderData.map((galleryFolderItem, index) => (
                        <LazyLoadComponent key={galleryFolderItem.id}>
                          <div style={{ paddingBottom: "10px" }}>
                            <Image
                              variant="withHover"
                              containerCustomStyle={{ height: "unset" }}
                              src={getImageUrl(
                                galleryFolderItem?.image?.bucket_path
                              )}
                              alt=""
                              onImageClick={() =>
                                history.push(
                                  `/collection/${props.collectionId}/folder/${currGalleryFolderInfo?.id}/image-slide?myPhotosOnly=${myPhotosOnly}&selected=${index}`
                                )
                              }
                              enableLikeIcon={true}
                              onLikeIconClick={
                                () => {}
                                // console.log("like clicked")
                              }
                            />
                          </div>
                        </LazyLoadComponent>
                      ))}
                    </Masonry>
                  ) : (
                    <h4 className="text-center pr20-lh32 high-emphasis-color pt-5">
                      You have no photos to view
                    </h4>
                  )
                ) : (
                  <CustomSpinner />
                )}
              </TabPanel>
            ))}
          </>
        ) : (
          <>
            <Box
              sx={{
                width: "100%",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
                backgroundColor: "var(--secondary-color)",
                position: "sticky",
                zIndex: 10,
                ...(isSmallScreen ? { top: "56px" } : { top: "64px" }),
              }}
            >
              <div className="d-flex">
                <div className="d-flex align-items-center ml-1">
                  <img
                    className="pt-cursor"
                    src={backArrow}
                    alt=""
                    style={{ width: "20px" }}
                    onClick={props.onBackBtnClick}
                  />
                  <p className="pl-2 mb-0 pr16-lh24" style={{ width: "72px" }}>
                    Gallery
                  </p>
                </div>
              </div>
            </Box>
            <h4 className="text-center pr20-lh32 high-emphasis-color pt-5">
              You have no folders
            </h4>
          </>
        )
      ) : (
        <CustomSpinner />
      )}
    </div>
  );
};

export default PhotoGallery;
