import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik, useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import cancelIcon from "../../../assets/icon/cancel.svg";

import SingleInputPopUp from "../../common/single-input-pop-up/SingleInputPopUp";
import {
  updateAEntityGalleryFolderPromise,
  getAllEntityGallery,
} from "../../../services/gallery.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import PopUp from "../../common/pop-up/PopUp";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import Select from "react-select";
import InputBox from "../../common/input-box/InputBox";
import {
  addOrUpdateGalleryAccess,
  getAccessGroupForGallery,
  getAllAccessGroups,
} from "../../../services/group-for-auth.service";
import { clearGroupAccessMappingEmStates } from "../../../redux/group-access-mapping/groupAccessMapping.action";

const EditEntityFolder = (props) => {
  const [groupAccessLoading, setGroupAccessLoading] = useState(true);
  const [groupAccessMappingLoading, setGroupAccessMappingLoading] =
    useState(true);

  const folderInfo = props.folderInfo;
  const dispatch = useDispatch();

  const params = useParams();

  const initialValues = {
    title: folderInfo?.title,
  };

  const groupAccessState = useSelector((state) => state.accessGroupAd);
  const groupAccessData = groupAccessState?.response?.data;

  const groupAccessMappingState = useSelector(
    (state) => state.groupAccessMappingEm
  );
  const groupAccessMappingData = groupAccessMappingState?.response?.data;

  useEffect(() => {
    if (!groupAccessState?.loading && !isEmpty(groupAccessState?.response)) {
      setGroupAccessLoading(false);
    }
    if (!groupAccessState?.loading && !isEmpty(groupAccessState?.error)) {
      setGroupAccessLoading(false);
      toast.error(STD_ERROR_MESSAGE);
    }
  }, [groupAccessState]);

  useEffect(() => {
    if (isEmpty(groupAccessState?.response)) {
      dispatch(getAllAccessGroups(params.entityId));
    }
  }, []);

  useEffect(() => {
    if (
      !groupAccessMappingState?.loading &&
      !isEmpty(groupAccessMappingState?.response)
    ) {
      setGroupAccessMappingLoading(false);
      const galleryAccessGroups = groupAccessMappingData.map((mapping) => {
        return {
          value: mapping.group_id,
          label: options.find((o) => o.value == mapping.group_id).label,
        };
      });
      setSelectedGroups(galleryAccessGroups);
    }
    if (
      !groupAccessMappingState?.loading &&
      !isEmpty(groupAccessMappingState?.error)
    ) {
      setGroupAccessMappingLoading(false);
      toast.error(STD_ERROR_MESSAGE);
    }
  }, [groupAccessMappingState]);

  useEffect(() => {
    if (isEmpty(groupAccessMappingState?.response)) {
      dispatch(getAccessGroupForGallery(params.entityId, folderInfo.id));
    }

    return () => {
      dispatch(clearGroupAccessMappingEmStates());
    };
  }, []);

  useEffect(() => {
    if (!groupAccessLoading && !groupAccessMappingLoading) {
      const galleryAccessGroups = groupAccessMappingData.map((mapping) => {
        return {
          value: mapping.group_id,
          label: options.find((o) => o.value == mapping.group_id).label,
        };
      });
      setSelectedGroups(galleryAccessGroups);
    }
  }, [groupAccessLoading, groupAccessMappingLoading]);

  const options = groupAccessData?.map((group) => {
    return {
      value: group.id,
      label: group.group_name,
    };
  });

  const [selectedGroups, setSelectedGroups] = useState([]);

  function handleChange(selectedGroups) {
    setSelectedGroups(selectedGroups || []);
  }

  const validationSchema = Yup.object({
    title: Yup.string().required("Please enter the folder name"),
  });

  const onSubmit = (values) => {
    updateAEntityGalleryFolderPromise(params.entityId, folderInfo.id, values)
      .then((res) => {
        const payload = [];
        for (const value of selectedGroups) {
          console.log("values:" + value);
          payload.push({ gallery_id: folderInfo.id, group_id: value.value });
        }
        addOrUpdateGalleryAccess(params.entityId, folderInfo.id, payload)
          .then((res) => {
            dispatch(getAllEntityGallery(params.entityId));
            props.handleClose();
          })
          .catch((err) => {
            toast.error(err?.error_data || STD_ERROR_MESSAGE);
          });
      })
      .catch((err) => {
        toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
      });
  };

  return (
    <PopUp overlayClassName={props.overlayClassName}>
      <img
        className="close-icon pt-cursor"
        onClick={props.handleClose}
        src={cancelIcon}
        alt=""
      ></img>
      <h1 className="pb18-lh32 text-center mb-3">Edit Folder</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <Field name="title">
              {(props) => {
                const { field, meta } = props;
                return (
                  <>
                    <InputBox
                      type="text"
                      placeholder="Enter Folder Name"
                      {...field}
                      errorMsg={meta.touched && meta.error ? meta.error : ""}
                    />
                  </>
                );
              }}
            </Field>
            <Select
              options={options}
              placeholder="Select groups..."
              isMulti
              className="mb-3"
              onChange={handleChange}
              value={selectedGroups}
            />
            <ButtonAccent
              type="submit"
              label="Edit Folder"
              className="pb14-lh21 w-100"
            />
          </Form>
        )}
      </Formik>
    </PopUp>
  );
};

export default EditEntityFolder;
