import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../../components/layout/navbar/Navbar";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { clearFetchTestimonialAdStates } from "../../../redux/testimonials/testimonial.action";
import ContentArea from "../../../components/layout/content-area/ContentArea";

import { clearFetchKnowTheHostSectionsEmStates } from "../../../redux/know-the-host/knowTheHost.action";
import { ENTITY_FOLDERS, STD_ERROR_MESSAGE } from "../../../utils/constant.js";
import SectionEntityProfile from "../../../components/layout/entity-sections/section-entity-profile/SectionEntityProfile";
import SectionEntityLiveFeed from "../../../components/layout/entity-sections/section-live-feed/SectionEntityLiveFeed";
import SectionEntityGallery from "../../../components/layout/entity-sections/section-entity-gallery/SectionEntityGallery";
import { clearFetchGalleryFoldersEmStates } from "../../../redux/gallery-folders/galleryFolders.action";
import { clearFetchGalleryEmStates } from "../../../redux/gallery/gallery.action";
import { clearFetchLiveFeedsEmStates } from "../../../redux/live-feed/liveFeed.action";
import { getAllEntityGallery } from "../../../services/gallery.service";
import AdminSidebar from "../../../components/layout/admin-sidebar/AdminSidebar";
import { clearFetchEntityAdStates } from "../../../redux/entity/entity.action";
import { getAnEntity } from "../../../services/entities.service";
import SectionKnowTheEntity from "../../../components/layout/entity-sections/section-know-the-entity/SectionKnowTheEntity";
import SectionTestimonial from "../../../components/layout/entity-sections/section-testimonial/SectionTestimonial";
import { clearFetchPermalinkAdStates } from "../../../redux/permalink/permalink.action.js";
import SectionPermalink from "../../../components/layout/entity-sections/section-permalink/SectionPermalink";
import { clearFetchentityProfileAdState } from "../../../redux/entity-location/entity.location.action.js";
import SectionGroups from "../../../components/layout/entity-sections/section-groups/SectionGroups.js";

const Entity = (props) => {
  const [loading, setLoading] = useState({ gallery: true, entity: true });
  const [selected, setSelected] = useState({
    folder: ENTITY_FOLDERS.know_the_entity,
    subFolder: null,
  });

  const params = useParams();

  const entityState = useSelector((state) => state.entityAd);
  const galleryState = useSelector((state) => state.galleryEm);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(entityState.response)) {
      dispatch(getAnEntity(params?.entityId));
    }
    if (isEmpty(galleryState.response)) {
      dispatch(getAllEntityGallery(params?.entityId));
    }
    return () => {
      dispatch(clearFetchEntityAdStates());
      dispatch(clearFetchentityProfileAdState());
      dispatch(clearFetchKnowTheHostSectionsEmStates());
      dispatch(clearFetchGalleryEmStates());
      dispatch(clearFetchGalleryFoldersEmStates());
      dispatch(clearFetchLiveFeedsEmStates());
      dispatch(clearFetchTestimonialAdStates());
      dispatch(clearFetchPermalinkAdStates());
    };
  }, []);

  useEffect(() => {
    if (!entityState.loading && !isEmpty(entityState.response)) {
      setLoading((ps) => {
        return {
          ...ps,
          entity: false,
        };
      });
    }

    if (!entityState.loading && !isEmpty(entityState.error)) {
      setLoading((ps) => {
        return {
          ...ps,
          entity: false,
        };
      });
      toast.error(entityState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [entityState]);

  useEffect(() => {
    if (!galleryState.loading && !isEmpty(galleryState.response)) {
      setLoading((ps) => {
        return {
          ...ps,
          gallery: false,
        };
      });
    }

    if (!galleryState.loading && !isEmpty(galleryState.error)) {
      setLoading((ps) => {
        return {
          ...ps,
          gallery: false,
        };
      });
      toast.error(galleryState.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [galleryState]);

  function getMainComponent() {
    switch (selected.folder) {
      case ENTITY_FOLDERS.group:
        return <SectionGroups setSelected={setSelected} />;
      case ENTITY_FOLDERS.profile:
        return <SectionEntityProfile setSelected={setSelected} />;
      case ENTITY_FOLDERS.know_the_entity:
        return <SectionKnowTheEntity setSelected={setSelected} />;
      case ENTITY_FOLDERS.live_feed:
        return <SectionEntityLiveFeed setSelected={setSelected} />;
      case ENTITY_FOLDERS.testimonial:
        return <SectionTestimonial setSelected={setSelected} />;
      case ENTITY_FOLDERS.permalink:
        return <SectionPermalink setSelected={setSelected} />;
      case ENTITY_FOLDERS.photos_gallery:
        return (
          <SectionEntityGallery selected={selected} setSelected={setSelected} />
        );
      default:
        return null;
    }
  }

  return (
    <>
      <Navbar />
      <ContentArea>
        <Grid container sx={{ height: "100%" }}>
          {/* sidebar */}
          <Grid
            item
            sx={{
              height: "100%",
              display: {
                xs: "none",
                sm: "inherit",
              },
            }}
            sm={4}
            md={3}
            lg={3}
            xl={3}
          >
            <AdminSidebar
              loading={loading}
              selected={selected}
              setSelected={setSelected}
            />
          </Grid>
          {/* main */}
          <Grid
            item
            sx={{
              height: "100%",
              padding: "1.5rem 2.5rem",
              overflow: "auto",
            }}
            xs={12}
            sm={8}
            md={9}
            lg={9}
            xl={9}
          >
            {getMainComponent()}
          </Grid>
        </Grid>
      </ContentArea>
    </>
  );
};

export default Entity;
