import "./infoscreen.css";

const PartnerSignin = () => {
  return (
    <div className="container">
      <h5 className="heading">Partner Sign In</h5>
      <p className="pr16-lh24">
        Business Partner Sign In :{" "}
        <a
          href="https://www.trueco.biz/business-partner/sign-in"
          rel="noreferrer"
        >
          Click here
        </a>{" "}
      </p>
      <p className="pr16-lh24">
        Community Partner Sign In :{" "}
        <a
          href="https://www.trueco.biz/community-partner/sign-in"
          rel="noreferrer"
        >
          Click here
        </a>
      </p>
    </div>
  );
};

export default PartnerSignin;
