import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import { size } from "lodash";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";

import { LazyLoadComponent } from "react-lazy-load-image-component";
import Image from "../../common/v2/image/Image";
import { getImageUrl } from "../../../utils/utils";
import DeletePopup from "../delete-popup/DeletePopup";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import editIcon from "../../../assets/icon/edit.svg";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import ButtonPrimary from "../../common/button-primary/ButtonPrimary";

import errorInfoIcon from "../../../assets/icon/info-red.svg";
import { getAnEntity, updateEntity } from "../../../services/entities.service";
import {
  deleteLocationImageBySequencePromise,
  getAllLocationInEntity,
} from "../../../services/entity.location.service";

const LocationList = (props) => {
  const entityProfileState = useSelector((state) => state.entityProfileAd);
  const entityState = useSelector((state) => state.entityAd);
  const [deletePopup, setDeletePopup] = useState({ open: false, id: null });
  const [descriptionEdit, setDescriptionEdit] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams();

  const entityProfileData = entityProfileState.response?.data;
  const entityData = entityState.response?.data;

  const initialValues = {
    entity_title: entityData?.entity_title ? entityData?.entity_title : "",
  };

  const validationSchema = Yup.object().shape({
    entity_title: Yup.string().required("Please enter caption/body text"),
  });

  useEffect(() => {
    if (!entityData?.entity_title) {
      setDescriptionEdit(true);
    }
  }, []);

  function onSubmit(values) {
    updateEntity(values, entityData.id)
      .then((res) => {
        dispatch(getAnEntity(entityData.id));
        setDescriptionEdit(false);
      })
      .catch((err) => {
        toast.error(err?.error_data || STD_ERROR_MESSAGE);
      });
  }

  function handleDeleteImageEvent() {
    deleteLocationImageBySequencePromise(params.entityId, deletePopup.id)
      .then((res) => {
        dispatch(getAllLocationInEntity(params.entityId));
        closeDeletePopup();
      })
      .catch((err) => {
        toast.error(err?.error_data || STD_ERROR_MESSAGE);
      });
  }

  function closeDeletePopup() {
    setDeletePopup({ open: false, id: null });
  }

  function openDeletePopup(id) {
    setDeletePopup({ open: true, id: id });
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title={`Carousel(${size(entityProfileData)})`}
        backToCollectionsBtn={true}
        addPhotosBtn={true}
        addPhotosLabel="Add Carousel Image"
        handleAddPhotosBtn={props.onLocationButtonClick}
        handleBackToCollectionsBtn={() => history.goBack()}
      />
      <div style={{ marginTop: "27px" }}>
        <div
          className="p-3 mb-3"
          style={{
            border: "1px solid rgba(41, 41, 41, 0.38)",
            borderRadius: "8px",
          }}
        >
          {descriptionEdit ? (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(formik) => (
                <Form>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3 className="pb18-lh32 high-emphasis-color mb-0">
                      Type Home Screen Content here...
                    </h3>
                    <div>
                      {entityData?.invitation_title && (
                        <ButtonPrimary
                          label="Cancel"
                          onClick={() => setDescriptionEdit(false)}
                          className="mr-3"
                        />
                      )}
                      <ButtonAccent label="Save" type="submit" />
                    </div>
                  </div>
                  <Field name="entity_title">
                    {(props) => {
                      const { field, form, meta } = props;
                      return (
                        <div className="mt-3">
                          <textarea
                            placeholder="Type caption / body text here..."
                            className="img-description-textarea pr16-lh24"
                            style={{
                              border: "none",
                              resize: "none",
                              height: "135px",
                            }}
                            {...field}
                            // errorMsg={meta.touched && meta.error ? meta.error : ""}
                          />
                          {meta.touched && meta.error ? (
                            <div className="d-flex align-items-center justify-content-start mt-2 mb-2">
                              <img src={errorInfoIcon} alt="" />
                              <span className="margin-left-5 pr10-lh15 error-color">
                                {meta.error}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      );
                    }}
                  </Field>
                </Form>
              )}
            </Formik>
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="pb18-lh32 high-emphasis-color mb-0">
                  Home Screen Content
                </h3>
                <img
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                  }}
                  src={editIcon}
                  alt=""
                  className="ml-2 pt-cursor"
                  onClick={() => setDescriptionEdit(true)}
                />
              </div>
              <p className="pr16-lh24 mt-3 mb-0">{entityData?.entity_title}</p>
            </>
          )}
        </div>
        {size(entityProfileData) ? (
          <Grid container spacing={2}>
            {entityProfileData.map((location, index) => (
              <Grid item sm={12} md={6} lg={6} xl={6} key={index}>
                <LazyLoadComponent>
                  <Image
                    variant="withHover"
                    enableDeleteIcon={true}
                    enableShareIcon={true}
                    src={getImageUrl(location.image.bucket_path)}
                    alt=""
                    onDeleteIconClick={() => openDeletePopup(location.sequence)}
                    // onShareIconClick={() => locationGuest(location)}
                  />
                </LazyLoadComponent>
              </Grid>
            ))}
          </Grid>
        ) : (
          <h2 className="text-center pr20-lh32 high-emphasis-color mt-5">
            You have no locations in this collection
          </h2>
        )}
      </div>
      {deletePopup.open && (
        <DeletePopup
          heading="DELETE IMAGE"
          text="The image will be deleted permanently. This cannot be undone."
          handleDelete={handleDeleteImageEvent}
          handleClose={closeDeletePopup}
        />
      )}
    </main>
  );
};

export default LocationList;
