import { Grid, Menu, MenuItem, Stack } from "@mui/material";
import DeleteIcon from "../../../assets/icon/delete.svg";
import EditIcon from "../../../assets/icon/edit.svg";
import MoreIcon from "../../../assets/icon/horizontal_more.svg";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import { useEffect, useState } from "react";
import AddGroupUsers from "../add-group-users/AddGroupUsers";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import DeleteGroupPopup from "../delete-popup/DeletePopup";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import {
  deleteGroupById,
  getAllAccessGroups,
} from "../../../services/group-for-auth.service";

const AccessGroups = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [deleteGroupPopup, setDeleteGroupPopup] = useState({
    open: false,
    id: null,
  });
  const open = Boolean(anchorEl);
  const params = useParams();
  const dispatch = useDispatch();
  const [groupUsersPopup, setGroupUsersPopup] = useState(false);
  const groupAccessState = useSelector((state) => state.accessGroupAd);
  const groupAccessData = groupAccessState?.response?.data;
  const [editItem, setEditItem] = useState({});

  useEffect(() => {
    if (!groupAccessState?.loading && !isEmpty(groupAccessState?.response)) {
      setPageLoading(false);
    }
    if (!groupAccessState?.loading && !isEmpty(groupAccessState?.error)) {
      setPageLoading(false);
      toast.error(STD_ERROR_MESSAGE);
    }
  }, [groupAccessState]);

  useEffect(() => {
    if (isEmpty(groupAccessState?.response)) {
      dispatch(getAllAccessGroups(params.entityId));
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleGroupDeleteClick(id) {
    setDeleteGroupPopup({
      open: true,
      id,
    });
  }

  function handleDeleteSubGroupEntity() {
    deleteGroupById(params.entityId, deleteGroupPopup.id)
      .then((res) => {
        dispatch(getAllAccessGroups(params.entityId));
        closeDeletePopup();
      })
      .catch((err) => {
        toast.error(err?.data?.error_data || STD_ERROR_MESSAGE);
      });
  }

  function closeDeletePopup() {
    setDeleteGroupPopup({
      open: false,
      id: null,
    });
  }

  return (
    <>
      {!pageLoading ? (
        <>
          <CollectionHeaderPartner title="Group" />
          <Grid container spacing={4}>
            <Grid item xs={4} md={4} lg={2}>
              {groupAccessData?.map((p) => (
                <>
                  <div
                    key={p.id}
                    className="mt-4"
                    style={{
                      border: "2px solid var(--low-emphasis)",
                      borderRadius: "8px",
                      backgroundColor: "var(--secondary-color)",
                    }}
                  >
                    <div className="px-3 py-1">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="align-items-center">{p.group_name}</div>
                        <div className="d-flex justify-content-between align-items-center">
                          <img
                            onClick={() => {
                              setEditItem((ps) => {
                                return {
                                  ...ps,
                                  id: p.id,
                                  name: p.group_name,
                                };
                              });
                              setGroupUsersPopup(true);
                            }}
                            style={{
                              width: "14px",
                              height: "14px",
                            }}
                            alt=""
                            src={EditIcon}
                          />
                          <img
                            onClick={() => {
                              handleGroupDeleteClick(p.id);
                            }}
                            style={{
                              width: "14px",
                              height: "14px",
                            }}
                            alt=""
                            src={DeleteIcon}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </Grid>
          </Grid>
          {groupUsersPopup && (
            <AddGroupUsers
              onCancel={() => setGroupUsersPopup(false)}
              editItem={editItem}
            />
          )}
          {deleteGroupPopup.open && (
            <DeleteGroupPopup
              heading="DELETE GROUP"
              text="This Group will be deleted permanently. This cannot be undone."
              handleDelete={handleDeleteSubGroupEntity}
              handleClose={closeDeletePopup}
            />
          )}
        </>
      ) : null}
    </>
  );
};
export default AccessGroups;
