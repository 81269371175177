import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import SingleInputPopUp from "../../common/single-input-pop-up/SingleInputPopUp";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useState } from "react";
import {
  createAccessGroupPromise,
  getAllAccessGroups,
} from "../../../services/group-for-auth.service";

const AddOrEditGroups = (props) => {
  const params = useParams();

  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const initialValues = {
    group_name: "",
  };

  const validationSchema = Yup.object({
    group_name: Yup.string().required("Please enter the name of the Group"),
  });

  const onSubmit = (values) => {
    if (!submitting) {
      setSubmitting(true);
      return createAccessGroupPromise(params.entityId, values)
        .then((res) => {
          setSubmitting(false);
          dispatch(getAllAccessGroups(params.entityId));
          props.handleClose();
          toast.success("Group Created");
        })
        .catch((err) => {
          setSubmitting(false);
          toast.error(err?.data || STD_ERROR_MESSAGE);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <SingleInputPopUp
      handleClose={props.handleClose}
      heading="NEW GROUP"
      inputPlaceholder="Name your Group"
      inputName="group_name"
      inputOnChange={formik.handleChange}
      inputValue={formik.values.group_name}
      inputErrorMsg={formik.touched.group_name && formik.errors.group_name}
      inputOnBlur={formik.handleBlur}
      btnLabel="Add Group"
      onSubmit={formik.handleSubmit}
      overlayClassName={props.overlayClassName}
    />
  );
};
export default AddOrEditGroups;
