import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  addOrUpdateEntityFeedAccess,
  getAccessGroupForFeed,
  getAllAccessGroups,
} from "../../../services/group-for-auth.service";
import {
  deleteEntityLiveFeedByIdPromise,
  entityLiveFeedLikeOrUnlikePromise,
  getAllEntityFeeds,
} from "../../../services/liveFeed.service";
import { STD_ERROR_MESSAGE } from "../../../utils/constant";
import { getImageUrl } from "../../../utils/utils";
import ButtonAccent from "../../common/button-accent/ButtonAccent";
import CollectionHeaderPartner from "../collection-header-partner/CollectionHeaderPartner";
import EntityLiveFeedCreateOrEdit from "../entity-live-feed-create-or-edit/EntityLIveFeedCreateOrEdit";
import LiveFeedItem from "../live-feed-item/LiveFeedItem";

const AdminLiveUpdates = (props) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [groupAccessLoading, setGroupAccessLoading] = useState(true);
  const [groupAccessMappingLoading, setGroupAccessMappingLoading] =
    useState(true);

  const params = useParams();
  const history = useHistory();

  const [selectedGroups, setSelectedGroups] = useState([]);

  function handleChange(selectedGroups) {
    setSelectedGroups(selectedGroups || []);
  }

  const liveFeedsState = useSelector((state) => state.liveFeedEm);
  const entityState = useSelector((state) => state.entityAd);
  const dispatch = useDispatch();

  const liveFeedsData = liveFeedsState?.response?.data?.live_feed_response;
  const entityData = entityState.response?.data;

  const groupAccessState = useSelector((state) => state.accessGroupAd);
  const groupAccessData = groupAccessState?.response?.data;
  const groupAccessMappingState = useSelector(
    (state) => state.groupAccessMappingEm
  );
  const groupAccessMappingData = groupAccessMappingState?.response?.data;

  useEffect(() => {
    if (!groupAccessState?.loading && !isEmpty(groupAccessState?.response)) {
      setGroupAccessLoading(false);
    }
    if (!groupAccessState?.loading && !isEmpty(groupAccessState?.error)) {
      setGroupAccessLoading(false);
      toast.error(STD_ERROR_MESSAGE);
    }
  }, [groupAccessState]);

  useEffect(() => {
    if (isEmpty(groupAccessState?.response)) {
      dispatch(getAllAccessGroups(params.entityId));
    }
  }, []);

  useEffect(() => {
    if (
      !groupAccessMappingState?.loading &&
      !isEmpty(groupAccessMappingState?.response)
    ) {
      setGroupAccessMappingLoading(false);
      const galleryAccessGroups = groupAccessMappingData.map((mapping) => {
        return {
          value: mapping.group_id,
          label: options.find((o) => o.value == mapping.group_id).label,
        };
      });
      setSelectedGroups(galleryAccessGroups);
    }
    if (
      !groupAccessMappingState?.loading &&
      !isEmpty(groupAccessMappingState?.error)
    ) {
      setGroupAccessMappingLoading(false);
      toast.error(STD_ERROR_MESSAGE);
    }
  }, [groupAccessMappingState]);

  useEffect(() => {
    if (isEmpty(groupAccessMappingState?.response)) {
      dispatch(getAccessGroupForFeed(params.entityId));
    }
  }, []);

  useEffect(() => {
    if (!groupAccessLoading && !groupAccessMappingLoading) {
      const galleryAccessGroups = groupAccessMappingData.map((mapping) => {
        return {
          value: mapping.group_id,
          label: options.find((o) => o.value == mapping.group_id).label,
        };
      });
      setSelectedGroups(galleryAccessGroups);
    }
  }, [groupAccessLoading, groupAccessMappingLoading]);

  const options = groupAccessData?.map((group) => {
    return {
      value: group.id,
      label: group.group_name,
    };
  });

  const onSubmit = (event) => {
    event.preventDefault();
    const payload = [];
    for (const value of selectedGroups) {
      payload.push({ group_id: value.value });
    }
    addOrUpdateEntityFeedAccess(params.entityId, payload)
      .then((res) => {
        dispatch(getAccessGroupForFeed(params.entityId));
        toast.info("Only added group members are allowed to post updates");
      })
      .catch((err) =>
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        )
      );
  };

  useEffect(() => {
    if (!liveFeedsState?.loading && !isEmpty(liveFeedsState?.response)) {
      setPageLoading(false);
    }

    if (!liveFeedsState?.loading && !isEmpty(liveFeedsState?.error)) {
      setPageLoading(false);
      toast.error(liveFeedsState.error?.error_data || STD_ERROR_MESSAGE);
    }
  }, [liveFeedsState]);

  useEffect(() => {
    if (isEmpty(liveFeedsState?.response)) {
      dispatch(getAllEntityFeeds(params.entityId));
    }
  }, []);

  function handleLiveFeedLike(id, currLikeState) {
    entityLiveFeedLikeOrUnlikePromise(params.entityId, id, {
      action: currLikeState ? "dislike" : "like",
    })
      .then((res) => {
        dispatch(getAllEntityFeeds(params.entityId));
      })
      .catch((err) => {
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  function handleDeleteFeed(id) {
    deleteEntityLiveFeedByIdPromise(params.entityId, id)
      .then((res) => {
        dispatch(getAllEntityFeeds(params.entityId));
      })
      .catch((err) => {
        toast.error(
          !isEmpty(err?.data?.error_data)
            ? err?.data?.error_data
            : STD_ERROR_MESSAGE
        );
      });
  }

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <CollectionHeaderPartner
        title="Add Live updates"
        backToCollectionsBtn={true}
        handleBackToCollectionsBtn={() => history.goBack()}
      />
      <div className="mt-3" style={{ maxWidth: "40%" }}>
        <button
          style={{
            display: "flex",
            height: "40px",
            border: "2px solid grey",
            borderRadius: "20px",
            padding: "06px 12px",
            backgroundColor: "transparent",
            gap: "1rem",
            marginBottom: "2rem",
          }}
        >
          <label
            style={{
              lineHeight: "24px",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              alignItems: "flex-end",
            }}
          >
            Enable Guest Posting
          </label>
        </button>
        <form onSubmit={onSubmit}>
          <Select
            // ref={inputRef}
            options={options}
            placeholder="Select groups..."
            isMulti
            className="mb-3"
            onChange={handleChange}
            value={selectedGroups}
          />
          <ButtonAccent
            type="submit"
            label="Submit"
            className="pb14-lh21 w-90"
          />
        </form>
      </div>
      <EntityLiveFeedCreateOrEdit action="create" entityId={entityData?.id} />
      {!pageLoading
        ? !isEmpty(liveFeedsData)
          ? liveFeedsData.map((obj) => (
              <LiveFeedItem
                key={obj?.live_feed?.id}
                thumbnail={getImageUrl(entityData?.image?.bucket_path)}
                enableMoreOption={true}
                title={entityData?.name}
                onDeletePostClick={() => handleDeleteFeed(obj?.live_feed?.id)}
                contentText={obj?.live_feed?.text}
                contentImageUrl={getImageUrl(
                  obj?.live_feed?.image?.bucket_path
                )}
                onLikeClick={() =>
                  handleLiveFeedLike(obj?.live_feed?.id, obj?.liked_by_user)
                }
                likesCount={obj?.live_feed?.likes_count}
                likeState={obj?.liked_by_user}
              />
            ))
          : null
        : null}
    </main>
  );
};

export default AdminLiveUpdates;
