import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, size } from "lodash";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import "./entities.css";

import ButtonAccent from "../../../components/common/button-accent/ButtonAccent";
import CustomNavbar from "../../../components/layout/navbar/Navbar";
import rightSideBackArrow from "../../../assets/icon/right_side_back_arrow.svg";
import { getImageUrl, getPartnerType, getUserId } from "../../../utils/utils";
import CustomSpinner from "../../../components/common/spinner/CustomSpinner";
// import placeholder from "../../../assets/placeholder/collection_thumbnail_fill_200x112.svg";
import DeletePopup from "../../../components/layout/delete-popup/DeletePopup";
import deleteIcon from "../../../assets/icon/delete.svg";

import { ENTITY_TYPES, STD_ERROR_MESSAGE } from "../../../utils/constant";
import Image from "../../../components/common/v2/image/Image";
import CreateEntityPopup from "../../../components/layout/create-entity-popup/CreateEntityPopup";
import {
  deleteEntity,
  getAllEntities,
} from "../../../services/entities.service";

const Entities = (props) => {
  const entitiesState = useSelector((state) => state.entitiesAd);
  const dispatch = useDispatch();

  const [createPopup, setCreatePopup] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [deletePopup, setDeletePopup] = useState({ open: false, id: null });

  const entities = entitiesState.response?.data;

  const history = useHistory();

  const toggleCreateColPopup = () => {
    setCreatePopup(!createPopup);
  };

  useEffect(() => {
    if (isEmpty(entitiesState.response) || entitiesState.reload) {
      dispatch(getAllEntities());
    }
  }, []);

  useEffect(() => {
    if (!entitiesState.loading && !isEmpty(entitiesState.response)) {
      setPageLoading(false);
    }
    if (!entitiesState.loading && entitiesState.error) {
      setPageLoading(false);
      toast.error(entitiesState?.error?.data?.error_data || STD_ERROR_MESSAGE);
    }
  }, [entitiesState]);

  const handleEntityTrigger = (entityId) => {
    history.push(`/${getPartnerType()}/${getUserId()}/entity/${entityId}`);
  };

  function handleDeletePopupOpen(id) {
    setDeletePopup((ps) => {
      return {
        ...ps,
        open: true,
        id: id,
      };
    });
  }

  function handleDeletePopupClose() {
    setDeletePopup((ps) => {
      return {
        ...ps,
        open: false,
        id: null,
      };
    });
  }

  const handleDeleteEntity = () => {
    deleteEntity(deletePopup.id)
      .then((res) => {
        dispatch(getAllEntities());
        handleDeletePopupClose();
      })
      .catch((error) => {
        handleDeletePopupClose();
        if (!isEmpty(error?.data)) {
          toast.error(error.data?.error_data || STD_ERROR_MESSAGE);
        }
      });
  };

  const getEntityType = (id) => {
    switch (id) {
      case ENTITY_TYPES.business_owners:
        return "Business Owner";
      case ENTITY_TYPES.education:
        return "Education";
      case ENTITY_TYPES.events_celebrations:
        return "Celebrations";
      case ENTITY_TYPES.finance:
        return "Finance";
      case ENTITY_TYPES.food_hospitality:
        return "Food And Hospitality";
      case ENTITY_TYPES.health_wellness:
        return "Health and wellness";
      case ENTITY_TYPES.marketing:
        return "Marketing";
      case ENTITY_TYPES.mnc_hr:
        return "MNC and HR";
      case ENTITY_TYPES.property:
        return "Property";
      case ENTITY_TYPES.wedding_planners:
        return "Wedding Planners";
      case ENTITY_TYPES.photographers:
        return "Photographers";
      case ENTITY_TYPES.bakers_chocolatiers:
        return "Bakers Chocolatiers";
      case ENTITY_TYPES.makeup_stylists:
        return "Makeup Stylists";
      case ENTITY_TYPES.resorts:
        return "Resorts";
      case ENTITY_TYPES.caterers:
        return "Caterers";
      case ENTITY_TYPES.fashion:
        return "Fashion";
      case ENTITY_TYPES.travel:
        return "Travel";
      case ENTITY_TYPES.artists:
        return "Artists";
      default:
        return "Other";
    }
  };

  return (
    <>
      <CustomNavbar />
      {!pageLoading ? (
        <div className="container">
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <h1 className="pb20-lh32 mb-0">Entities</h1>
          </div>
          {!isEmpty(entities) ? (
            <Grid container spacing={2}>
              {entities &&
                entities.map((container, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={4}
                    key={container.entity.id}
                  >
                    <div className="entity-card">
                      <LazyLoadComponent>
                        <Image
                          src={getImageUrl(
                            container.entity?.image?.bucket_path
                          )}
                          alt=""
                          containerCustomStyle={{
                            width: "100%",
                            height: "auto",
                            aspectRatio: "16/9",
                            cursor: "pointer",
                          }}
                          otherContainerProps={{
                            onClick: () =>
                              handleEntityTrigger(container.entity.id),
                          }}
                          imageCustomStyle={{
                            objectFit: "cover",
                            objectPosition: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </LazyLoadComponent>
                      <div className="card-heading">
                        <h5 className="pb16-lh24">{container.entity.name}</h5>
                        <div className="entity-details pr12-lh24">
                          {container.entity.category_id ? (
                            <>
                              <span>
                                {getEntityType(container.entity.category_id)}
                              </span>
                            </>
                          ) : null}
                          {!container.entity.published && (
                            <img
                              className="mb-5 pt-cursor"
                              src={deleteIcon}
                              alt="delete icon"
                              onClick={() =>
                                handleDeletePopupOpen(container.entity.id)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <div className="center">
              <h1 className="mb-4 pr20-lh32 high-emphasis-color text-center">
                Looks like you are new here
              </h1>
              <ButtonAccent
                onClick={toggleCreateColPopup}
                className="pb14-lh21 px-3 py-2"
                label="Create your first entity"
                endIcon={rightSideBackArrow}
                endIconClassName="pl-2"
                variant="icon-button"
                labelClassName="align-middle"
              />
            </div>
          )}
        </div>
      ) : (
        <CustomSpinner />
      )}
      {deletePopup.open && (
        <DeletePopup
          heading="DELETE ENTITY"
          text="The entity will be deleted permanently. This cannot be undone."
          handleDelete={handleDeleteEntity}
          handleClose={handleDeletePopupClose}
        />
      )}
      {createPopup && (
        <CreateEntityPopup
          setLoader={setPageLoading}
          handleClose={toggleCreateColPopup}
        />
      )}
    </>
  );
};

export default Entities;
