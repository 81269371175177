import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  closeAttendanceForMemberPromise,
  getAttendanceSummaryByEventAndMeetingId,
} from "../../services/attendanceMember.service";
import {
  BNI_CHAPTER_FOLDERS,
  getLocalDateString,
  getLocalTimeString,
} from "../../utils/constant";
import { useDispatch } from "react-redux";
import { getMeetingByEvent } from "../../services/meeting.service";

const MeetingDetail = (props) => {
  const isMobile = useMediaQuery("(max-width: 480px)");
  const { collectionId, meetingData } = props;
  const value = props.value;
  const type = props.type;

  const dispatch = useDispatch();

  const onCloseAttendance = async () => {
    try {
      const res = await closeAttendanceForMemberPromise(
        collectionId,
        meetingData.id
      );
      if (res.error_data == null) {
        toast.success("Attendance closed successfully");
        return;
      }
      toast.error("Error closing attendance");
    } catch (e) {
      console.log("Error closing attendance", e);
      return;
    } finally {
      dispatch(
        getAttendanceSummaryByEventAndMeetingId(collectionId, meetingData.id)
      );
      dispatch(getMeetingByEvent(collectionId));
    }
  };

  const [closeAttendance, setCloseAttendance] = useState(false);

  useEffect(() => {
    if (meetingData) {
      setCloseAttendance(meetingData.meeting_closed);
    }
  });

  return (
    <>
      <Grid item container spacing={1}>
        <Grid direction={{ xs: "row", md: "column" }} item xs={6} container>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Stack direction="row" spacing={1}>
              <EventAvailableIcon sx={{ fontSize: { xs: 17, md: 25 } }} />
              <Typography
                sx={{
                  color: "#29292999",
                  whiteSpace: "nowrap",
                  fontSize: { xs: "0.8rem", md: 16 },
                }}
              >
                {isMobile ? "Meeting Date:" : "Meeting Start Date:"}
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: { xs: "0.7rem", md: 16 },
                }}
              >
                {getLocalDateString(meetingData?.start_date)}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack direction="row" spacing={1} minWidth={10} marginTop={1}>
              <AccessTimeFilledIcon sx={{ fontSize: { xs: 17, md: 25 } }} />
              <Typography
                sx={{
                  color: "#29292999",
                  whiteSpace: "nowrap",
                  fontSize: { xs: "0.8rem", md: 16 },
                }}
              >
                Meeting Start Time:
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: { xs: "0.7rem", md: 16 },
                }}
              >
                {getLocalTimeString(meetingData?.start_date)}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack direction="row" spacing={1} marginTop={1}>
              <AssignmentLateIcon sx={{ fontSize: { xs: 17, md: 25 } }} />
              <Typography
                sx={{
                  color: "#29292999",
                  whiteSpace: "nowrap",
                  fontSize: { xs: "0.8rem", md: 16 },
                }}
              >
                Meeting Late Time:
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: { xs: "0.7rem", md: 16 },
                }}
              >
                {getLocalTimeString(meetingData?.very_late_time)}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} marginTop={{ xs: 1, md: 0 }}>
            <Stack direction="row" spacing={1}>
              <PendingActionsIcon sx={{ fontSize: { xs: 17, md: 25 } }} />
              <Typography
                sx={{
                  color: "#29292999",
                  whiteSpace: "nowrap",
                  fontSize: { xs: "0.8rem", md: 16 },
                }}
              >
                Next Meeting Date:
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: { xs: "0.7rem", md: 16 },
                }}
              >
                {getLocalDateString(meetingData?.next_meeting_date)}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} marginTop={1}>
            <Stack direction="row" spacing={1}>
              <LocationOnIcon sx={{ fontSize: { xs: 17, md: 25 } }} />
              <Typography
                sx={{
                  color: "#29292999",
                  whiteSpace: "nowrap",
                  fontSize: { xs: "0.8rem", md: 16 },
                }}
              >
                Venue:
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: { xs: "0.7rem", md: 16 },
                }}
              >
                {meetingData?.venue}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid direction={{ xs: "row", md: "column" }} item container xs={6}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {value === 0 && type === BNI_CHAPTER_FOLDERS.LVH && (
              <Box
                sx={{
                  border: "none",
                  borderRadius: "4px",
                  backgroundColor: closeAttendance ? "black" : "#EB4E2C",
                  maxWidth: "14rem",
                  marginLeft: "3rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!closeAttendance) {
                    setCloseAttendance(true);
                    onCloseAttendance();
                  } else {
                    toast.info("Attendance is already closed");
                  }
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "white",
                    paddingTop: 1,
                    paddingBottom: 1,
                    padding: 1,
                    fontSize: { xs: 12, md: 20 },
                    whiteSpace: "nowrap",
                    maxHeight: 2,
                  }}
                >
                  {closeAttendance ? "Closed Attendance" : "Close Attendance"}
                </Typography>
              </Box>
            )}
          </Grid>
          {isMobile ? (
            <Grid item xs={8} marginLeft={6}>
              <Stack direction="row" spacing={1} marginTop={1}>
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    background: "#0098C8",
                  }}
                />
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "0.7rem",
                  }}
                >
                  On Time
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} marginTop={0.5}>
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    background: "#CDC500",
                  }}
                />
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "0.7rem",
                  }}
                >
                  Slightly Late
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} marginTop={0.5}>
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    background: "#EB4E2C",
                  }}
                />
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "0.7rem",
                  }}
                >
                  Late
                </Typography>
              </Stack>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default MeetingDetail;
