import React from "react";
import appLogo from "../../../assets/icon/app_logo.svg";

const AppLogo = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img style={{ marginRight: "5px" }} src={appLogo} />
      <span style={{ fontSize: "24px", color: "#F2F2F2" }}>TrueCo</span>
    </div>
  );
};

export default AppLogo;
