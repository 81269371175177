import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import "./infoscreens.css";

import CustomNavbar from "../../components/layout/navbar/Navbar";
import AboutUs from "../../components/layout/info-screen/AboutUs";
import ContactUs from "../../components/layout/info-screen/ContactUs";
import Help from "../../components/layout/info-screen/Help";
import TermsOfUse from "../../components/layout/info-screen/TermsOfUse";
import Privacy from "../../components/layout/info-screen/Privacy";

import { getKeyByValue } from "../../utils/utils";
import PartnerSignin from "../../components/layout/info-screen/PartnerSignin";

const TAB_NAME_INDEX_MAPPING = {
  "partner-signin": 0,
  "about-us": 1,
  "help-and-support": 2,
  "privacy-policy": 3,
  "terms-of-use": 4,
  "contact-us": 5,
};

const StyledTabs = styled(Tabs)({
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
  backgroundColor: "var(--secondary-color)",
  "& .MuiTabs-indicator": {
    backgroundColor: "var(--high-emphasis)",
    height: "2.5px",
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: 400,
  fontFamily: '"Poppins" , sans-serif',
  lineHeight: "32px",
  fontSize: "18px",
  color: "var(--medium-emphasis)",
  marginRight: theme.spacing(1),
  // '&:hover': {
  //     color: 'var(--high-emphasis)',
  //     fontWeight: 600,
  // },
  "&.Mui-selected": {
    color: "var(--high-emphasis)",
    fontWeight: 600,
  },
  // '&.Mui-focusVisible': {
  //     backgroundColor: '#d1eaff',
  // },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function InfoScreen() {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isDesktop = useMediaQuery("(min-width: 992px)");

  const path = useLocation().pathname;
  const history = useHistory();
  const invokedTab = path.split("/")[1];

  const handleChange = (event, newValue) => {
    history.push(`/${getKeyByValue(TAB_NAME_INDEX_MAPPING, newValue)}`);
  };

  return (
    <>
      <CustomNavbar />
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            position: "sticky",
            zIndex: 10,
            ...(isSmallScreen ? { top: "56px" } : { top: "64px" }),
          }}
        >
          <StyledTabs
            value={TAB_NAME_INDEX_MAPPING[invokedTab]}
            onChange={handleChange}
            aria-label="tabs"
            {...(isDesktop
              ? {
                  centered: true,
                }
              : {
                  variant: "scrollable",
                  scrollButtons: "auto",
                  allowScrollButtonsMobile: true,
                })}
          >
            <StyledTab label="Partner Sign In" {...a11yProps(0)} />
            <StyledTab label="About us" {...a11yProps(1)} />
            <StyledTab label="Help & Support" {...a11yProps(2)} />
            <StyledTab label="Privacy policy" {...a11yProps(3)} />
            <StyledTab label="Terms of use" {...a11yProps(4)} />
            <StyledTab label="Contact us" {...a11yProps(5)} />
          </StyledTabs>
        </Box>
        <TabPanel value={TAB_NAME_INDEX_MAPPING[invokedTab]} index={0}>
          <PartnerSignin />
        </TabPanel>
        <TabPanel value={TAB_NAME_INDEX_MAPPING[invokedTab]} index={1}>
          <AboutUs />
        </TabPanel>
        <TabPanel value={TAB_NAME_INDEX_MAPPING[invokedTab]} index={2}>
          <Help />
        </TabPanel>
        <TabPanel value={TAB_NAME_INDEX_MAPPING[invokedTab]} index={3}>
          <Privacy />
        </TabPanel>
        <TabPanel value={TAB_NAME_INDEX_MAPPING[invokedTab]} index={4}>
          <TermsOfUse />
        </TabPanel>
        <TabPanel value={TAB_NAME_INDEX_MAPPING[invokedTab]} index={5}>
          <ContactUs />
        </TabPanel>
      </Box>
    </>
  );
}

export default InfoScreen;
